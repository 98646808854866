import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { select_instance } from "../redux/actions";

/**
 * User hits this page if coming from an external link (/instance/:instance)
 * If user is logged in, select the instance and route to the dashboard
 * Else, route to login page
 */

const InstanceRedirect = ({
  instanceSelected,
  select_instance,
  socket,
  userInfo,
}) => {
  const { instance } = useParams();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (socket && instanceSelected !== instance) {
      if (!userInfo._id) history.push("/login");
      else select_instance(instance);
    }
  }, [socket]);

  return <></>;
};

const mapStateToProps = (state) => ({
  instance: state.instance,
  instanceSelected: state.instanceSelected,
  socket: state.socket,
  userInfo: state.userInfo,
});

export default connect(mapStateToProps, { select_instance })(InstanceRedirect);
