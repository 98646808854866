export default [
  {
    id: "body_background_color_default",
    label: "Body Background Color (Default theme)",
    type: "color",
    tab: "colors",
    defaultValue: "#ffffff",
  },
  {
    id: "body_text_color_default",
    label: "Body Color (Default theme)",
    type: "color",
    tab: "colors",
    defaultValue: "#4f4f4f",
  },
  {
    id: "danger_default",
    label: "Danger Color (Default theme)",
    type: "color",
    tab: "colors",
    defaultValue: "#f93154",
  },
  {
    id: "dark_default",
    label: "Dark Color (Default theme)",
    type: "color",
    tab: "colors",
    defaultValue: "#262626",
  },
  {
    id: "info_default",
    label: "Info Color (Default theme)",
    type: "color",
    tab: "colors",
    defaultValue: "#39c0ed",
  },
  {
    id: "light_default",
    label: "Light Color (Default theme)",
    type: "color",
    tab: "colors",
    defaultValue: "#f9f9f9",
  },
  {
    id: "primary_default",
    label: "Primary Color (Default theme)",
    type: "color",
    tab: "colors",
    defaultValue: "#1266f1",
  },
  {
    id: "secondary_default",
    label: "Secondary Color (Default theme)",
    type: "color",
    tab: "colors",
    defaultValue: "#b23cfd",
  },
  {
    id: "success_default",
    label: "Success Color (Default theme)",
    type: "color",
    tab: "colors",
    defaultValue: "#00b74a",
  },
  {
    id: "warning_default",
    label: "Warning Color (Default theme)",
    type: "color",
    tab: "colors",
    defaultValue: "#ffa900",
  },
  {
    id: "body_background_color_dark",
    label: "Body Background Color (Dark Theme)",
    type: "color",
    tab: "colors",
    defaultValue: "#303030",
  },
  {
    id: "body_background_color_contrast_dark",
    label: "Body Background Color Contrast (Dark Theme)",
    type: "color",
    tab: "colors",
    defaultValue: "#424242",
  },
  {
    id: "body_text_color_dark",
    label: "Body Color (Dark Theme)",
    type: "color",
    tab: "colors",
    defaultValue: "#ffffff",
  },

  {
    id: "custom_css",
    label: "Custom CSS (All Themes)",
    type: "textarea",
    tab: "colors",
    defaultValue: "",
  },
  {
    id: "custom_css_dark",
    label: "Custom CSS (Dark Theme)",
    type: "textarea",
    tab: "colors",
    defaultValue: "",
  },
  {
    id: "custom_css_default",
    label: "Custom CSS (Default Theme)",
    type: "textarea",
    tab: "colors",
    defaultValue: "",
  },
  {
    id: "danger_dark",
    label: "Danger Color (Dark Theme)",
    type: "color",
    tab: "colors",
    defaultValue: "#f93154",
  },

  {
    id: "dark_dark",
    label: "Dark Color (Dark Theme)",
    type: "color",
    tab: "colors",
    defaultValue: "#262626",
  },

  {
    id: "info_dark",
    label: "Info Color (Dark Theme)",
    type: "color",
    tab: "colors",
    defaultValue: "#39c0ed",
  },

  {
    id: "light_dark",
    label: "Light Color (Dark Theme)",
    type: "color",
    tab: "colors",
    defaultValue: "#f9f9f9",
  },

  {
    id: "primary_dark",
    label: "Primary Color (Dark Theme)",
    type: "color",
    tab: "colors",
    defaultValue: "#1266f1",
  },

  {
    id: "secondary_dark",
    label: "Secondary Color (Dark Theme)",
    type: "color",
    tab: "colors",
    defaultValue: "#b23cfd",
  },

  {
    id: "success_dark",
    label: "Success Color (Dark Theme)",
    type: "color",
    tab: "colors",
    defaultValue: "#00b74a",
  },

  {
    id: "warning_dark",
    label: "Warning Color (Dark Theme)",
    type: "color",
    tab: "colors",
    defaultValue: "#ffa900",
  },
];
