import React from "react";
import { connect } from "react-redux";
import { set_user } from "../../redux/actions";
import BioField from "../../pages/createAccount/BioField";
import { user_schema } from "../../utilities/validations";
import axios from "axios";
import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBRipple,
} from "mdb-react-ui-kit";
import h from "../../utilities/helpers";

const fields = [
  {
    text: "Username",
    id: "username",
    type: "text",
  },
  {
    text: "Email Address",
    id: "email",
    type: "text",
  },
  {
    text: "Password",
    id: "password1",
    type: "password",
  },
  {
    text: "Re-Enter Password",
    id: "password2",
    type: "password",
  },
  {
    text: "Display Name",
    id: "displayName",
    type: "text",
  },
  {
    text: "Location (Optional)",
    id: "location",
    type: "text",
  },
  {
    text: "Website (Optional)",
    id: "website",
    type: "text",
  },
];

const allowedExtensions = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/bmp",
  "image/webp",
  "image/svg+xml",
];

class CreateAccount extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether a new account is in the process of being submitted
       * inputs: Array - The input data (values, errors, etc)
       * avatar: String, Path to the user's avatar
       * avatarName: String, "Click to Change", or the name of the file the user has selected, if any
       * avatarFile: false | File object that contains an avatar file that the user selected
       * background: String, Path to the user's background
       * backgroundName: String, "Click to Change", or the name of the file the user has selected, if any
       * backgroundFile: false | File object that contains an background file that the user selected
       * reset: Boolean - When flipped, fixes an mdb ui bug
       */
      working: false,
      inputs: fields.map((field) => ({
        id: field.id,
        error: "",
        invalid: true,
        value: "",
      })),
      avatar: `${process.env.REACT_APP_BUCKET_HOST}/images/blank-avatar.png`,
      avatarName: "Click to Change",
      avatarFile: "",
      background: `${process.env.REACT_APP_BUCKET_HOST}/images/default-background.webp`,
      backgroundName: "Click to Change",
      backgroundFile: "",
      reset: false,
    };
    this.submit = this.submit.bind(this); // Allows the submit method to be called from the parent
  }

  /**
   * Fix mdb inputs
   * Set the submit() method in the parent
   * Run blank changeHandler
   */
  componentDidMount() {
    setTimeout(this.fixMDBInputs, 250);
    this.props.setSubmit(this.submit);
    this.changeHandler({
      target: {
        name: "",
      },
    });
  }

  /**
   * When logged in, hide modal
   * When modal spawns, fix MDB inputs
   */
  componentDidUpdate(prevProps) {
    if (
      prevProps.userInfo.username !== this.props.userInfo.username &&
      this.props.modalShown
    )
      this.props.toggleShowModal();
    if (prevProps.modalShown !== this.props.modalShown) this.fixMDBInputs();
  }

  componentWillUnmount() {
    this.props.setWorking(false);
  }

  /**
   * Executes a captcha challenge and generates a key a key
   * Will hang until connected to captcha servers
   */
  getRecaptcha = () =>
    new Promise(async (resolve, reject) => {
      if (String(process.env.REACT_APP_DEV) === "true")
        return resolve(process.env.REACT_APP_DEV_CAPTCHA_KEY);
      if (this.props.captchaReady)
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
          .then(resolve)
          .catch((err) => {
            console.log(err);
            alert("Human verification failed. Refresh the page and try again.");
            reject();
          });
      else
        setTimeout(async () => {
          const captchaKey = await this.getRecaptcha();
          resolve(captchaKey);
        }, 500);
    });

  /**
   * Fixes MDB ui bug in which labels are not properly floating or are crossed out
   */
  fixMDBInputs = () => {
    [].slice
      .call(document.getElementsByClassName("comment-inputs"))
      .forEach((e) => {
        if (!e.value && e.classList.contains("active"))
          e.classList.remove("active");
        else if (e.value && !e.classList.contains("active"))
          e.classList.add("active");
      });
    this.setState(
      {
        ...this.state,
        reset: !this.state.reset,
      },
      () => h.floatLabels()
    );
  };

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) => {
    this.setState(
      {
        ...this.state,
        inputs: this.state.inputs.map((input) => {
          if (input.id === e.target.name)
            return {
              ...input,
              value: e.target.value,
            };
          else return input;
        }),
      },
      () => {
        const data = Object.fromEntries(
          this.state.inputs.map((input) => [input.id, input.value.trim()])
        );
        try {
          user_schema.validateSync(data, {
            abortEarly: false,
          });
          this.setState({
            ...this.state,
            inputs: this.state.inputs.map((input) => {
              document.getElementById(input.id).setCustomValidity("");
              return {
                ...input,
                invalid: false,
                error: "",
              };
            }),
          });
        } catch (err) {
          let errorsAdded = [];
          this.setState(
            {
              ...this.state,
              inputs: this.state.inputs.map((input) => {
                if (
                  err.inner.find((error) => error.path === input.id) &&
                  errorsAdded.indexOf(input.id) === -1
                ) {
                  errorsAdded.push(input.id);
                  return {
                    ...input,
                    invalid: true,
                    error: err.inner.find((error) => error.path === input.id)
                      .message,
                  };
                } else
                  return {
                    ...input,
                    invalid: false,
                    error: "",
                  };
              }),
            },
            () =>
              this.state.inputs.forEach((input) => {
                if (input.invalid)
                  document
                    .getElementById(input.id)
                    .setCustomValidity(input.error || "Invalid");
                else document.getElementById(input.id).setCustomValidity("");
              })
          );
        }
      }
    );
  };

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Make request to server
   * Set user to newly created account
   */
  submit = () => {
    this.forceParse();
    document.getElementById("form").classList.add("was-validated");
    let invalidInputs = this.state.inputs.filter((input) => input.invalid);
    invalidInputs.forEach((input) =>
      document
        .getElementById(input.id)
        .setCustomValidity(input.error || "Invalid")
    );
    if (!this.state.working && !invalidInputs.length)
      this.setState(
        {
          ...this.state,
          working: true,
        },
        async () => {
          this.props.setWorking(true);
          const data = Object.fromEntries(
            this.state.inputs.map((input) => [input.id, input.value.trim()])
          );
          try {
            user_schema.validateSync(data, {
              abortEarly: false,
            });
            const bio = document.getElementById("input-bio");
            const length = String(bio.textContent)
              .split("")
              .filter((c) => {
                const checkWhiteSpace = c.match(/[\s]/);
                if (!checkWhiteSpace) return true;
                else {
                  return [" ", "\n"].indexOf(c) > -1;
                }
              }).length;
            if (length > 1000)
              this.setState(
                {
                  ...this.state,
                  working: false,
                },
                () => alert("Your bio is too long (Max: 1000 chars)")
              );
            else {
              const captchaKey = await this.getRecaptcha();
              const fd = new FormData();
              for (const key in data) {
                fd.append(key, data[key]);
              }
              fd.append(
                "bio",
                bio.innerHTML.replace(/[\u200B-\u200D\uFEFF]/g, "")
              );
              if (this.state.avatarFile)
                fd.append(
                  "avatar",
                  this.state.avatarFile,
                  this.state.avatarName
                );
              if (this.state.backgroundFile)
                fd.append(
                  "background",
                  this.state.backgroundFile,
                  this.state.backgroundName
                );
              fd.append("captchaKey", captchaKey);
              axios
                .post("/auth/create_account", fd)
                .then((res) =>
                  this.setState(
                    {
                      ...this.state,
                      working: false,
                    },
                    () => {
                      if (res.data.error) {
                        this.props.setWorking(false);
                        alert(res.data.error);
                      } else {
                        this.props.setWorking(false);
                        document
                          .getElementById("form")
                          .classList.remove("was-validated");
                        document.getElementById("form").reset();
                        if (
                          process.env.REACT_APP_EMAIL_VERIFICATION_REQUIRED ===
                          "true"
                        )
                          alert(
                            `An email has been sent from ${process.env.REACT_APP_VERIFICATION_EMAIL} to verify your email address. Please click the link in this email to verify your account.`
                          );
                        else if (
                          process.env.REACT_APP_REQUIRE_APPROVAL === "true"
                        )
                          alert(
                            "Your account is awaiting approval. Please check back later."
                          );
                        else this.props.set_user(res.data);
                      }
                    }
                  )
                )
                .catch((err) =>
                  this.setState(
                    {
                      ...this.state,
                      working: false,
                    },
                    () => {
                      this.props.setWorking(true);
                      console.log(err);
                      alert("An error occurred. Please try again later");
                    }
                  )
                );
            }
          } catch (err) {
            this.setState(
              {
                ...this.state,
                working: false,
              },
              () => {
                this.props.setWorking(true);
                console.log(err);
                alert("An error occurred. Please try again later");
              }
            );
          }
        }
      );
  };

  /**
   * Submit the form if the user presses the enter key while in one of the inputs
   */
  pressEnter = (e) => {
    if (e.key === "Enter") this.submit();
  };

  /**
   *
   * @param {Event} e - Keypress event
   *
   * Triggered when the user presses the Tab key
   * Moves cursor to next input (MDB is bugged)
   * Removed when MDB fixes
   */
  pressTab = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const input = fields.find((f) => f.id === e.target.id);
      if (input) {
        const nextField = fields[fields.indexOf(input) + 1];
        if (nextField) {
          const element = document.getElementById(nextField.id);
          if (element) {
            setTimeout(() => {
              element.focus();
              element.select();
            }, 100);
          }
        }
      }
    }
  };

  /**
   * Fired when the user clicks their avatar or background
   *
   * Creates a virtual file input
   * Adds a change event that sets the selected file into state
   * Appends to document body (necessary for iDevices and possibly others)
   * Clicks the input
   * Removes the input after the file is selected
   */
  selectFile = (type) => {
    let input = document.createElement("input");
    input.type = "file";
    input.classList.add("d-none");
    document.body.appendChild(input);
    input.onchange = (e) => {
      let file = e.target.files[0];
      if (allowedExtensions.indexOf(file.type) !== -1) {
        if (file.size < 15000001) {
          this.setState(
            {
              ...this.state,
              [`${type}Name`]: e.target.files[0].name,
              [`${type}File`]: e.target.files[0],
              [type]: URL.createObjectURL(e.target.files[0]),
            },
            () => document.body.removeChild(input)
          );
        } else {
          document.body.removeChild(input);
          alert("Your file is too big (Max: 15MB)");
        }
      } else {
        document.body.removeChild(input);
        alert("Please select a valid image file (png, jpg, gif, bmp, webp)");
      }
    };
    input.click();
  };

  render() {
    return (
      <>
        <MDBValidation
          method="dialog"
          id="form"
          name="form"
          className="row mt-4"
          onSubmit={this.submit}
        >
          {fields
            .filter((field) => ["bio", "avatar"].indexOf(field.id) === -1)
            .map((i, index) => (
              <MDBValidationItem
                key={i.id}
                className="pb-4 col-12 col-lg-6"
                feedback={
                  this.state.inputs.find((input) => input.id === i.id).error
                }
                invalid={true}
              >
                <MDBInput
                  name={i.id}
                  onChange={this.changeHandler}
                  id={i.id}
                  label={i.text}
                  size="lg"
                  className={
                    !this.state.inputs.find((input) => input.id === i.id)
                      .invalid
                      ? "mb-0"
                      : 0
                  }
                  type={i.type}
                  onKeyPress={(e) => this.pressEnter(e, index)}
                  onKeyDown={this.pressTab}
                />
              </MDBValidationItem>
            ))}
          <div className=" col-12 col-lg-6">
            <BioField
              setForceParse={(f) => (this.forceParse = f)}
              working={this.state.working}
            />
          </div>
        </MDBValidation>
        <div className="row mt-4 justify-content-center">
          <div className=" col-12 col-lg-6">
            <p style={{ fontSize: "1.5rem" }} className="text-center display-6">
              Display Picture
            </p>
            <div className="d-flex justify-content-center">
              <div className="d-flex justify-content-center align-items-center square-15 mx-auto">
                <MDBRipple
                  onClick={() => this.selectFile("avatar")}
                  tag="div"
                  rippleColor="primary"
                  className="fit-images fit-round"
                  style={{
                    backgroundImage: `url("${this.state.avatar}")`,
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                ></MDBRipple>
              </div>
            </div>
          </div>
          <div className=" col-12 col-lg-6">
            <p style={{ fontSize: "1.5rem" }} className="text-center display-6">
              Background Picture
            </p>
            <div
              style={{ height: "250px" }}
              className="d-flex justify-content-center align-items-center w-100 mx-auto"
            >
              <MDBRipple
                onClick={() => this.selectFile("background")}
                tag="div"
                rippleColor="light"
                className="fit-background cursor-pointer"
                style={{ backgroundImage: `url("${this.state.background}")` }}
              ></MDBRipple>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { set_user })(CreateAccount);
