import React from "react";
import { connect } from "react-redux";
import { remove_notification } from "../redux/actions";
import { MDBStack, MDBToast } from "mdb-react-ui-kit";
import t from "../utilities/transitions";
import { motion } from "framer-motion";
import { useHistory, useLocation } from "react-router-dom";

const Notifications = ({ notifications, userInfo, remove_notification }) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <div className="position-relative">
      <MDBStack
        position="bottom-left"
        className="position-fixed page-container"
      >
        {notifications.map((notification) => {
          return (
            <motion.div
              transition={t.transition}
              exit={history?.location?.state?.exit || t.fade_out_scale_1}
              animate={t.normalize}
              initial={history?.location?.state?.enter || t.fade_out}
              key={notification.id}
            >
              <MDBToast
                color={
                  userInfo.userSettings.theme === "default" ? "light" : "dark"
                }
                id={`notification-${notification.id}`}
                defaultOpen={true}
                autohide={true}
                initialAnimation={false}
                delay={2000}
                appendToBody={false}
                headerContent={
                  <div className="me-auto">{notification.icon}</div>
                }
                bodyContent={<h5>{notification.text}</h5>}
                onClosed={() => remove_notification(notification.id)}
              />
            </motion.div>
          );
        })}
      </MDBStack>
    </div>
  );
};

const mapStateToProps = (state) => ({
  notifications: state.notifications,
  userInfo: state.userInfo,
});

export default connect(mapStateToProps, { remove_notification })(Notifications);
