import React from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import h from "../../../../../../utilities/helpers";
import t from "../../../../../../utilities/transitions";

const Banned = ({ userInfo }) => {
  const instance = userInfo.instances.find((i) => i.service === "jizzer");
  return (
    <motion.div
      transition={t.transition}
      initial={t.fade_out}
      animate={t.normalize}
      exit={t.fade_out_scale_1}
    >
      <h3 className="text-center display-6 mb-4">Instance has been banned</h3>
      <h5 className="text-center">{instance.banned.details}</h5>
      <p className="text-center">
        Banned on {h.makeDateHR(new Date(instance.banned.timestamp))} at{" "}
        {h.getTimeHR(new Date(instance.banned.timestamp))}
      </p>
    </motion.div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

export default connect(mapStateToProps, {})(Banned);
