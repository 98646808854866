import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import h from "../../../../../../utilities/helpers";
import t from "../../../../../../utilities/transitions";
import Spinner from "../../../../../../components/Spinner";
import { MDBListGroup, MDBListGroupItem, MDBRipple } from "mdb-react-ui-kit";
import MessageModal from "./MessageModal";

const Messages = ({ messages, messagesLoaded }) => {
  const [messageSelected, setMessageSelected] = useState("");
  const [modalShown, setModalShown] = useState(false);

  useEffect(() => {
    if (messageSelected) setModalShown(true);
  }, [messageSelected]);

  if (!messagesLoaded)
    return (
      <motion.section
        transition={t.transition}
        initial={t.fade_out}
        animate={t.normalize}
        exit={t.fade_out_scale_1}
        className="d-flex justify-content-center mt-4"
      >
        <Spinner color="primary" />
      </motion.section>
    );

  return (
    <motion.div
      transition={t.transition}
      initial={t.fade_out}
      animate={t.normalize}
      exit={t.fade_out_scale_1}
    >
      <MessageModal
        modalShown={modalShown}
        toggleShowModal={() => setModalShown(!modalShown)}
        message={messages.find((m) => m._id === messageSelected)}
      />
      <MDBListGroup>
        {messages
          .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
          .map((message) => (
            <MDBRipple>
              <MDBListGroupItem
                action
                className="cursor-pointer"
                onClick={() => setMessageSelected(message._id)}
                key={message._id}
                active={messageSelected === message._id}
              >
                <div className="d-flex position-relative">
                  <div className="flex-grow-1">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h6 className="m-0">
                          {h.veryShortString(message.name)}
                        </h6>
                        <p className="m-0">
                          {h.veryShortString(message.subject)}
                        </p>
                      </div>
                      <p
                        className={`m-0 ${
                          messageSelected !== message._id ? "text-blusteel" : ""
                        }`}
                      >
                        {h.getMessageTime(message.timestamp)}
                      </p>
                    </div>
                    <p className="m-0">{h.shortString(message.message)}</p>
                  </div>
                </div>
              </MDBListGroupItem>
            </MDBRipple>
          ))}
      </MDBListGroup>
    </motion.div>
  );
};

export default Messages;
