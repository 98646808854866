import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import t from "../utilities/transitions";
import { connect } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import {
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardFooter,
  MDBBtn,
  MDBContainer,
} from "mdb-react-ui-kit";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";

const Instances = ({}) => {
  const [instances, setInstances] = useState(false);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    load();
  }, []);

  /**
   * Load list of instances
   * Set into state
   */
  const load = () =>
    axios
      .get("/api/instances")
      .then((res) => {
        setInstances(
          res.data.instances.map((instance) => ({
            ...instance,
            domain: instance.domain.replaceAll("⚓", "."),
          }))
        );
      })
      .catch((err) => {
        console.log("load error", err);
        setTimeout(load, 1000);
      });

  /**
   *
   * @param {String} instanceID - Product instance ID
   * @returns href of logo
   */
  const getLogo = (instanceID) => {
    switch (instanceID) {
      case "jizzer":
      case "jizzer-beta":
        return "/assets/images/jizzer-logo.png";
      default:
        console.log("oob instance id", instanceID);
        return "/assets/images/jizzer-logo.png";
    }
  };

  /**
   *
   * @param {String} instanceID
   * @returns Bucket host
   */
  const getHost = (instanceID) => {
    switch (instanceID) {
      case "jizzer":
      case "jizzer-beta":
        return process.env.REACT_APP_JIZZER_BUCKET_HOST;
      default:
        console.log("oob instance id", instanceID);
        return process.env.REACT_APP_JIZZER_BUCKET_HOST;
    }
  };

  return (
    <motion.div
      transition={t.transition}
      initial={history?.location?.state?.enter || t.fade_out}
      animate={t.normalize}
      exit={history?.location?.state?.exit || t.fade_out_scale_1}
    >
      <MDBContainer fluid>
        {instances ? (
          <motion.div
            transition={t.transition}
            initial={history?.location?.state?.enter || t.fade_out}
            animate={t.normalize}
            exit={history?.location?.state?.exit || t.fade_out_scale_1}
            className="row"
          >
            {instances.map((instance) => {
              return (
                <div
                  key={instance._id}
                  className="col-12 col-md-6 col-xl-4 mt-4"
                >
                  <MDBCard>
                    <MDBCardHeader className="d-flex align-items-center justify-content-between">
                      <h5 className="m-0">{instance.preferences.app_name}</h5>
                      <div className="square-2">
                        <div
                          className="fit-images"
                          style={{
                            backgroundImage: `url("${getLogo(
                              instance.service
                            )}")`,
                          }}
                        ></div>
                      </div>
                    </MDBCardHeader>
                    <MDBCardBody className="d-flex align-items-start">
                      <div className="h-max-content">
                        <div className="square-6">
                          <div
                            className="fit-images"
                            style={{
                              backgroundImage:
                                instance.domain === "filepimps.com"
                                  ? `url("/assets/images/jizzer-logo.png")`
                                  : `url("${getHost(instance.service)}/${
                                      instance.uuid
                                    }/icons/favicon-96x96.png")`,
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="flex-grow-1 ps-3">
                        <p className="m-0">
                          {instance.preferences.description}
                        </p>
                      </div>
                    </MDBCardBody>
                    <MDBCardFooter className="d-flex justify-content-end">
                      <a href={"https://" + instance.domain}>
                        <MDBBtn color="link" rippleColor="primary">
                          {instance.domain}
                          <i className="fas fa-chevron-right ms-2" />
                        </MDBBtn>
                      </a>
                    </MDBCardFooter>
                  </MDBCard>
                </div>
              );
            })}
          </motion.div>
        ) : (
          <div className="w-75 mx-auto">
            <h5 className="text-center display-6 my-4">Getting Instances</h5>
            <LinearProgress />
          </div>
        )}
      </MDBContainer>
    </motion.div>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {})(Instances);
