import React from "react";
import { motion } from "framer-motion";
import t from "../../../../../../../utilities/transitions";
import Spinner from "../../../../../../../components/Spinner";

const Status = ({ status }) => {
  return (
    <motion.div
      transition={t.transition}
      initial={t.fade_out}
      animate={t.normalize}
      exit={t.fade_out_scale_1}
    >
      <h5 className="text-center display-6">
        Removing Files ({status.current}/{status.total})
      </h5>
      <p className="text-center mb-4">
        If you close out of the application, the operation will stop
      </p>
      <div className="d-flex justify-content-center">
        <Spinner color="primary" />
      </div>
      <h5 className="text-center mt-4">
        {status.deleting.name} (
        {(status.deleting.size / (1024 * 1024)).toFixed(2)}MB)
      </h5>
    </motion.div>
  );
};

export default Status;
