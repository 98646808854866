import React from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import t from "../../../../../../utilities/transitions";

const Logs = ({ userInfo }) => {
  const instance = userInfo.instances.find((i) => i.service === "jizzer");
  console.log("Logs", instance);

  return (
    <motion.div
      transition={t.transition}
      initial={t.fade_out}
      animate={t.normalize}
      exit={t.fade_out_scale_1}
    >
      Logs
    </motion.div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

export default connect(mapStateToProps, {})(Logs);
