import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  purge_user,
  set_user,
  reconnect_socket,
  set_server_status,
  set_preference_status,
  notify,
} from "../redux/actions";
import "./css/navBar.css";
import MobileLogo from "./navBar/MobileLogo";
import MobileOnlyNavItems from "./navBar/MobileOnlyNavItems";
import MobileLogin from "./navBar/MobileLogin";
import DropdownMenuDesktop from "./navBar/DropdownMenuDesktop";
import MenuItemsBoth from "./navBar/MenuItemsBoth";
import LogoutButtonMobile from "./navBar/LogoutButtonMobile";
import DesktopLogo from "./navBar/DesktopLogo";
import {
  MDBNavbar,
  MDBContainer,
  MDBIcon,
  MDBNavbarToggler,
  MDBCollapse,
  MDBRipple,
  MDBBtn,
} from "mdb-react-ui-kit";
import { motion } from "framer-motion";
import t from "../utilities/transitions";
import { Link } from "react-router-dom";

/**
 * This is the navbar
 * It currently looks decent on all screen sizes, but the code needs to be cleaned up
 */

class NavBar extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * userLoaded: Boolean indicating whether the first user session check has concluded
       */
      userLoaded: false,
      navExpanded: false,
      loggingOut: false,
    };
  }

  componentDidMount() {
    /**
     * Set bootstrap (need to wait until mount or else it messes up on ssr)
     * Check user session
     * Set the mobile hamburger into state so that it can be closed upon route
     * Set tooltips
     */
    if (this.props.socket) this.props.socket.emit("login");
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.socket && this.props.socket) this.initializeSocket();
    if (
      prevProps.userInfo._id &&
      !this.props.userInfo._id &&
      ["dashboard"].indexOf(
        this.props.history.location.pathname.split("/")[1]
      ) > -1
    )
      this.props.history.push("/login");
    if (
      prevProps.history?.location.pathname !==
      this.props.history?.location.pathname
    )
      console.log("change");
  }

  socketDisconnect = () =>
    setTimeout(() => {
      if (this.props.socket.connected) this.initializeSocket();
      else this.socketDisconnect();
    }, 1500);

  initializeSocket = () => {
    this.props.socket.off("disconnect");
    this.props.socket.off("server-info");
    this.props.socket.off("preference-status");

    this.props.reconnect_socket();
    this.props.socket.emit("login");

    this.props.socket.on("preference-status", this.props.set_preference_status);
    this.props.socket.on("server-info", this.props.set_server_status);
    this.props.socket.on("disconnect", this.socketDisconnect);
  };

  logout = (retry) => {
    if (!this.state.loggingOut || retry)
      this.setState(
        {
          ...this.state,
          loggingOut: true,
          navExpanded: false,
        },
        () =>
          axios
            .get("/auth/logout")
            .then(() => {
              if (this.props.socket && this.props.socket.disconnect)
                this.props.socket.disconnect();
              this.props.purge_user();
              this.setState({
                ...this.state,
                loggingOut: false,
              });
            })
            .catch((err) => {
              console.log("logout error", err);
              setTimeout(() => {
                this.props.notify(
                  <i className="fas fa-times-circle me-2 text-danger" />,
                  "An error occurred while logging you out. Retrying..."
                );
                this.logout(true);
              }, 1000);
            })
      );
  };

  nav = (e, path) => {
    e.preventDefault();
    this.props.history.push(path);
    this.setState({
      ...this.state,
      navExpanded: false,
    });
  };

  collapseNav = () =>
    this.setState({
      ...this.state,
      navExpanded: false,
    });

  toggleNav = () =>
    this.setState({
      ...this.state,
      navExpanded: !this.state.navExpanded,
    });

  render() {
    return (
      <MDBNavbar
        light
        expand="lg"
        id="div-nav-container"
        className="container-fluid px-0 navbar navbar-bg w-100"
      >
        <div className="row mx-auto w-100">
          <MobileLogo collapseNav={this.collapseNav} />
          <div
            id="nav-column"
            className="col-lg-12 col-6 d-flex flex-column justify-content-center"
          >
            <MDBNavbar
              style={{ boxShadow: "none" }}
              expand="lg"
              id="nav-container"
              className="py-2 w-100"
            >
              <div className="pe-2 w-max-content ms-auto">
                <MDBRipple rippleColor="light">
                  <MDBNavbarToggler
                    type="button"
                    data-target="#navbarText"
                    aria-controls="navbarText"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={this.toggleNav}
                    className="ms-auto"
                    style={{
                      background: "rgba(96, 125, 139, 0.90)",
                      color: "#eee",
                      padding: "10px 16px 8px 16px",
                    }}
                    size="sm"
                  >
                    <MDBIcon icon="bars" size="lg" fas />
                  </MDBNavbarToggler>
                </MDBRipple>
              </div>
              <MDBCollapse open={this.state.navExpanded} navbar id="navbarText">
                <MDBContainer fluid className="px-2">
                  <div className="row mx-0">
                    <div className="col-12 col-lg-8 d-flex align-items-center">
                      <DesktopLogo collapseNav={this.collapseNav} />
                      <ul className="navbar-nav mb-lg-0, ul-main-nav">
                        {!this.props.userInfo.username ? (
                          <>
                            {typeof document !== "undefined" &&
                            document.getElementById("user-info-server") ? (
                              <MobileOnlyNavItems
                                history={this.props.history}
                                userInfo={JSON.parse(
                                  document.getElementById("user-info-server")
                                    .textContent
                                )}
                                collapseNav={this.collapseNav}
                              />
                            ) : (
                              <>
                                {typeof document !== "undefined" ? (
                                  <MobileLogin
                                    history={this.props.history}
                                    collapseNav={this.collapseNav}
                                  />
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <MobileOnlyNavItems
                            history={this.props.history}
                            userInfo={this.props.userInfo}
                            collapseNav={this.collapseNav}
                          />
                        )}

                        <MenuItemsBoth collapseNav={this.collapseNav} />

                        {this.props.userInfo.username ||
                        (typeof document !== "undefined" &&
                          document.getElementById("user-info-server")) ? (
                          <LogoutButtonMobile
                            loggingOut={this.state.loggingOut}
                            logout={this.logout}
                          />
                        ) : (
                          <></>
                        )}
                      </ul>
                    </div>
                    <div className="col-12 col-lg-4 d-flex justify-content-end align-items-center">
                      {!this.props.userInfo.username ? (
                        <>
                          {typeof document !== "undefined" &&
                          document.getElementById("user-info-server") ? (
                            <DropdownMenuDesktop
                              userInfo={JSON.parse(
                                document.getElementById("user-info-server")
                                  .textContent
                              )}
                              nav={this.nav}
                              loggingOut={this.state.loggingOut}
                              logout={this.logout}
                            />
                          ) : (
                            <>
                              {typeof document !== "undefined" ? (
                                <motion.div
                                  transition={t.transition}
                                  exit={
                                    this.props.history?.location?.state?.exit ||
                                    t.fade_out_scale_1
                                  }
                                  animate={t.normalize}
                                  initial={
                                    this.props.history?.location?.state
                                      ?.enter || t.fade_out
                                  }
                                  className={`ms-auto h6 me-3 navbar-user-desktop`}
                                >
                                  <Link onClick={this.collapseNav} to="/login">
                                    <MDBBtn
                                      color="link"
                                      rippleColor="primary"
                                      className="text-nowrap h6 m-0 fw-bold"
                                      style={{
                                        textTransform: "none",
                                        fontSize: "1rem",
                                      }}
                                    >
                                      Login / Register
                                    </MDBBtn>
                                  </Link>
                                </motion.div>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <DropdownMenuDesktop
                          loggingOut={this.state.loggingOut}
                          userInfo={this.props.userInfo}
                          nav={this.nav}
                          logout={this.logout}
                        />
                      )}
                    </div>
                  </div>
                </MDBContainer>
              </MDBCollapse>
            </MDBNavbar>
          </div>
        </div>
      </MDBNavbar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  purge_user,
  set_user,
  reconnect_socket,
  set_server_status,
  set_preference_status,
  notify,
})(NavBar);
