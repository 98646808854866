import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import t from "../../../../utilities/transitions";
import { connect } from "react-redux";
import { MDBBtn } from "mdb-react-ui-kit";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { StaticRouter, Switch, Route } from "react-router-dom";
import JizzerTab from "./jizzerBeta/JizzerTab";

const tabs = ["requests", "active", "disabled", "release-notes"];

class Jizzer extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * tab: String - Tab that the user is currently on
       * loaded: Boolean - Whether the initial data has loaded (placeholder)
       * exit: Object - framer-motion exit transition for the outgoing tab
       * enter: Object - framer-motion entrance transition for the incoming tab
       */
      tab: "requests",
      loaded: false,
      exit: t.fade_out_scale_1,
      enter: t.fade_out,
    };
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      loaded: true,
    });
  }

  /**
   *
   * @param {String} tab - Tab to be sent to
   *
   * Triggered when the user selects a tab
   * Sets appropriate exit/enter transitions, then changes the tab
   */
  selectTab = (e, tab) =>
    this.setState(
      {
        ...this.state,
        exit:
          tabs.indexOf(tab) > tabs.indexOf(this.state.tab)
            ? t.fade_out_left
            : t.fade_out_right,
        enter:
          tabs.indexOf(tab) > tabs.indexOf(this.state.tab)
            ? t.fade_out_right
            : t.fade_out_left,
      },
      () =>
        this.setState({
          ...this.state,
          tab: tab,
        })
    );

  render() {
    return (
      <motion.div
        className="pt-4 d-flex flex-column h-100"
        transition={t.transition}
        initial={t.fade_out_right}
        animate={t.normalize}
        exit={t.fade_out_right}
      >
        <div
          id="instance-all-navigation"
          className="d-flex justify-content-between align-items-center"
        >
          <MDBBtn
            color="link"
            className="text-primary"
            onClick={() => this.props.setView("list")}
            size="lg"
          >
            <i className="fas fa-chevron-left me-2"></i>
            Products
          </MDBBtn>
          <h5 className="m-0 display-6 text-center fs-3">Jizzer</h5>
          <Tabs value={this.state.tab} onChange={this.selectTab}>
            <Tab
              style={{ minHeight: "48px" }}
              label="Requests"
              value="requests"
            />
            <Tab style={{ minHeight: "48px" }} label="Active" value="active" />
            <Tab
              style={{ minHeight: "48px" }}
              label="Disabled"
              value="disabled"
            />
            <Tab
              style={{ minHeight: "48px" }}
              label="Release Notes"
              value="release-notes"
            />
          </Tabs>
        </div>
        <StaticRouter location={this.state.tab}>
          <AnimatePresence exitBeforeEnter>
            <Switch key={this.state.tab}>
              <Route exact path=":tab">
                <JizzerTab
                  tab={this.state.tab}
                  loaded={this.state.loaded}
                  exit={this.state.exit}
                  enter={this.state.enter}
                />
              </Route>
            </Switch>
          </AnimatePresence>
        </StaticRouter>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {})(Jizzer);
