import React from "react";
import Requests from "./Requests";
import Active from "./Active";
import Disabled from "./Disabled";
import ReleaseNotes from "./ReleaseNotes";

class JizzerTab extends React.Component {
  constructor(props) {
    super();
    this.tab = props.tab;
  }

  render() {
    switch (this.tab) {
      case "requests":
        return (
          <Requests
            loaded={this.props.loaded}
            exit={this.props.exit}
            enter={this.props.enter}
          />
        );
      case "active":
        return (
          <Active
            loaded={this.props.loaded}
            exit={this.props.exit}
            enter={this.props.enter}
          />
        );
      case "disabled":
        return (
          <Disabled
            loaded={this.props.loaded}
            exit={this.props.exit}
            enter={this.props.enter}
          />
        );
      case "release-notes":
        return (
          <ReleaseNotes
            loaded={this.props.loaded}
            exit={this.props.exit}
            enter={this.props.enter}
          />
        );
      default:
        console.log("oob tab", this.tab);
        return <></>;
    }
  }
}

export default JizzerTab;
