import React from "react";
import { motion } from "framer-motion";
import h from "../../../../../utilities/helpers";
import t from "../../../../../utilities/transitions";
import { connect } from "react-redux";
import { MDBDatatable, MDBRipple, MDBBtn } from "mdb-react-ui-kit";
import SurveyAnswersModal from "./SurveyAnswersModal";
import ProfileModal from "./ProfileModal";
import ConfirmModal from "./ConfirmModal";

class Requests extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * surveyModalShown: Boolean - Whether the Survery Answers modal is shown
       * profileModalShown: Boolean - Whether the Profile Details modal is shown
       * userSelected: false | Users document - User selected
       * confirmModalShown: Boolean - Whether the Confirm Decision modal is shown
       * action: false | String - The action that the user wishes to take on the applicant
       */
      surveyModalShown: false,
      profileModalShown: false,
      userSelected: false,
      confirmModalShown: false,
      action: false,
    };
  }

  /**
   *
   * @param {Object} user - Users document
   *
   * Triggered when the user clicks on an applicant's profile
   * Selects that applicant and displays the Profile Info modal
   */
  selectUser = (user) =>
    this.setState(
      {
        ...this.state,
        userSelected: user,
      },
      this.toggleProfileModal
    );

  /**
   *
   * @param {Object} user - Users document
   *
   * Triggered when the user clicks on an applicant's survey answers
   * Selects that applicant and displays the Survey Answers modal
   */
  viewSurvey = (user) =>
    this.setState(
      {
        ...this.state,
        userSelected: user,
      },
      this.toggleSurveyModal
    );

  /**
   *
   * @param {Object} user - Users document
   * @param {String} action - "accept" | "reject"
   *
   * Triggered when the user clicks Confirm or Deny
   * Selects the user and opens the Confirm modal
   */
  confirm = (user, action) =>
    this.setState(
      {
        ...this.state,
        userSelected: user,
        action: action,
      },
      this.toggleConfirmModal
    );

  toggleSurveyModal = () =>
    this.setState({
      ...this.state,
      surveyModalShown: !this.state.surveyModalShown,
    });

  setSurveyModal = (option) =>
    this.setState({
      ...this.state,
      surveyModalShown: option,
    });

  toggleProfileModal = () =>
    this.setState({
      ...this.state,
      profileModalShown: !this.state.profileModalShown,
    });

  setProfileModal = (option) =>
    this.setState({
      ...this.state,
      profileModalShown: option,
    });

  toggleConfirmModal = () =>
    this.setState({
      ...this.state,
      confirmModalShown: !this.state.confirmModalShown,
    });

  setConfirmModal = (option) =>
    this.setState({
      ...this.state,
      confirmModalShown: option,
    });

  render() {
    const userList = this.props.userList.filter((u) => {
      const instance = u.instances.find(
        (i) => i.id === "jizzer-beta" && i.status === "requested"
      );
      return instance;
    });
    return (
      <motion.div
        className="fg-1 dashboard-containers"
        transition={t.transition}
        initial={this.props.history?.location?.state?.enter || this.props.enter}
        animate={t.normalize}
        exit={this.props.history?.location?.state?.exit || this.props.exit}
      >
        <SurveyAnswersModal
          modalShown={this.state.surveyModalShown}
          setShowModal={this.setSurveyModal}
          toggleShowModal={this.toggleSurveyModal}
          user={this.state.userSelected}
        />
        <ProfileModal
          modalShown={this.state.profileModalShown}
          setShowModal={this.setProfileModal}
          toggleShowModal={this.toggleProfileModal}
          user={this.state.userSelected}
        />
        <ConfirmModal
          modalShown={this.state.confirmModalShown}
          setShowModal={this.setConfirmModal}
          toggleShowModal={this.toggleConfirmModal}
          user={this.state.userSelected}
          action={this.state.action}
        />
        {userList.length ? (
          <MDBDatatable
            className="h-100 d-flex flex-column table-vertical-center"
            data={{
              columns: [
                { label: "User", field: "user" },
                { label: "Email", field: "email" },
                { label: "Date", field: "date" },
                { label: "Survey Answers", field: "answers" },
                { label: "Actions", field: "actions" },
              ],
              rows: userList
                .filter((u) => {
                  const instance = u.instances.find(
                    (i) => i.id === "jizzer-beta" && i.status === "requested"
                  );
                  return instance;
                })
                .map((user) => {
                  return {
                    user: (
                      <MDBRipple
                        onClick={() => this.selectUser(user)}
                        rippleColor="#1976d2"
                        tag="div"
                        className="p-1 rounded-4 d-flex align-items-center cursor-pointer"
                      >
                        <div className="d-flex justify-content-center align-items-center square-3 me-2">
                          <div
                            className="fit-images fit-round"
                            style={{
                              backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/thumbnails/${user.avatar.thumbnail}")`,
                              borderRadius: "50%",
                            }}
                          ></div>
                        </div>
                        <h5 className="m-0 display-6 fs-5">{user.username}</h5>
                      </MDBRipple>
                    ),
                    email: user.email,
                    date: h.makeDateHR(
                      new Date(
                        user.instances
                          .find((i) => i.id === "jizzer-beta")
                          .actions.find((a) => a.action === "request").timestamp
                      )
                    ),
                    answers: (
                      <MDBBtn
                        onClick={() => this.viewSurvey(user)}
                        color="link"
                        rippleColor="#1976d2"
                        className="text-darkblu"
                      >
                        View Answers
                      </MDBBtn>
                    ),
                    actions: (
                      <>
                        <MDBBtn
                          onClick={() => this.confirm(user, "Approve")}
                          color="link"
                          rippleColor="success"
                          className="text-success d-block"
                        >
                          Approve
                        </MDBBtn>
                        <MDBBtn
                          onClick={() => this.confirm(user, "Deny")}
                          color="link"
                          rippleColor="danger"
                          className="text-danger mt-2 d-block"
                        >
                          Deny
                        </MDBBtn>
                      </>
                    ),
                  };
                }),
            }}
          />
        ) : (
          <>
            <hr />
            <h5 className="text-center">No open requests found</h5>
          </>
        )}
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {})(Requests);
