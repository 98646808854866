import React from "react";
import t from "../../../utilities/transitions";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import {
  MDBContainer,
  // MDBListGroup, MDBListGroupItem
} from "mdb-react-ui-kit";
import { payment_success, change_user_details } from "../../../redux/actions";
import axios from "axios";
import { v4 as uuid } from "uuid";
import Spinner from "../../../components/Spinner";

class JizzerPayment extends React.Component {
  constructor() {
    super();
    this.state = {
      working: true,
      status: [],
    };
  }

  componentDidMount() {
    this.props.socket.on("jizzer-payment-status", (status) => {
      status.id = uuid();
      this.setState((curr) => ({
        ...curr,
        status,
      }));
    });
    this.props.resetAnimations();
    axios
      .get("/jizzer/jizzer-purchase")
      .then((res) => this.props.change_user_details(res.data.user))
      .catch((err) => alert("error", err));
  }

  componentDidUpdate(prevProps) {
    if (this.props.userInfo._id) {
      if (
        !prevProps.userInfo.instances.find((i) => i.service === "jizzer") &&
        this.props.userInfo.instances.find((i) => i.service === "jizzer")
      )
        this.props.payment_success({
          icon: <i className="fas fa-check-circle text-success me-2" />,
          text: "Payment Successful",
          instance: "jizzer",
        });
    }
  }

  componentWillUnmount() {
    this.props.socket.off("jizzer-payment-status");
  }

  render() {
    return (
      <motion.div
        className="pt-4"
        transition={t.transition}
        initial={this.props.enter}
        animate={t.normalize}
        exit={this.props.exit}
      >
        <MDBContainer>
          <h5 className="text-center display-6 m-0">
            Payment Received. Please Wait.
          </h5>
          <div className="mt-5 d-flex justify-content-center">
            <Spinner color="primary" />
          </div>
          {/* <hr />
          <MDBListGroup light>
            {this.state.status.map((s) => {
              let statusIcon;
              switch (s.status) {
                case "inProgress":
                  statusIcon = <Spinner color="primary" size="sm" />;
                  break;
                case "complete":
                  statusIcon = (
                    <i className="fas fa-check-circle text-success" />
                  );
                  break;
                default:
                  statusIcon = (
                    <i className="fas fa-times-circle text-danger" />
                  );
              }
              return (
                <MDBListGroupItem key={s.id}>
                  <motion.div
                    className="d-flex justify-content-between align-items-center"
                    transition={t.transition}
                    initial={t.fade_out}
                    animate={t.normalize}
                    exit={t.fade_out_scale_1}
                  >
                    {s.label}
                    {statusIcon}
                  </motion.div>
                </MDBListGroupItem>
              );
            })}
          </MDBListGroup> */}
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {
  payment_success,
  change_user_details,
})(JizzerPayment);
