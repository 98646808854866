import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import t from "../../utilities/transitions";
import { useSwipeable } from "react-swipeable";

const Emojis = (props) => {
  const history = useHistory();
  const location = useLocation();

  /**
   * Adds swipe functionality to the picker
   */
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => props.setEmojiIndex(props.index + 1),
    onSwipedRight: () => props.setEmojiIndex(props.index - 1),
  });

  // Loop through JSON emojis, replace emoji with svg so they are uniform across all devices
  return (
    <motion.div
      {...swipeHandlers}
      transition={t.transition_fast}
      exit={props.exit}
      animate={t.normalize}
      initial={props.entrance}
      className="emoji-panel__body"
    >
      {props.json[props.index].emojis.map((item, index) => (
        <button
          className="emoji-panel__btn emoji-svg"
          key={index}
          onClick={() => props.onEmojiSelect(item)}
        >
          {props.createSvg(item.unicode, item.title)}
        </button>
      ))}
    </motion.div>
  );
};

/**
 * Need a function inside a class to have this.whatever and also be able to use hooks
 */
class EmojiBody extends React.Component {
  constructor(props) {
    super();
    this.index = props.emojiIndex;
  }

  render() {
    return (
      <Emojis
        index={this.index}
        exit={this.props.exit}
        entrance={this.props.entrance}
        onEmojiSelect={this.props.onEmojiSelect}
        createSvg={this.props.createSvg}
        json={this.props.json}
        setEmojiIndex={this.props.setEmojiIndex}
      />
    );
  }
}

export default EmojiBody;
