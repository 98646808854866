import React from "react";
import Logos from "./Logos";
import Settings from "./Settings";
import Verbiage from "./Verbiage";
import Colors from "./Colors";

class PreferenceView extends React.Component {
  constructor(props) {
    super();
    this.view = props.view;
  }

  /**
   * logos
   * verbiage
   * settings, rules
   * CSS, colors
   *
   */

  render() {
    switch (this.view) {
      case "logos":
        return <Logos {...this.props} />;
      case "settings":
        return <Settings {...this.props} />;
      case "verbiage":
        return <Verbiage {...this.props} />;
      case "colors":
        return <Colors {...this.props} />;
      default:
        console.log("oob view", this.view);
        return <></>;
    }
  }
}

export default PreferenceView;
