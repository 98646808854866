import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import t from "../utilities/transitions";
import { user_schema } from "../utilities/validations";
import axios from "axios";
import { set_user, set_verification_details } from "../redux/actions";
import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBBtn,
  MDBContainer,
  MDBRipple,
} from "mdb-react-ui-kit";
import h from "../utilities/helpers";
import Spinner from "../components/Spinner";
import { Link } from "react-router-dom";
import BioField from "./createAccount/BioField";
import { useHistory, useLocation } from "react-router-dom";

/**
 * Create Account
 */

const fields = [
  {
    text: "Username",
    id: "username",
    type: "text",
  },
  {
    text: "Email Address",
    id: "email",
    type: "text",
  },
  {
    text: "Password",
    id: "password1",
    type: "password",
  },
  {
    text: "Re-Enter Password",
    id: "password2",
    type: "password",
  },
  {
    text: "Display Name",
    id: "displayName",
    type: "text",
  },
  {
    text: "Location (Optional)",
    id: "location",
    type: "text",
  },
  {
    text: "Website (Optional)",
    id: "website",
    type: "text",
  },
];

const allowedExtensions = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/bmp",
  "image/webp",
  "image/svg+xml",
];

const CreateAccount = ({
  captchaReady,
  set_verification_details,
  set_user,
  tempAction,
  userInfo,
  verificationDetails,
}) => {
  const [working, setWorking] = useState(false);
  const [inputs, setInputs] = useState(
    fields.map((field) => ({
      id: field.id,
      error: "",
      invalid: true,
      value: "",
    }))
  );
  const [avatar, setAvatar] = useState({
    path: `${process.env.REACT_APP_BUCKET_HOST}/images/blank-avatar.png`,
    name: "Click to Change",
    file: "",
  });
  const [background, setBackground] = useState({
    path: `${process.env.REACT_APP_BUCKET_HOST}/images/default-background.webp`,
    name: "Click to Change",
    file: "",
  });
  const [forceParse, setForceParse] = useState(false);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    changeHandler({
      target: {
        name: "",
      },
    });
  }, []);

  useEffect(() => {
    if (userInfo.username) {
      if (tempAction?.page)
        history.push(tempAction.page, {
          exit: t.fade_out_left,
          enter: t.fade_out_right,
        });
      else history.push("/dashboard");
    }
  }, [userInfo.username]);

  useEffect(() => {
    if (verificationDetails) history.push("/validate-email");
  }, [verificationDetails]);

  /**
   * Executes a captcha challenge and generates a key a key
   * Will hang until connected to captcha servers
   */
  const getRecaptcha = () =>
    new Promise(async (resolve, reject) => {
      if (String(process.env.REACT_APP_DEV) === "true")
        return resolve(process.env.REACT_APP_DEV_CAPTCHA_KEY);
      if (captchaReady)
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
          .then(resolve)
          .catch((err) => {
            console.log(err);
            alert("Human verification failed. Refresh the page and try again.");
            reject();
          });
      else
        setTimeout(async () => {
          const captchaKey = await getRecaptcha();
          resolve(captchaKey);
        }, 500);
    });

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  const changeHandler = (e) => {
    setInputs((curr) => {
      curr = curr.map((input) => {
        if (input.id === e.target.name)
          return {
            ...input,
            value: e.target.value,
          };
        else return input;
      });

      const data = Object.fromEntries(
        curr.map((input) => [input.id, input.value.trim()])
      );
      try {
        user_schema.validateSync(data, {
          abortEarly: false,
        });
        curr = curr.map((input) => {
          document.getElementById(input.id).setCustomValidity("");
          return {
            ...input,
            invalid: false,
            error: "",
          };
        });
      } catch (err) {
        let errorsAdded = [];
        curr = curr.map((input) => {
          if (
            err.inner.find((error) => error.path === input.id) &&
            errorsAdded.indexOf(input.id) === -1
          ) {
            errorsAdded.push(input.id);
            return {
              ...input,
              invalid: true,
              error: err.inner.find((error) => error.path === input.id).message,
            };
          } else
            return {
              ...input,
              invalid: false,
              error: "",
            };
        });
      }

      curr.forEach((input) => {
        if (input.invalid)
          document
            .getElementById(input.id)
            .setCustomValidity(input.error || "Invalid");
        else document.getElementById(input.id).setCustomValidity("");
      });

      return curr;
    });
  };

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Make request to server
   * Set user to newly created account
   */
  const submit = async () => {
    try {
      setForceParse(!forceParse);
      document.getElementById("form").classList.add("was-validated");
      let invalidInputs = inputs.filter((input) => input.invalid);
      invalidInputs.forEach((input) =>
        document
          .getElementById(input.id)
          .setCustomValidity(input.error || "Invalid")
      );
      if (!working && !invalidInputs.length) {
        setWorking(true);
        const data = Object.fromEntries(
          inputs.map((input) => [input.id, input.value.trim()])
        );
        try {
          user_schema.validateSync(data, {
            abortEarly: false,
          });
          const bio = document.getElementById("input-bio");
          const length = String(bio.textContent)
            .split("")
            .filter((c) => {
              const checkWhiteSpace = c.match(/[\s]/);
              if (!checkWhiteSpace) return true;
              else {
                return [" ", "\n"].indexOf(c) > -1;
              }
            }).length;
          if (length > 1000) {
            setWorking(false);
            return alert("Your bio is too long (Max: 1000 chars)");
          } else {
            const captchaKey = await getRecaptcha();
            const fd = new FormData();
            for (const key in data) {
              fd.append(key, data[key]);
            }
            fd.append(
              "bio",
              bio.innerHTML.replace(/[\u200B-\u200D\uFEFF]/g, "")
            );
            if (avatar.file) fd.append("avatar", avatar.file, avatar.name);
            if (background.file)
              fd.append("background", background.file, background.name);
            fd.append("captchaKey", captchaKey);
            axios
              .post("/auth/create_account", fd)
              .then((res) => {
                if (res.data.error) {
                  setWorking(false);
                  alert(res.data.error);
                } else {
                  if (
                    process.env.REACT_APP_EMAIL_VERIFICATION_REQUIRED === "true"
                  )
                    set_verification_details(res.data);
                  else set_user(res.data);
                }
              })
              .catch((err) => {
                setWorking(false);
                console.log(err);
                alert("An error occurred. Please try again later");
              });
          }
        } catch (err) {
          setWorking(false);
          console.log(err);
          alert("An error occurred. Please try again later");
        }
      }
    } catch (err) {
      setWorking(false);
      console.log("Submit error", err);
      alert("An error occurred. Please try again later.");
    }
  };

  /**
   * Submit the form if the user presses the enter key while in one of the inputs
   */
  const pressEnter = (e) => {
    if (e.key === "Enter") submit();
  };

  /**
   * Fired when the user clicks their avatar or background
   *
   * Creates a virtual file input
   * Adds a change event that sets the selected file into state
   * Appends to document body (necessary for iDevices and possibly others)
   * Clicks the input
   * Removes the input after the file is selected
   */
  const selectFile = (type) => {
    let input = document.createElement("input");
    input.type = "file";
    input.style.visibility = "hidden";
    input.style.position = "fixed";
    document.body.appendChild(input);
    input.onchange = (e) => {
      let file = e.target.files[0];
      if (allowedExtensions.indexOf(file.type) !== -1) {
        if (file.size > Number(process.env.REACT_APP_MAX_INDIVIDUAL_FILE_SIZE))
          alert(
            `Max individual file size exceeded. (Max: ${Math.round(
              Number(process.env.REACT_APP_MAX_INDIVIDUAL_FILE_SIZE) /
                (1024 * 1024)
            )}MB)`
          );
        else {
          const fun = type === "avatar" ? setAvatar : setBackground;
          fun({
            name: e.target.files[0].name,
            file: e.target.files[0],
            path: URL.createObjectURL(e.target.files[0]),
          });
          document.body.removeChild(input);
        }
      } else {
        document.body.removeChild(input);
        alert("Please select a valid image file (png, jpg, gif, bmp, webp)");
      }
    };
    input.click();
  };

  /**
   *
   * @param {Event} e - Keypress event
   *
   * Triggered when the user presses the Tab key
   * Moves cursor to next input (MDB is bugged)
   * Removed when MDB fixes
   */
  const pressTab = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const input = inputs.find((f) => f.id === e.target.id);
      if (input) {
        const nextField = inputs[inputs.indexOf(input) + 1];
        if (nextField) {
          const element = document.getElementById(nextField.id);
          if (element) {
            setTimeout(() => {
              element.focus();
              element.select();
            }, 100);
          }
        }
      }
    }
  };

  h.floatLabels();

  return (
    <motion.div
      className="py-4"
      transition={t.transition}
      exit={history?.location?.state?.exit || t.fade_out_scale_1}
      animate={t.normalize}
      initial={history?.location?.state?.enter || t.fade_out}
    >
      <MDBContainer>
        <h1 className="display-4 text-center">Create Account</h1>
        <div className="w-100 d-flex justify-content-center">
          <Link
            to="/login"
            className="mt-2 mb-0 text-center text-primary cursor-pointer"
          >
            Already Have an Account? Login
            <i className="fas fa-sign-in-alt ms-2"></i>
          </Link>
        </div>
        <hr></hr>
        <MDBValidation
          method="dialog"
          id="form"
          name="form"
          className="row mt-4"
          onSubmit={submit}
        >
          {fields
            .filter((field) => ["bio", "avatar"].indexOf(field.id) === -1)
            .map((i) => (
              <MDBValidationItem
                key={i.id}
                className="pb-4 col-12 col-lg-6"
                feedback={inputs.find((input) => input.id === i.id).error}
                invalid={true}
              >
                <MDBInput
                  name={i.id}
                  onChange={changeHandler}
                  id={i.id}
                  label={i.text}
                  size="lg"
                  className={
                    !inputs.find((input) => input.id === i.id).invalid
                      ? "mb-0"
                      : 0
                  }
                  type={i.type}
                  onKeyPress={pressEnter}
                  onKeyDown={pressTab}
                />
              </MDBValidationItem>
            ))}
          <div className="col-12 col-lg-6">
            <BioField working={working} />
          </div>
        </MDBValidation>
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6 mt-4">
            <p style={{ fontSize: "1.5rem" }} className="text-center display-6">
              Display Picture
            </p>
            <div className="d-flex justify-content-center">
              <div className="d-flex justify-content-center align-items-center square-15 mx-auto">
                <MDBRipple
                  onClick={() => selectFile("avatar")}
                  tag="div"
                  rippleColor="primary"
                  className="fit-images fit-round"
                  style={{
                    backgroundImage: `url("${avatar.path}")`,
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                ></MDBRipple>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-4">
            <p style={{ fontSize: "1.5rem" }} className="text-center display-6">
              Background Picture
            </p>
            <div
              style={{ height: "250px" }}
              className="d-flex justify-content-center align-items-center w-100 mx-auto"
            >
              <MDBRipple
                onClick={() => selectFile("background")}
                tag="div"
                rippleColor="light"
                className="fit-background cursor-pointer"
                style={{ backgroundImage: `url("${background.path}")` }}
              ></MDBRipple>
            </div>
          </div>
          <div className="col-12">
            <hr></hr>
          </div>
          <div className="col-12 col-lg-6">
            {working ? (
              <MDBBtn
                color="success"
                size="lg"
                className="w-100 mt-4"
                block
                disabled
              >
                <Spinner size="sm" className="me-2" />
                Working
              </MDBBtn>
            ) : (
              <MDBBtn
                color="success"
                onClick={submit}
                size="lg"
                block
                className="w-100 mt-4"
              >
                <i className="fas fa-paper-plane me-2"></i>Submit
              </MDBBtn>
            )}
          </div>
        </div>
        <small className="mt-2 d-block mx-auto text-center">
          This site is protected by reCAPTCHA and the Google{" "}
          <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
          <a href="https://policies.google.com/terms"> Terms of Service</a>{" "}
          apply.
        </small>
      </MDBContainer>
    </motion.div>
  );
};
const mapStateToProps = (state) => {
  return {
    captchaReady: state.captchaReady,
    tempAction: state.tempAction,
    userInfo: state.userInfo,
    verificationDetails: state.verificationDetails,
  };
};

export default connect(mapStateToProps, {
  set_user,
  set_verification_details,
})(CreateAccount);
