import React from "react";
import { motion } from "framer-motion";
import t from "../../../../../utilities/transitions";
import Spinner from "../../../../../components/Spinner";
import { MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit";

const LaunchStatus = ({ status }) => {
  return (
    <motion.div
      transition={t.transition}
      initial={t.fade_out}
      animate={t.normalize}
      exit={t.fade_out_scale_1}
      className="form-containers"
    >
      <h5 className="text-center">Launching App...</h5>
      <hr />
      <MDBListGroup light>
        {status.map((s) => {
          let statusIcon;
          switch (s.status) {
            case "inProgress":
              statusIcon = <Spinner color="primary" size="sm" />;
              break;
            case "complete":
              statusIcon = <i className="fas fa-check-circle text-success" />;
              break;
            default:
              statusIcon = <i className="fas fa-times-circle text-danger" />;
          }
          return (
            <MDBListGroupItem key={s.id}>
              <motion.div
                className="d-flex justify-content-between align-items-center"
                transition={t.transition}
                initial={t.fade_out}
                animate={t.normalize}
                exit={t.fade_out_scale_1}
              >
                {s.label}
                {statusIcon}
              </motion.div>
            </MDBListGroupItem>
          );
        })}
      </MDBListGroup>
    </motion.div>
  );
};

export default LaunchStatus;
