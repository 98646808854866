import React from "react";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import t from "../../utilities/transitions";
import { set_temp_action, select_instance } from "../../redux/actions";
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardFooter,
  MDBContainer,
  MDBBtn,
} from "mdb-react-ui-kit";

class List extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.props.resetAnimations();
  }

  getStarted = (product) => {
    switch (product) {
      case "jizzer":
        if (this.props.userInfo._id) {
          if (
            this.props.userInfo.instances?.find((i) => i.service === "jizzer")
          )
            this.props.select_instance("jizzer");
          else this.props.changePage("jizzer-terms");
        } else {
          this.props.set_temp_action({
            action: "continue",
            product: "jizzer",
            label: "Continue",
            page: "/products",
          });
          this.props.history.push("/login", {
            exit: t.fade_out_left,
            enter: t.fade_out_right,
          });
        }
        break;
      default:
        console.log("oob product", product);
    }
  };

  render() {
    return (
      <motion.div
        transition={t.transition}
        initial={this.props.enter}
        animate={t.normalize}
        exit={this.props.exit}
      >
        <MDBContainer>
          <div className="col-12 col-md-6 col-xl-5 mt-4">
            <MDBCard>
              <MDBCardHeader className="d-flex justify-content-between align-items-center">
                <h5 className="m-0">Filepimps</h5>
                <small className="text-gray">Cocktease Edition β</small>
              </MDBCardHeader>
              <MDBCardBody className="d-flex align-items-start">
                <div className="h-max-content">
                  <div className="square-6">
                    <div
                      className="fit-images"
                      style={{
                        backgroundImage:
                          'url("/assets/images/jizzer-logo.png")',
                      }}
                    ></div>
                  </div>
                </div>
                <div className="flex-grow-1 ps-3">
                  <p className="m-0">
                    Fully featured Twitter alternative that includes live
                    streaming, searching, file sharing, encrypted messaging, and
                    much more.
                  </p>
                </div>
              </MDBCardBody>
              <MDBCardFooter className="d-flex justify-content-between">
                <MDBBtn
                  onClick={() => this.props.history.push("/info#jizzer")}
                  className="text-darkblu"
                  color="link"
                  rippleColor="#1976d2"
                >
                  <i className="fas fa-info-circle me-2"></i>
                  Info
                </MDBBtn>
                {this.props.userInfo.instances?.find(
                  (i) => i.id === "jizzer"
                ) ? (
                  <MDBBtn
                    onClick={() => this.props.select_instance("jizzer")}
                    color="link"
                    rippleColor="primary"
                  >
                    View Details
                    <i className="fas fa-chevron-right ms-2"></i>
                  </MDBBtn>
                ) : (
                  <MDBBtn
                    onClick={() => this.getStarted("jizzer")}
                    color="link"
                    rippleColor="primary"
                  >
                    Get Started
                    <i className="fas fa-chevron-right ms-2"></i>
                  </MDBBtn>
                )}
              </MDBCardFooter>
            </MDBCard>
          </div>
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {
  set_temp_action,
  select_instance,
})(List);
