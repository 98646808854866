import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import t from "../../../../../utilities/transitions";
import {
  MDBValidation,
  MDBValidationItem,
  MDBTextArea,
  MDBListGroup,
  MDBListGroupItem,
  MDBContainer,
} from "mdb-react-ui-kit";
import colors from "./formData/colors";

const Colors = ({
  animations,
  changeHandler,
  clearErrorPage,
  errorPage,
  formValues,
  formsSubmitted,
  view,
}) => {
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const errors = formValues.filter((v) => v.tab === "colors" && v.error);
    errors.forEach((error) => {
      const element = document.getElementById(error.field);
      if (element) element.setCustomValidity(error.error);
    });
  }, []);

  const scrollToError = () => {
    if (!scrolled && errorPage && view === "colors") {
      const errors = formValues.filter((v) => v.tab === "colors" && v.error);
      for (let e = 0; e < errors.length; e++) {
        const error = errors[e];
        const element = document.getElementById(error.field);
        if (element) element.scrollIntoView();
        clearErrorPage();
        setScrolled(true);
        break;
      }
    }
  };

  return (
    <motion.div
      transition={t.transition}
      initial={animations.enter}
      animate={t.normalize}
      exit={animations.exit}
      onAnimationComplete={scrollToError}
    >
      <MDBValidation
        className={formsSubmitted.includes("colors") ? "was-validated" : ""}
      >
        <MDBListGroup light>
          {colors
            .filter((color) => color.type === "color")
            .map((item) => {
              const data = formValues.find((v) => v.field === item.id);
              return (
                <MDBListGroupItem
                  key={item.id}
                  className="d-flex justify-content-between align-items-center"
                >
                  <h6 className="m-0 fs-5 display-6">{item.label}</h6>
                  <input
                    type="color"
                    id={item.id}
                    name={item.id}
                    value={data.value}
                    onChange={changeHandler}
                    className="cursor-pointer"
                  />
                </MDBListGroupItem>
              );
            })}
        </MDBListGroup>
        <hr />
        <h5 className="text-center">Custom CSS</h5>
        <MDBContainer fluid>
          <div className="row">
            <MDBValidationItem
              className="pb-4 col-12 col-xl-4"
              feedback={formValues.find((v) => v.field === "custom_css").error}
              invalid={true}
            >
              <MDBTextArea
                name="custom_css"
                onChange={changeHandler}
                id="custom_css"
                label="All Themes"
                size="lg"
                className={
                  !formValues.find((v) => v.field === "custom_css").error
                    ? "mb-0"
                    : 0
                }
                value={formValues.find((v) => v.field === "custom_css").value}
                rows={12}
              />
            </MDBValidationItem>
            <MDBValidationItem
              className="pb-4 col-12 col-xl-4"
              feedback={
                formValues.find((v) => v.field === "custom_css_default").error
              }
              invalid={true}
            >
              <MDBTextArea
                name="custom_css_default"
                onChange={changeHandler}
                id="custom_css_default"
                label="Default Theme"
                size="lg"
                className={
                  !formValues.find((v) => v.field === "custom_css_default")
                    .error
                    ? "mb-0"
                    : 0
                }
                value={
                  formValues.find((v) => v.field === "custom_css_default").value
                }
                rows={12}
              />
            </MDBValidationItem>
            <MDBValidationItem
              className="pb-4 col-12 col-xl-4"
              feedback={
                formValues.find((v) => v.field === "custom_css_dark").error
              }
              invalid={true}
            >
              <MDBTextArea
                name="custom_css_dark"
                onChange={changeHandler}
                id="custom_css_dark"
                label="Dark Theme"
                size="lg"
                className={
                  !formValues.find((v) => v.field === "custom_css_dark").error
                    ? "mb-0"
                    : 0
                }
                value={
                  formValues.find((v) => v.field === "custom_css_dark").value
                }
                rows={12}
              />
            </MDBValidationItem>
          </div>
        </MDBContainer>
      </MDBValidation>
    </motion.div>
  );
};

export default Colors;
