import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
const translations = {
  video: "Videos",
  audio: "Audio",
  image: "Images",
  other: "Other",
};

const addLabels = (series) => {
  return series.map((item) => ({
    ...item,
    label: translations[item.dataKey],
    valueFormatter: (v) => {
      return v ? `${v.toFixed(3)} GB` : "-";
    },
  }));
};

const InstanceSize = ({ stats }) => {
  console.log("stats", stats);
  const chartData = stats
    .filter((stat, s) => s >= stats.length - 12)
    .map((stat, s) => ({
      // month: `${months[stat.month]} (${stats
      //   .filter((stat, s) => s >= stats.length - 12)
      //   .filter((stat, index) => index <= s)
      //   .reduce(
      //     (prev, curr) =>
      //       prev +
      //       (curr.totals.other.size +
      //         curr.totals.image.size +
      //         curr.totals.video.size +
      //         curr.totals.audio.size) /
      //         (1024 * 1024 * 1000),
      //     0
      //   )
      //   .toFixed(3)} GB)`,
      month: months[stat.month],
      audio: stats
        .filter((stat, s) => s >= stats.length - 12)
        .filter((stat, index) => index <= s)
        .reduce(
          (prev, curr) => prev + curr.totals.audio.size / (1024 * 1024 * 1000),
          0
        ),
      image: stats
        .filter((stat, s) => s >= stats.length - 12)
        .filter((stat, index) => index <= s)
        .reduce(
          (prev, curr) => prev + curr.totals.image.size / (1024 * 1024 * 1000),
          0
        ),
      video: stats
        .filter((stat, s) => s >= stats.length - 12)
        .filter((stat, index) => index <= s)
        .reduce(
          (prev, curr) => prev + curr.totals.video.size / (1024 * 1024 * 1000),
          0
        ),
      other: stats
        .filter((stat, s) => s >= stats.length - 12)
        .filter((stat, index) => index <= s)
        .reduce(
          (prev, curr) => prev + curr.totals.other.size / (1024 * 1024 * 1000),
          0
        ),
    }));
  return (
    <>
      <h5 className="display-6 text-center">Storage</h5>
      <BarChart
        dataset={chartData}
        series={addLabels([
          { dataKey: "audio", stack: "all" },
          { dataKey: "video", stack: "all" },
          { dataKey: "image", stack: "all" },
          { dataKey: "other", stack: "all" },
        ])}
        xAxis={[{ scaleType: "band", dataKey: "month" }]}
        slotProps={{ legend: { hidden: true } }}
        height={350}
      />
    </>
  );
};

export default InstanceSize;
