import {
  Route,
  Switch,
  useHistory,
  useLocation,
  Redirect,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";
import {
  set_history,
  set_captcha_ready,
  remove_notification,
  set_screen_dimensions,
} from "./redux/actions";
import { connect } from "react-redux";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Resets from "./pages/Resets";
import Cancel from "./pages/Cancel";
import Message from "./pages/Message";
import CreateAccount from "./pages/CreateAccount";
import Home from "./pages/Home";
import NavBar from "./components/NavBar";
import ValidateEmail from "./pages/ValidateEmail";
import Validate from "./pages/Validate";
import Dashboard from "./pages/Dashboard";
import Products from "./pages/Products";
import Feedback from "./pages/Feedback";
import Info from "./pages/Info";
import InstanceRedirect from "./pages/InstanceRedirect";
import Instances from "./pages/Instances";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Notifications from "./components/Notifications";

const App = (props) => {
  const location = useLocation();
  const history = useHistory();

  const resize = () =>
    props.set_screen_dimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });

  useEffect(() => {
    props.set_history(location, history); // Set history into redux store as soon as app is rendered on the client
    document.addEventListener("onautocomplete", (e) => {
      if (e.target.classList.contains("form-control"))
        e.target.classList.add("active");
    });
    window.addEventListener("resize", resize);
  }, []);

  useEffect(() => {
    if (
      process.env.REACT_APP_DEV !== "true" &&
      window.grecaptcha?.enterprise?.ready
    )
      window.grecaptcha.enterprise.ready(props.set_captcha_ready);
  }, [String(window.grecaptcha?.enterprise?.ready)]);

  /**
   * Currently using React Router 5
   * React Router 6 recently released; stupidly not made backwards-compatible and removes numerous features with no replacement
   */

  const theme = createTheme({
    palette: {
      primary: {
        main: process.env.REACT_APP_COLOR_PRIMARY,
      },
      secondary: {
        main: process.env.REACT_APP_COLOR_SECONDARY,
      },
      info: {
        main: process.env.REACT_APP_COLOR_INFO,
      },
      warning: {
        main: process.env.REACT_APP_COLOR_WARNING,
      },
      danger: {
        main: process.env.REACT_APP_COLOR_DANGER,
      },
      success: {
        main: process.env.REACT_APP_COLOR_SUCCESS,
      },
      light: {
        main: process.env.REACT_APP_COLOR_LIGHT,
      },
      dark: {
        main: process.env.REACT_APP_COLOR_DARK,
      },
    },
  });

  if (!(props.history && props.location)) return <></>;

  return (
    /**
     * AnimatePresence tells the child components to use framer motion page transitions
     * exitBeforeEnter flag makes sure the exit transition completes before the entrance on the next page initiates
     * The "key" in the Switch is necessary for animations to work properly. Needs to be unique on every page change, hence setting it to location.pathname
     */
    <ThemeProvider theme={theme}>
      <NavBar />
      <div className="flex-grow-1 position-relative">
        <div className="h-100 d-flex flex-column">
          <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/info">
                <Info />
              </Route>
              <Route exact path="/instance/:instance">
                <InstanceRedirect />
              </Route>
              <Route exact path="/tag/:tag">
                <Message
                  message="The link you visited is invalid from carbonvalley.net, but will redirect the user to a tag on your instance."
                  history={history}
                  location={location}
                />
              </Route>
              <Route exact path="/feedback">
                <Feedback />
              </Route>
              <Route exact path="/instances">
                <Instances />
              </Route>
              <Route exact path="/products">
                <Products />
              </Route>
              <Route exact path="/dashboard">
                <Dashboard />
              </Route>
              <Route exact path="/set-password/:id">
                <Resets />
              </Route>
              <Route exact path="/forgot-password">
                <ForgotPassword />
              </Route>
              <Route exact path="/cancel/:id">
                <Cancel />
              </Route>
              <Route exact path="/check-email">
                <Message
                  message="An email has been sent with a link to reset your password."
                  history={history}
                  location={location}
                />
              </Route>
              <Route exact path="/feedback-received">
                <Message
                  message="Thank you for your feedback. If we need more details, we will reach out to you via email."
                  history={history}
                  location={location}
                />
              </Route>
              <Route exact path="/received">
                <Message
                  message="Your request has been received. Please keep an eye on your email inbox or Carbon Valley dashboard for our response."
                  history={history}
                  location={location}
                />
              </Route>
              <Route exact path="/create-account">
                <CreateAccount />
              </Route>
              <Route exact path="/validate-email">
                <ValidateEmail />
              </Route>
              <Route exact path="/verify/:id">
                <Validate />
              </Route>
              <Route exact path="/not-found">
                <Message
                  message="Not Found"
                  history={history}
                  location={location}
                />
              </Route>
              <Route exact path="/null">
                <Redirect to="/" />
              </Route>
              <Route path="*">
                <Message
                  message="Not Found"
                  history={history}
                  location={location}
                />
              </Route>
            </Switch>
          </AnimatePresence>
        </div>
        <Notifications />
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  set_history,
  set_captcha_ready,
  remove_notification,
  set_screen_dimensions,
})(App);
