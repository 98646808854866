import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { connect } from "react-redux";
import { forgot_password_schema } from "../utilities/validations";
import t from "../utilities/transitions";
import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBBtn,
  MDBContainer,
} from "mdb-react-ui-kit";
import h from "../utilities/helpers";
import Spinner from "../components/Spinner";
import { useHistory, useLocation } from "react-router-dom";

const fields = [
  {
    text: "Username",
    id: "username",
  },
  {
    text: "Email Address",
    id: "email",
  },
];

const ForgotPassword = ({ captchaReady }) => {
  const [working, setWorking] = useState(false);
  const [inputs, setInputs] = useState(
    fields.map((field) => ({
      id: field.id,
      error: "",
      invalid: true,
      value: "",
    }))
  );

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    changeHandler({
      target: {
        name: "",
      },
    });
  }, []);

  useEffect(() => {
    console.log(history, location);
  }, [location.pathname]);

  /**
   * Executes a captcha challenge and generates a key a key
   * Will hang until connected to captcha servers
   */
  const getRecaptcha = () =>
    new Promise(async (resolve, reject) => {
      if (String(process.env.REACT_APP_DEV) === "true")
        return resolve(process.env.REACT_APP_DEV_CAPTCHA_KEY);
      if (captchaReady)
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
          .then(resolve)
          .catch((err) => {
            console.log(err);
            alert("Human verification failed. Refresh the page and try again.");
            reject();
          });
      else
        setTimeout(async () => {
          const captchaKey = await getRecaptcha();
          resolve(captchaKey);
        }, 500);
    });

  /**
   * Submit the form if the user presses the enter key while in one of the inputs
   */
  const pressEnter = (e) => {
    if (e.key === "Enter") submit();
  };

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Make request to server
   * Route to the "Check your email" page
   */
  const submit = async () => {
    try {
      document.getElementById("form").classList.add("was-validated");
      let invalidInputs = inputs.filter((input) => input.invalid);
      invalidInputs.forEach((input) =>
        document
          .getElementById(input.id)
          .setCustomValidity(input.error || "Invalid")
      );
      if (!working && !invalidInputs.length) {
        setWorking(true);
        const data = Object.fromEntries(
          inputs.map((input) => [input.id, input.value])
        );
        try {
          forgot_password_schema.validateSync(data, {
            abortEarly: false,
          });
          const captchaKey = await getRecaptcha();
          const fd = new FormData();
          for (const key in data) {
            fd.append(key, data[key]);
          }
          fd.append("captchaKey", captchaKey);
          axios
            .post("/auth/forgot-password", fd)
            .then((res) => {
              if (res.data.success) history.push("/check-email");
              else {
                setWorking(false);
                alert(res.data.error);
              }
            })
            .catch((err) => {
              setWorking(false);
              console.log(err);
              alert("An error occurred. Please try again later.");
            });
        } catch (err) {
          setWorking(false);
          console.log(err);
          alert("An error occurred. Please try again later");
        }
      }
    } catch (err) {
      console.log("submit error", err);
      setWorking(false);
      alert("An error occurred. Please try again later.");
    }
  };

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  const changeHandler = (e) => {
    setInputs((curr) => {
      curr = curr.map((input) => {
        if (input.id === e.target.name)
          return {
            ...input,
            value: e.target.value,
          };
        else return input;
      });

      const data = Object.fromEntries(
        curr.map((input) => [input.id, input.value])
      );
      try {
        forgot_password_schema.validateSync(data, {
          abortEarly: false,
        });
        curr = curr.map((input) => {
          document.getElementById(input.id).setCustomValidity("");
          return {
            ...input,
            invalid: false,
            error: "",
          };
        });
      } catch (err) {
        let errorsAdded = [];
        curr = curr.map((input) => {
          if (
            err.inner.find((error) => error.path === input.id) &&
            errorsAdded.indexOf(input.id) === -1
          ) {
            errorsAdded.push(input.id);
            return {
              ...input,
              invalid: true,
              error: err.inner.find((error) => error.path === input.id).message,
            };
          } else
            return {
              ...input,
              invalid: false,
              error: "",
            };
        });
      }

      curr.forEach((input) => {
        if (input.invalid)
          document
            .getElementById(input.id)
            .setCustomValidity(input.error || "Invalid");
        else document.getElementById(input.id).setCustomValidity("");
      });

      return curr;
    });
  };

  const pressTab = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const input = inputs.find((f) => f.id === e.target.id);
      if (input) {
        const nextField = inputs[inputs.indexOf(input) + 1];
        if (nextField) {
          const element = document.getElementById(nextField.id);
          if (element) {
            setTimeout(() => {
              element.focus();
              element.select();
            }, 100);
          }
        }
      }
    }
  };

  h.floatLabels();

  return (
    <motion.div
      className="py-4"
      transition={t.transition}
      exit={history?.location?.state?.exit || t.fade_out_scale_1}
      animate={t.normalize}
      initial={history?.location?.state?.enter || t.fade_out}
    >
      <MDBContainer>
        <h1 className="display-4 text-center">Forgot Password</h1>
        <hr></hr>
        <div className="mx-auto mt-2 form-containers">
          <MDBValidation id="form" onSubmit={submit}>
            {fields.map((i) => (
              <MDBValidationItem
                key={i.id}
                className="pb-4"
                feedback={inputs.find((input) => input.id === i.id).error}
                invalid={true}
              >
                <MDBInput
                  name={i.id}
                  onChange={changeHandler}
                  id={i.id}
                  label={i.text}
                  size="lg"
                  className={
                    !inputs.find((input) => input.id === i.id).invalid
                      ? "mb-0"
                      : 0
                  }
                  onKeyPress={pressEnter}
                  onKeyDown={pressTab}
                />
              </MDBValidationItem>
            ))}
          </MDBValidation>
          {working ? (
            <MDBBtn color="primary" size="lg" className="w-100" block disabled>
              <Spinner size="sm" className="me-2" />
              Working
            </MDBBtn>
          ) : (
            <MDBBtn
              color="primary"
              onClick={submit}
              size="lg"
              block
              className="w-100"
            >
              <i className="fas fa-paper-plane me-2"></i>Submit
            </MDBBtn>
          )}
          <MDBBtn
            onClick={() =>
              history.push("/login", {
                exit: t.fade_out_right,
                enter: t.fade_out_left,
              })
            }
            color="dark"
            size="lg"
            className="w-100 my-4"
            block
          >
            <i className="fas fa-chevron-left me-2"></i>Back
          </MDBBtn>
        </div>
        <small className="mt-2 d-block mx-auto text-center">
          This site is protected by reCAPTCHA and the Google{" "}
          <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
          <a href="https://policies.google.com/terms"> Terms of Service</a>{" "}
          apply.
        </small>
      </MDBContainer>
    </motion.div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {})(ForgotPassword);
