import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { notify } from "../../../../../../redux/actions";
import { connect } from "react-redux";
import { StaticRouter, Switch, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import View from "./confirmDeleteModal/View";
import axios from "axios";

class ConfirmDeleteModal extends React.Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      working: false,
      view: "confirm",
      status: {
        current: 0,
        total: 0,
        deleting: {
          name: "",
          size: 0,
        },
      },
    };
  }

  componentDidMount() {
    this.setCustomValidity();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.files.length !== this.props.files.length)
      this.setState((curr) => ({
        ...curr,
        working: false,
        view: "confirm",
        username: "",
        password: "",
        status: {
          current: 0,
          total: this.props.files.length,
          deleting: {
            name: "",
            size: 0,
          },
        },
      }));
  }

  deleteFile = (file, index) =>
    new Promise((resolve, reject) =>
      this.setState(
        (curr) => ({
          ...curr,
          status: {
            ...curr.status,
            current: index + 1,
            deleting: {
              name: file.Key.split("/")[file.Key.split("/").length - 1],
              size: file.size,
            },
          },
        }),
        () =>
          axios
            .post("/jizzer/delete-file", {
              username: this.state.username,
              password: this.state.password,
              file: file.Key,
            })
            .then(resolve)
            .catch(reject)
      )
    );

  /**
   * Triggered when the user clicks the Confirm button
   * Makes request to server
   * Updates the server in application state
   * Hides the modal
   */
  confirm = () => {
    document.getElementById("delete-confirm").classList.add("was-validated");
    if (!this.state.working && this.state.username && this.state.password)
      this.setState(
        {
          ...this.state,
          working: true,
          view: "status",
        },
        async () => {
          try {
            for (let f = 0; f < this.props.files.length; f++) {
              const file = this.props.files[f];
              await this.deleteFile(file, f);
            }
            this.props.notify(
              <i className="fas fa-check-circle me-2 text-success" />,
              `${this.props.files.length} File${
                this.props.files.length === 1 ? "" : "s"
              } Deleted`
            );
            this.props.fileRemoveSuccess();
          } catch (err) {
            console.log("confirm error", err);
            this.setState((curr) => ({
              ...curr,
              username: "",
              password: "",
              working: false,
              view: "confirm",
              status: {
                current: 0,
                total: 0,
                deleting: {
                  name: "",
                  size: 0,
                },
              },
            }));
            alert("An error occurred. Please refresh the page and try again.");
          }
        }
      );
  };

  setCustomValidity = () => {
    if (this.state.view === "confirm") {
      ["username", "password"].forEach((field) => {
        document
          .getElementById(field)
          .setCustomValidity(
            !this.state[field] ? "Please enter your " + field : ""
          );
      });
    }
  };

  changeHandler = (e) =>
    this.setState(
      (curr) => ({
        ...curr,
        [e.target.name]: e.target.value,
      }),
      this.setCustomValidity
    );

  render() {
    const { username, password, working, status, view } = this.state;
    const { files } = this.props;
    const { confirm, setCustomValidity, changeHandler } = this;
    const props = {
      username,
      password,
      working,
      status,
      files,
      confirm,
      setCustomValidity,
      changeHandler,
      view,
    };
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            open={this.props.modalShown}
            staticBackdrop
            onClosePrevented={
              this.state.working ? () => {} : this.props.toggleShowModal
            }
            tabIndex="-1"
          >
            <MDBModalDialog size="xl">
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Are You Sure?</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={
                      this.state.working ? () => {} : this.props.toggleShowModal
                    }
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <StaticRouter location={this.state.view}>
                    <AnimatePresence exitBeforeEnter>
                      <Switch key={this.state.view}>
                        <Route exact path=":view">
                          <View {...props} />
                        </Route>
                      </Switch>
                    </AnimatePresence>
                  </StaticRouter>
                </MDBModalBody>
                {!this.state.working && (
                  <MDBModalFooter>
                    <MDBBtn color="danger" onClick={this.confirm}>
                      <i className="fas fa-check-circle me-2" />
                      Confirm
                    </MDBBtn>
                    <MDBBtn
                      className="bg-gray"
                      onClick={this.props.toggleShowModal}
                    >
                      Close
                    </MDBBtn>
                  </MDBModalFooter>
                )}
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { notify })(ConfirmDeleteModal);
