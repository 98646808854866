import React from "react";
import { connect } from "react-redux";
import { MDBContainer } from "mdb-react-ui-kit";
import LinearProgress from "@mui/material/LinearProgress";
import t from "../../../utilities/transitions";
import MessageNav from "./messages/MessageNav";
import { AnimatePresence } from "framer-motion";
import { StaticRouter, Switch, Route } from "react-router-dom";
import MessageTab from "./messages/MessageTab";

const tabs = ["general", "bug", "request", "report"];

class Messages extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * enter: Object - framer-motion entrance animation
       * exit: Object - framer-motion exit animation
       * tab: String - Category of messages that is displayed
       */
      enter: t.fade_out,
      exit: t.fade_out_scale_1,
      tab: "general",
    };
  }

  /**
   *
   * @param {String} tab - Category of messages
   *
   * Trigger when the user selects a new tab
   * Update entrance and exit animations as necessary
   * Change the tab
   */
  selectTab = (e, tab) =>
    this.setState(
      {
        ...this.state,
        exit:
          tabs.indexOf(tab) > tabs.indexOf(this.state.tab)
            ? t.fade_out_left
            : t.fade_out_right,
        enter:
          tabs.indexOf(tab) > tabs.indexOf(this.state.tab)
            ? t.fade_out_right
            : t.fade_out_left,
      },
      () =>
        this.setState({
          ...this.state,
          tab: tab,
        })
    );

  render() {
    return (
      <MDBContainer className="pt-2">
        {this.props.messageCounts ? (
          <>
            <div className="d-flex justify-content-end">
              <MessageNav tab={this.state.tab} selectTab={this.selectTab} />
            </div>
            <StaticRouter location={this.state.tab}>
              <AnimatePresence exitBeforeEnter>
                <Switch key={this.state.tab}>
                  <Route exact path=":tab">
                    <MessageTab
                      tab={this.state.tab}
                      enter={this.state.enter}
                      exit={this.state.exit}
                    />
                  </Route>
                </Switch>
              </AnimatePresence>
            </StaticRouter>
          </>
        ) : (
          <LinearProgress className="mt-5" />
        )}
      </MDBContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {})(Messages);
