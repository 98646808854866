import React from "react";
import Confirm from "./Confirm";
import Status from "./Status";

class View extends React.Component {
  constructor(props) {
    super();
    this.view = props.view;
  }

  render() {
    switch (this.view) {
      case "confirm":
        return <Confirm {...this.props} />;
      case "status":
        return <Status {...this.props} />;
      default:
        console.log("oob view", this.view);
        return <></>;
    }
  }
}

export default View;
