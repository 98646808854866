import React from "react";
import { connect } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const Nav = ({ view, nav, screenDimensions }) => (
  <div
    id="preferences-nav-container"
    className="d-flex align-items-center justify-content-between"
  >
    <Tabs
      value={view}
      onChange={(e, tab) => nav(tab)}
      orientation={screenDimensions.width < 675 ? "vertical" : "horizontal"}
      className="w-100"
    >
      <Tab
        style={{ minHeight: "48px" }}
        icon={
          <i
            style={{ color: "var(--mdb-success)" }}
            className="fas fa-globe-americas"
          ></i>
        }
        iconPosition="start"
        label="Name and Icons"
        value="logos"
        className={screenDimensions.width < 675 ? "min-w-100 d-block" : ""}
      />
      <Tab
        style={{ minHeight: "48px" }}
        icon={
          <i style={{ color: "#FFAB00" }} className="fas fa-quote-right"></i>
        }
        iconPosition="start"
        label="Verbiage"
        value="verbiage"
        className={screenDimensions.width < 675 ? "min-w-100 d-block" : ""}
      />
      <Tab
        style={{ minHeight: "48px" }}
        icon={
          <i
            style={{ color: "var(--mdb-danger)" }}
            className="fas fa-palette"
          ></i>
        }
        iconPosition="start"
        label="Appearance"
        value="colors"
        className={screenDimensions.width < 675 ? "min-w-100 d-block" : ""}
      />
      <Tab
        style={{ minHeight: "48px" }}
        icon={<i className="fas fa-cog text-purple"></i>}
        iconPosition="start"
        label="App Settings"
        value="settings"
        className={screenDimensions.width < 675 ? "min-w-100 d-block" : ""}
      />
    </Tabs>
    {screenDimensions.width < 675 ? <hr /> : <></>}
  </div>
);

const mapStateToProps = (state) => ({
  screenDimensions: state.screenDimensions,
});

export default connect(mapStateToProps, {})(Nav);
