import React from "react";
import { connect } from "react-redux";
import {
  MDBContainer,
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBRipple,
  MDBBtn,
  MDBToast,
} from "mdb-react-ui-kit";
import { edit_user_schema } from "../../../utilities/validations";
import h from "../../../utilities/helpers";
import BioField from "./settings/BioField";
import Spinner from "../../../components/Spinner";
import { change_user_details, notify } from "../../../redux/actions";
import axios from "axios";
import ChangePasswordModal from "./settings/ChangePasswordModal";
import { LinearProgress } from "@mui/material";

const fields = [
  {
    text: "Email Address",
    id: "email",
    type: "text",
  },
  {
    text: "Display Name",
    id: "displayName",
    type: "text",
  },
  {
    text: "Location (Optional)",
    id: "location",
    type: "text",
  },
  {
    text: "Website (Optional)",
    id: "website",
    type: "text",
  },
];

const allowedExtensions = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/bmp",
  "image/webp",
  "image/svg+xml",
];

class Settings extends React.Component {
  constructor(props) {
    super();
    this.state = {
      /**
       * avatar: String - Path to currently selected avatar
       * avatarName: String - Avatar filename if new file selected | "Click to Change"
       * avatarFile: false | File - Selected file to replace current avatar
       * avatarUpdating: Boolean - Whether the user's avatar is in the process of being updated
       * background: String - Path to currently selected background
       * backgroundName: String - Avatar filename if new file selected | "Click to Change"
       * backgroundFile: false | File - Selected file to replace current background
       * backgroundUpdating: Boolean - Whether the user's background is in the process of being updated
       * inputs: Array - The input data (values, errors, etc)
       * notificationIcon: JSX - Notification icon
       * notificationText: String - Notification text
       * changePasswordModalShown: Boolean - Whether the Change Password modal is shown
       */
      avatar: `${process.env.REACT_APP_BUCKET_HOST}/images/${props.userInfo.avatar.main}`,
      avatarName: "Click to Change",
      avatarFile: false,
      avatarUpdating: false,
      background: `${process.env.REACT_APP_BUCKET_HOST}/images/${props.userInfo.background.main}`,
      backgroundName: "Click to Change",
      backgroundFile: false,
      backgroundUpdating: false,
      inputs: fields.map((field) => ({
        id: field.id,
        error: "",
        invalid: false,
        value: props.userInfo[field.id],
      })),
      notificationIcon: <></>,
      notificationText: "",
      changePasswordModalShown: false,
    };
    /**
     * this.toastRef - When this.toastRef.current is clicked, a notification is shown
     * this.containerRef - Notifications are placed inside this container
     */
    this.toastRef = React.createRef(null);
    this.containerRef = React.createRef(null);
  }

  // Run empty change handlger
  componentDidMount() {
    this.changeHandler({
      target: {
        name: "",
      },
    });
  }

  componentDidUpdate() {
    h.floatLabels();
  }

  /**
   * Executes a captcha challenge and generates a key a key
   * Will hang until connected to captcha servers
   */
  getRecaptcha = () =>
    new Promise(async (resolve, reject) => {
      if (String(process.env.REACT_APP_DEV) === "true")
        return resolve(process.env.REACT_APP_DEV_CAPTCHA_KEY);
      if (this.props.captchaReady)
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
          .then(resolve)
          .catch((err) => {
            console.log(err);
            alert("Human verification failed. Refresh the page and try again.");
            reject();
          });
      else
        setTimeout(async () => {
          const captchaKey = await this.getRecaptcha();
          resolve(captchaKey);
        }, 500);
    });

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) => {
    this.setState(
      {
        ...this.state,
        inputs: this.state.inputs.map((input) => {
          if (input.id === e.target.name)
            return {
              ...input,
              value: e.target.value,
            };
          else return input;
        }),
      },
      () => {
        const data = Object.fromEntries(
          this.state.inputs.map((input) => [input.id, input.value])
        );
        try {
          edit_user_schema.validateSync(data, {
            abortEarly: false,
          });
          this.setState({
            ...this.state,
            inputs: this.state.inputs.map((input) => {
              document
                .getElementById(input.id + "-edit-profile")
                .setCustomValidity("");
              return {
                ...input,
                invalid: false,
                error: "",
              };
            }),
          });
        } catch (err) {
          let errorsAdded = [];
          if (err.inner) {
            this.setState(
              {
                ...this.state,
                inputs: this.state.inputs.map((input) => {
                  if (
                    err.inner.find((error) => error.path === input.id) &&
                    errorsAdded.indexOf(input.id) === -1
                  ) {
                    errorsAdded.push(input.id);
                    return {
                      ...input,
                      invalid: true,
                      error: err.inner.find((error) => error.path === input.id)
                        .message,
                    };
                  } else
                    return {
                      ...input,
                      invalid: false,
                      error: "",
                    };
                }),
              },
              () =>
                this.state.inputs.forEach((input) => {
                  if (input.invalid)
                    document
                      .getElementById(input.id + "-edit-profile")
                      .setCustomValidity(input.error || "Invalid");
                  else
                    document
                      .getElementById(input.id + "-edit-profile")
                      .setCustomValidity("");
                })
            );
          }
        }
      }
    );
  };

  /**
   * Fired when the user clicks their avatar or background
   *
   * Creates a virtual file input
   * Adds a change event that sets the selected file into state
   * Appends to document body (necessary for iDevices and possibly others)
   * Clicks the input
   * Removes the input after the file is selected
   */
  selectFile = (type) => {
    let input = document.createElement("input");
    input.type = "file";
    input.style.visibility = "hidden";
    input.style.position = "fixed";
    document.body.appendChild(input);
    input.onchange = (e) => {
      if (!this.state[type + "Updating"])
        this.setState(
          {
            ...this.state,
            [type + "Updating"]: true,
          },
          () => {
            let file = e.target.files[0];
            if (allowedExtensions.indexOf(file.type) !== -1) {
              if (
                file.size >
                Number(process.env.REACT_APP_MAX_INDIVIDUAL_FILE_SIZE)
              )
                alert(
                  `Max individual file size exceeded. (Max: ${Math.round(
                    Number(process.env.REACT_APP_MAX_INDIVIDUAL_FILE_SIZE) /
                      (1024 * 1024)
                  )}MB)`
                );
              else
                this.setState(
                  {
                    ...this.state,
                    [`${type}Name`]: e.target.files[0].name,
                    [`${type}File`]: e.target.files[0],
                    [type]: URL.createObjectURL(e.target.files[0]),
                  },
                  () => {
                    document.body.removeChild(input);
                    const fd = new FormData();
                    fd.append(
                      type,
                      this.state[type + "File"],
                      this.state[type + "Name"]
                    );
                    axios
                      .post("/dashboard/change-" + type, fd)
                      .then((res) =>
                        this.setState(
                          {
                            ...this.state,
                            [type + "Updating"]: false,
                            notificationIcon: (
                              <i className="fas fa-images me-2 text-success" />
                            ),
                            notificationText: (
                              <>
                                <span className="text-capitalize">
                                  {type} Updated
                                </span>
                              </>
                            ),
                          },
                          () => {
                            this.props.change_user_details(res.data.userInfo);
                            this.toastRef.current.click();
                          }
                        )
                      )
                      .catch((err) => {
                        console.log(err);
                        alert("An error occurred. Please try again later.");
                      });
                  }
                );
            } else {
              document.body.removeChild(input);
              alert(
                "Please select a valid image file (png, jpg, gif, bmp, webp)"
              );
            }
          }
        );
    };
    input.click();
  };

  /**
   *
   * @param {Event} e - Keypress event
   *
   * Triggered when the user presses the Tab key
   * Moves cursor to next input (MDB is bugged)
   * Removed when MDB fixes
   */
  pressTab = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const input = this.state.inputs.find((f) => f.id === e.target.name);
      if (input) {
        const nextField =
          this.state.inputs[this.state.inputs.indexOf(input) + 1];
        if (nextField) {
          const element = document.getElementById(
            nextField.id + "-edit-profile"
          );
          if (element) {
            setTimeout(() => {
              element.focus();
              element.select();
            }, 100);
          }
        }
      }
    }
  };

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Validate Captcha
   * Make request to server
   * Update user
   * Notify user
   */
  submit = () => {
    document.getElementById("form-edit-profile").classList.add("was-validated");
    this.forceParse();
    let invalidInputs = this.state.inputs.filter((input) => input.invalid);
    invalidInputs.forEach((input) =>
      document
        .getElementById(input.id + "-edit-profile")
        .setCustomValidity(input.error || "Invalid")
    );
    if (!this.state.working && !invalidInputs.length)
      this.setState(
        {
          ...this.state,
          working: true,
        },
        async () => {
          const data = Object.fromEntries(
            this.state.inputs.map((input) => [input.id, input.value])
          );
          try {
            edit_user_schema.validateSync(data, {
              abortEarly: false,
            });
            const captchaKey = await this.getRecaptcha();
            const fd = new FormData();
            for (const key in data) {
              fd.append(key, data[key]);
            }
            fd.append("captchaKey", captchaKey);
            const bio = document.getElementById("input-bio");
            const length = String(bio.textContent)
              .split("")
              .filter((c) => {
                const checkWhiteSpace = c.match(/[\s]/);
                if (!checkWhiteSpace) return true;
                else {
                  return [" ", "\n"].indexOf(c) > -1;
                }
              }).length;
            if (length > 1000)
              this.setState(
                {
                  ...this.state,
                  working: false,
                },
                () => alert("Your bio is too long (Max: 1000 chars)")
              );
            else {
              fd.append(
                "bio",
                bio.innerHTML.replace(/[\u200B-\u200D\uFEFF]/g, "")
              );
              axios
                .post("/profile/update", fd)
                .then((res) =>
                  this.setState(
                    {
                      ...this.state,
                      working: false,
                    },
                    () => {
                      document
                        .getElementById("form-edit-profile")
                        .classList.remove("was-validated");
                      this.props.change_user_details(res.data.userInfo);
                      this.props.notify(
                        <i className="fas fa-user me-2 text-success"></i>,
                        "User Info Updated"
                      );
                    }
                  )
                )
                .catch((err) =>
                  this.setState(
                    {
                      ...this.state,
                      working: false,
                    },
                    () => {
                      if (err.response && err.response.status === 400)
                        alert(err.response.data.message);
                      else {
                        console.log(err);
                        alert("An error occurred. Please try again later.");
                      }
                    }
                  )
                );
            }
          } catch (err) {
            this.setState(
              {
                ...this.state,
                working: false,
              },
              () => {
                console.log(err);
                alert("An error occurred. Please try again later");
              }
            );
          }
        }
      );
  };

  setChangePasswordModal = (option) =>
    this.setState({
      ...this.state,
      changePasswordModalShown: option,
    });

  toggleChangePasswordModal = () =>
    this.setState({
      ...this.state,
      notificationIcon: <i className="fas fa-key text-success me-2" />,
      notificationText: "Password Changed",
      changePasswordModalShown: !this.state.changePasswordModalShown,
    });

  render() {
    return (
      <div className="h-100 d-flex flex-column pt-5">
        <MDBContainer ref={this.containerRef} fluid className="fg-1 px-0">
          <ChangePasswordModal
            setShowModal={this.setChangePasswordModal}
            toggleShowModal={this.toggleChangePasswordModal}
            modalShown={this.state.changePasswordModalShown}
            toastRef={this.toastRef.current}
          />
          <MDBToast
            show={this.state.avatarUpdating}
            containerRef={this.containerRef}
            color="light"
            position="bottom-left"
            closeBtnClasses="d-none"
            headerContent={
              <>
                <strong className="me-auto">Updating Avatar</strong>
              </>
            }
            bodyContent={<LinearProgress />}
          />
          <MDBToast
            show={this.state.backgroundUpdating}
            containerRef={this.containerRef}
            color="light"
            position="bottom-left"
            closeBtnClasses="d-none"
            headerContent={
              <>
                <strong className="me-auto">Updating Background</strong>
              </>
            }
            bodyContent={<LinearProgress />}
          />
          <div className="row h-100 overflow-y-auto mx-0 dashboard-containers">
            <div className="col-12 col-lg-4 d-flex flex-column align-items-center">
              <MDBBtn
                onClick={this.toggleChangePasswordModal}
                className="bg-indigo"
                size="lg"
                block
              >
                <i className="fas fa-key me-2"></i>
                Change Password
              </MDBBtn>
              <p
                style={{ fontSize: "1.5rem" }}
                className="text-center display-6 mt-4"
              >
                Display Picture
              </p>
              <div className="d-flex justify-content-center">
                <div className="d-flex justify-content-center align-items-center square-15 mx-auto">
                  <MDBRipple
                    onClick={() => this.selectFile("avatar")}
                    tag="div"
                    rippleColor="primary"
                    className="fit-images fit-round"
                    style={{
                      backgroundImage: `url("${this.state.avatar}")`,
                      cursor: "pointer",
                      borderRadius: "50%",
                    }}
                  ></MDBRipple>
                </div>
              </div>
              <hr className="w-100" />
              <p
                style={{ fontSize: "1.5rem" }}
                className="text-center display-6 mt-4"
              >
                Background Picture
              </p>
              <div
                style={{ height: "250px" }}
                className="d-flex justify-content-center align-items-center w-100 mx-auto"
              >
                <MDBRipple
                  onClick={() => this.selectFile("background")}
                  tag="div"
                  rippleColor="light"
                  className="fit-background cursor-pointer"
                  style={{ backgroundImage: `url("${this.state.background}")` }}
                ></MDBRipple>
              </div>
            </div>
            <div className="col-12 col-lg-8">
              <MDBContainer fluid className="px-0 mx-0">
                <MDBValidation
                  method="dialog"
                  id="form-edit-profile"
                  name="form-edit-profile"
                  className="row"
                  onSubmit={this.props.submit}
                >
                  {fields.map((i) => (
                    <MDBValidationItem
                      key={i.id + "-edit-profile"}
                      className="pb-4 col-12 col-lg-6 mt-2"
                      feedback={
                        this.state.inputs.find((input) => input.id === i.id)
                          .error
                      }
                      invalid={true}
                    >
                      <MDBInput
                        name={i.id}
                        onChange={this.changeHandler}
                        id={i.id + "-edit-profile"}
                        label={i.text}
                        size="lg"
                        className={
                          !this.state.inputs.find((input) => input.id === i.id)
                            .invalid
                            ? "mb-0"
                            : 0
                        }
                        defaultValue={
                          this.state.inputs.find((input) => input.id === i.id)
                            .value
                        }
                        onKeyDown={this.pressTab}
                      />
                    </MDBValidationItem>
                  ))}
                  <MDBValidationItem className="col-12 col-lg-6 mt-2">
                    {this.props.dashboardItemSelected === "settings" ? (
                      <BioField
                        userInfo={this.props.userInfo}
                        setForceParse={(f) => (this.forceParse = f)}
                        working={this.props.working}
                        maxChars={1000}
                      />
                    ) : (
                      <></>
                    )}
                  </MDBValidationItem>
                  <div className="col-12 col-lg-6 mt-2 d-flex justify-content-end align-items-end">
                    {this.state.working ? (
                      <MDBBtn
                        className="dashboard-setting-buttons"
                        size="lg"
                        disabled
                        color="success"
                      >
                        <Spinner className="me-2" size="sm" />
                        Saving
                      </MDBBtn>
                    ) : (
                      <MDBBtn
                        className="dashboard-setting-buttons"
                        size="lg"
                        onClick={this.submit}
                        color="success"
                      >
                        <i className="fas fa-save me-2" />
                        Save
                      </MDBBtn>
                    )}
                  </div>
                </MDBValidation>
                <small className="mt-2 d-block mx-auto text-center">
                  This site is protected by reCAPTCHA and the Google{" "}
                  <a href="https://policies.google.com/privacy">
                    {" "}
                    Privacy Policy
                  </a>{" "}
                  and
                  <a href="https://policies.google.com/terms">
                    {" "}
                    Terms of Service
                  </a>{" "}
                  apply.
                </small>
              </MDBContainer>
            </div>
          </div>
        </MDBContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  change_user_details,
  notify,
})(Settings);
