import React from "react";
import List from "./List";
// import SelectPlanJizzer from "./jizzer/SelectPlanJizzer";
import JizzerTerms from "./jizzer/JizzerTerms";
import JizzerPayment from "./jizzer/JizzerPayment";

class ProductView extends React.Component {
  constructor(props) {
    super();
    this.page = props.page;
  }

  render() {
    switch (this.page) {
      case "list":
        return (
          <List
            changePage={this.props.changePage}
            productHistoryStack={this.props.productHistoryStack}
            toggleLoginModal={this.props.toggleLoginModal}
            enter={this.props.enter}
            exit={this.props.exit}
            resetAnimations={this.props.resetAnimations}
          />
        );
      case "jizzer-terms":
        return (
          <JizzerTerms
            changePage={this.props.changePage}
            productHistoryStack={this.props.productHistoryStack}
            enter={this.props.enter}
            exit={this.props.exit}
            resetAnimations={this.props.resetAnimations}
          />
        );
      case "jizzer-payment":
        return (
          <JizzerPayment
            changePage={this.props.changePage}
            productHistoryStack={this.props.productHistoryStack}
            enter={this.props.enter}
            exit={this.props.exit}
            resetAnimations={this.props.resetAnimations}
          />
        );
      default:
        console.log("oob product page", this.page);
        return <></>;
    }
  }
}

export default ProductView;
