import React from "react";
import { motion } from "framer-motion";
import t from "../utilities/transitions";
import { useHistory, useLocation } from "react-router-dom";

/**
 * This is a generic page that the user comes to for various messages
 * * After submitting a contact form
 * * After submitting a password reset request
 * * 404 Page
 */

const Message = ({ message }) => {
  const history = useHistory();
  const location = useLocation();
  return (
    <motion.div
      className="container"
      transition={t.transition}
      exit={history?.location?.state?.exit || t.fade_out_scale_1}
      animate={t.normalize}
      initial={history?.location?.state?.enter || t.fade_out}
    >
      <h1 className="display-5 mt-5 text-center">{message}</h1>
    </motion.div>
  );
};

export default Message;
