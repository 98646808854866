import React from "react";
import { connect } from "react-redux";
import { MDBNavbarItem, MDBNavbarLink, MDBRipple } from "mdb-react-ui-kit";
import { Link, useHistory, useLocation } from "react-router-dom";

/**
 *
 * @param {Function} nav - The navigation function
 * @param {Object} history - The react-router history object
 * @returns Menu items that are present on all screens
 */
const MenuItemsBoth = ({ collapseNav }) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <>
      <MDBRipple rippleColor="dark">
        <Link
          to="/products"
          onClick={() => {
            collapseNav();
          }}
        >
          <MDBNavbarItem className="nav-item nav-items-collapse rounded">
            <MDBNavbarLink
              id="nav-products"
              className={`nav-link text-default rounded-4 nav-main-items ${
                "products" === history.location.pathname.split("/")[1]
                  ? "active"
                  : ""
              }`}
            >
              Products
            </MDBNavbarLink>
          </MDBNavbarItem>
        </Link>
      </MDBRipple>
      <MDBRipple rippleColor="dark">
        <Link to="/instances" onClick={collapseNav}>
          <MDBNavbarItem className="nav-item nav-items-collapse rounded">
            <MDBNavbarLink
              id="nav-instances"
              className={`nav-link text-default rounded-4 nav-main-items ${
                "instances" === history.location.pathname.split("/")[1]
                  ? "active"
                  : ""
              }`}
            >
              Instances
            </MDBNavbarLink>
          </MDBNavbarItem>
        </Link>
      </MDBRipple>
      <MDBRipple rippleColor="dark">
        <Link to="/feedback" onClick={collapseNav}>
          <MDBNavbarItem className="nav-item nav-items-collapse rounded">
            <MDBNavbarLink
              id="nav-feedback"
              className={`nav-link text-default rounded-4 nav-main-items ${
                "feedback" === history.location.pathname.split("/")[1]
                  ? "active"
                  : ""
              }`}
            >
              Feedback
            </MDBNavbarLink>
          </MDBNavbarItem>
        </Link>
      </MDBRipple>
      <MDBRipple rippleColor="dark">
        <Link to="/info" onClick={collapseNav}>
          <MDBNavbarItem className="nav-item nav-items-collapse rounded">
            <MDBNavbarLink
              id="nav-info"
              className={`nav-link text-default rounded-4 nav-main-items ${
                "info" === history.location.pathname.split("/")[1]
                  ? "active"
                  : ""
              }`}
            >
              Info
            </MDBNavbarLink>
          </MDBNavbarItem>
        </Link>
      </MDBRipple>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {})(MenuItemsBoth);
