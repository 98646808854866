import React from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import t from "../../../../../../utilities/transitions";
import { LinearProgress } from "@mui/material";
import InstanceSize from "./charts/InstanceSize";
import Tokens from "./charts/Tokens";
import Streaming from "./charts/Streaming";

const Status = ({ statistics }) => {
  if (!statistics)
    return (
      <motion.h5
        transition={t.transition}
        initial={t.fade_out}
        animate={t.normalize}
        exit={t.fade_out_scale_1}
        className="display-6 text-center"
      >
        No statistics found for this instance
      </motion.h5>
    );

  if (statistics?.loading)
    return (
      <motion.div
        transition={t.transition}
        initial={t.fade_out}
        animate={t.normalize}
        exit={t.fade_out_scale_1}
        className="mt-4"
      >
        <h5 className="display-6 text-center">Loading Statistics</h5>
        <LinearProgress className="mt-4 w-75 mx-auto" />
      </motion.div>
    );

  return (
    <motion.div
      transition={t.transition}
      initial={t.fade_out}
      animate={t.normalize}
      exit={t.fade_out_scale_1}
    >
      <InstanceSize stats={statistics.instanceSize} />
      <hr />
      <Tokens stats={statistics.tokens} />
      <hr />
      <Streaming stats={statistics.streamLogs} />
    </motion.div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

export default connect(mapStateToProps, {})(Status);
