import React from "react";
import { motion } from "framer-motion";
import h from "../../../../utilities/helpers";
import t from "../../../../utilities/transitions";
import { connect } from "react-redux";
import {
  MDBListGroup,
  MDBListGroupItem,
  MDBRipple,
  MDBBtn,
} from "mdb-react-ui-kit";
import axios from "axios";
import { load_more_messages } from "../../../../redux/actions";
import Spinner from "../../../../components/Spinner";
import MessageModal from "./MessageModal";

class Requests extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * loadingMore: Boolean - Whether the user is in the process of loading older bug reports
       * messageModalShown: Boolean - Whether the Message modal is displayed
       * messageSelected: false | Object - The message selected
       */
      loadingMore: false,
      messageModalShown: false,
      messageSelected: false,
    };
  }

  toggleMessageModal = () =>
    this.setState({
      ...this.state,
      messageModalShown: !this.state.messageModalShown,
    });

  setMessageModal = (option) =>
    this.setState({
      ...this.state,
      messageModalShown: option,
    });

  /**
   * Triggered when the user clicks the See More button at the bottom of the page
   * Loads older bug reports
   */
  loadMore = () => {
    if (!this.state.loadingMore)
      this.setState(
        {
          ...this.state,
          loadingMore: true,
        },
        () =>
          axios
            .get("/api/dashboard/load-more", {
              category: "request",
              messageIDs: this.props.contactFormMessages.map((m) => m._id),
            })
            .then((res) => this.props.load_more_messages(res.data))
            .catch((err) => {
              console.log("err", err);
              alert("An error occurred. Please try again later.");
            })
            .finally(() =>
              this.setState({
                ...this.state,
                loadingMore: false,
              })
            )
      );
  };

  /**
   *
   * @param {Object} message - Messages document
   *
   * Triggered when the user clicks on one of the messages
   * Sets that message into state and displays the Message modal
   */
  selectMessage = (message) =>
    this.setState(
      {
        ...this.state,
        messageSelected: message,
      },
      this.toggleMessageModal
    );

  render() {
    const messages = this.props.contactFormMessages
      .filter(
        (m) => m.instanceID === "carbon-valley" && m.category === "request"
      )
      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    return (
      <motion.div
        className="mt-2"
        transition={t.transition}
        initial={this.props.history?.location?.state?.enter || this.props.enter}
        animate={t.normalize}
        exit={this.props.history?.location?.state?.exit || this.props.exit}
      >
        {messages.length ? (
          <>
            <MessageModal
              modalShown={this.state.messageModalShown}
              setShowModal={this.setMessageModal}
              toggleShowModal={this.toggleMessageModal}
              message={this.state.messageSelected}
              category="Request"
            />
            <MDBListGroup>
              {messages.map((message) => (
                <MDBRipple
                  rippleColor="primary"
                  key={message._id}
                  className="cursor-pointer"
                  onClick={() => this.selectMessage(message)}
                >
                  <MDBListGroupItem action>
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="mb-1">
                          <span className="fw-bold">Name:</span> {message.name}
                        </p>
                        <p className="mb-1">
                          <span className="fw-bold">Email:</span>{" "}
                          {message.email}
                        </p>
                        <p className="mb-1">
                          <span className="fw-bold">Subject:</span>{" "}
                          {message.subject}
                        </p>
                      </div>
                      <div>
                        <p className="m-0 text-blusteel text-end">
                          {h.makeDateHR(message.timestamp)}
                        </p>
                        <p className="m-0 text-blusteel text-end">
                          {h.getTimeHR(message.timestamp)}
                        </p>
                      </div>
                    </div>
                    <p>
                      <span className="fw-bold">Message:</span>{" "}
                      {h.shortString(message.feedback)}
                    </p>
                  </MDBListGroupItem>
                </MDBRipple>
              ))}
            </MDBListGroup>
          </>
        ) : (
          <h5 className="text-center mt-4">There are no requests</h5>
        )}
        {messages.length < this.props.messageCounts.request ? (
          <>
            {this.state.loadingMore ? (
              <MDBBtn
                size="lg"
                disabled
                className="d-block mx-auto w-50 mt-2"
                rippleColor="primary"
                outline
                color="primary"
                rounded
              >
                <Spinner color="primary" size="sm" className="me-2" />
                Please Wait
              </MDBBtn>
            ) : (
              <MDBBtn
                size="lg"
                onClick={this.loadMore}
                className="d-block mx-auto w-50 mt-2"
                rippleColor="primary"
                outline
                color="primary"
                rounded
              >
                See More
              </MDBBtn>
            )}
          </>
        ) : (
          <></>
        )}
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { load_more_messages })(Requests);
