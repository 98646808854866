import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBValidation,
  MDBValidationItem,
  MDBInput,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import BioField from "../../settings/BioField";
import h from "../../../../../utilities/helpers";

class RuleModal extends React.Component {
  constructor(props) {
    super();
    this.state = {
      submitted: false,
      title: {
        value: "",
        error: "Please enter a title",
      },
      valueLength: props.rules.value.length,
      refresh: false,
    };
  }

  componentDidMount() {
    document
      .getElementById("new-rule-input")
      .setCustomValidity(this.state.title.error);
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.rules.value.length !== this.props.rules.value.length) This doesn't work. WTF
    if (this.props.rules.value.length !== this.state.valueLength)
      this.setState(
        (curr) => ({
          ...curr,
          valueLength: this.props.rules.value.length,
          submitted: false,
          title: {
            value: "",
            error: "Please enter a title",
          },
        }),
        this.props.toggleShowModal
      );
    if (!prevProps.ruleSelected && this.props.ruleSelected) {
      const ruleSelected = this.props.rules.value.find(
        (v) => v.id === this.props.ruleSelected
      );
      this.setState((curr) => ({
        ...curr,
        title: {
          value: ruleSelected.title,
          error: "",
        },
        refresh: !this.state.refresh,
      }));
    }
  }

  changeHandler = (e) => {
    this.setState(
      (curr) => ({
        ...curr,
        title: {
          ...curr.title,
          value: e.target.value,
          error: e.target.value
            ? e.target.value.length > 250
              ? "Rule titles must not exceed 250 characters"
              : ""
            : "Please enter a title",
        },
      }),
      () =>
        document
          .getElementById("new-rule-input")
          .setCustomValidity(this.state.title.error)
    );
  };

  submit = () => {
    this.setState(
      (curr) => ({
        ...curr,
        submitted: true,
      }),
      () => {
        if (!this.state.title.error) {
          if (this.forceParse) this.forceParse();
          let rule = document.getElementById("input-new-rule");
          const length = String(rule.textContent)
            .split("")
            .filter((c) => {
              const checkWhiteSpace = c.match(/[\s]/);
              if (!checkWhiteSpace) return true;
              else {
                return [" ", "\n"].indexOf(c) > -1;
              }
            }).length;
          if (length > 2500)
            return alert("Rule text must not exceed 2500 characters");
          rule = rule.innerHTML;
          rule = h.sanitizeHTML(rule);
          this.props.saveRule({
            title: this.state.title.value,
            html: rule,
          });
        }
      }
    );
  };

  render() {
    let ruleSelected;
    if (this.props.ruleSelected)
      ruleSelected =
        this.props.rules.value.find((v) => v.id === this.props.ruleSelected)
          ?.html || "";
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            open={this.props.modalShown}
            staticBackdrop
            onClosePrevented={this.props.toggleShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog
              size={
                this.props.screenDimensions.width >
                this.props.screenDimensions.modalBreak
                  ? "xl"
                  : "fullscreen"
              }
            >
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>New Rule</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={this.props.toggleShowModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <MDBValidation
                    className={`mb-${
                      this.state.submitted ? "4 was-validated" : "3"
                    }`}
                  >
                    <MDBValidationItem
                      feedback={this.state.title.error}
                      invalid={true}
                    >
                      <MDBInput
                        id="new-rule-input"
                        name="new-rule-input"
                        onChange={this.changeHandler}
                        className={!this.state.title.error ? "mb-0" : 0}
                        value={this.state.title.value}
                        label="New Rule"
                      />
                    </MDBValidationItem>
                  </MDBValidation>
                  <BioField
                    setForceParse={(f) => (this.forceParse = f)}
                    flavor="new-rule"
                    key={
                      String(this.props.rules.value.length) +
                      String(this.state.refresh)
                    }
                    maxChars={2500}
                    noLabel={true}
                    userInfo={
                      this.props.ruleSelected ? { bio: ruleSelected } : false
                    }
                  />
                </MDBModalBody>
                {this.props.ruleSelected ? (
                  <MDBModalFooter className="justify-content-between">
                    <MDBBtn color="danger" onClick={this.props.removeRule}>
                      <i className="fas fa-times-circle me-2" />
                      Remove Rule
                    </MDBBtn>
                    <div className="d-flex align-items-center">
                      <MDBBtn color="success" onClick={this.submit}>
                        <i className="fas fa-paper-plane me-2" />
                        Save
                      </MDBBtn>
                      <MDBBtn
                        className="bg-gray ms-2"
                        onClick={this.props.toggleShowModal}
                      >
                        Close
                      </MDBBtn>
                    </div>
                  </MDBModalFooter>
                ) : (
                  <MDBModalFooter>
                    <MDBBtn color="success" onClick={this.submit}>
                      <i className="fas fa-paper-plane me-2" />
                      Save
                    </MDBBtn>
                    <MDBBtn
                      className="bg-gray"
                      onClick={this.props.toggleShowModal}
                    >
                      Close
                    </MDBBtn>
                  </MDBModalFooter>
                )}
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {})(RuleModal);
