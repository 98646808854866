import React from "react";
import { motion } from "framer-motion";
import t from "../../../../../../../utilities/transitions";
import { MDBInput, MDBValidation, MDBValidationItem } from "mdb-react-ui-kit";

const Confirm = ({ changeHandler, confirm, files, password, username }) => {
  return (
    <motion.div
      transition={t.transition}
      initial={t.fade_out}
      animate={t.normalize}
      exit={t.fade_out_scale_1}
    >
      <h5 className="text-center display-6 my-4">
        You are about to permanently delete {files.length} file
        {files.length === 1 ? "" : "s"}
      </h5>
      <p className="text-center">Are you sure you want to do this?</p>
      <div className="form-containers">
        <MDBValidation
          name="delete-confirm"
          method="dialog"
          id="delete-confirm"
          onSubmit={confirm}
        >
          <MDBValidationItem
            className="pb-4"
            feedback={username ? "" : "Please enter your username"}
            invalid={true}
          >
            <MDBInput
              name="username"
              onChange={changeHandler}
              id="username"
              label="Username or Email"
              size="lg"
              className={username ? "mb-0" : 0}
              value={username}
            />
          </MDBValidationItem>
          <MDBValidationItem
            className="pb-4"
            feedback={password ? "" : "Please enter your password"}
            invalid={true}
          >
            <MDBInput
              name="password"
              onChange={changeHandler}
              id="password"
              label="Password"
              size="lg"
              className={password ? "mb-0" : 0}
              type="password"
              value={password}
            />
          </MDBValidationItem>
        </MDBValidation>
      </div>
    </motion.div>
  );
};

export default Confirm;
