import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import h from "../../../../../../utilities/helpers";

class MessageModal extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    const message = this.props.message;
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            open={this.props.modalShown}
            staticBackdrop
            onClosePrevented={this.props.toggleShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog size="xl">
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>
                    <h5 className="m-0">{message?.name}</h5>
                  </MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={this.props.toggleShowModal}
                  ></MDBBtn>
                </MDBModalHeader>
                {message && (
                  <MDBModalBody>
                    <div className="d-flex justify-content-between align-items-center">
                      <p>
                        <span className="fw-bold">Email:</span> {message.email}
                      </p>
                      <p className="text-blusteel">
                        {h.getMessageTime(message.timestamp)}
                      </p>
                    </div>

                    <p>
                      <span className="fw-bold">Subject:</span>{" "}
                      {message.subject}
                    </p>
                    <p className="fw-bold">Message</p>
                    <p>{message.message}</p>
                  </MDBModalBody>
                )}
                <MDBModalFooter>
                  <MDBBtn
                    className="bg-gray"
                    onClick={this.props.toggleShowModal}
                  >
                    Close
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default MessageModal;
