import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBValidation,
  MDBValidationItem,
  MDBTextArea,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import Spinner from "../../../../../components/Spinner";
import axios from "axios";
import { update_dashboard_data } from "../../../../../redux/actions";
import { disable_schema } from "../../../../../utilities/validations";

class DisableModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether a disable request is currently being processed
       * inputs: Object - The input data (value, error, etc)
       */
      working: false,
      input: {
        error: "Please enter an explanation",
        invalid: true,
        value: "",
      },
    };
  }

  // Set explanation field custom validity
  componentDidUpdate(prevProps) {
    if (!prevProps.instance && this.props.instance)
      document
        .getElementById("explanation-text")
        .setCustomValidity(this.state.input.error);
  }

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) =>
    this.setState(
      {
        ...this.state,
        input: {
          ...this.state.input,
          value: e.target.value,
        },
      },
      () => {
        try {
          const data = {
            explanation: this.state.input.value,
          };
          disable_schema.validateSync(data, {
            abortEarly: false,
          });
          document.getElementById("explanation-text").setCustomValidity("");
          this.setState({
            ...this.state,
            input: {
              ...this.state.input,
              invalid: false,
              error: "",
            },
          });
        } catch (err) {
          this.setState(
            {
              ...this.state,
              input: {
                ...this.state.input,
                invalid: true,
                error: err.inner.find((error) => error.path === "explanation")
                  .message,
              },
            },
            () =>
              document
                .getElementById("explanation-text")
                .setCustomValidity(this.state.input.error)
          );
        }
      }
    );

  /**
   * Submit only if there isn't already a submission being sent
   * Validate inputs
   * Make request to server
   * Update dashboard data
   * Hide modal
   */
  confirm = () => {
    document.getElementById("disable-form").classList.add("was-validated");
    if (!this.state.working && !this.state.input.invalid)
      this.setState(
        {
          ...this.state,
          working: true,
        },
        () =>
          axios
            .get("/jizzer/disable-chadmin", {
              ...this.props.instance,
              explanation: this.state.input.value,
            })
            .then((res) => {
              this.props.update_dashboard_data(res.data);
              this.setState(
                {
                  ...this.state,
                  working: false,
                },
                this.props.toggleShowModal
              );
            })
            .catch((err) =>
              this.setState(
                {
                  ...this.state,
                  working: false,
                },
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later.");
                }
              )
            )
      );
  };

  render() {
    const instance = this.props.instance;
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            open={this.props.modalShown}
            staticBackdrop
            onClosePrevented={this.props.toggleShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog
              size={
                this.props.screenDimensions.width >
                this.props.screenDimensions.modalBreak
                  ? "xl"
                  : "fullscreen"
              }
            >
              {instance ? (
                <MDBModalContent>
                  <MDBModalHeader>
                    <MDBModalTitle className="d-flex align-items-center">
                      <div className="square-2 me-2">
                        <div
                          className="fit-images fit-round"
                          style={{
                            backgroundImage: `url("${process.env.REACT_APP_JIZZER_BUCKET_HOST}/${instance.uuid}/icons/favicon-96x96.png")`,
                          }}
                        ></div>
                      </div>
                      Are you sure?
                    </MDBModalTitle>
                    <MDBBtn
                      className="btn-close"
                      color="none"
                      onClick={this.props.toggleShowModal}
                    ></MDBBtn>
                  </MDBModalHeader>
                  <MDBModalBody>
                    <h5 className="text-center">
                      You are about to disable {instance.preferences.app_name}{" "}
                      located at{" "}
                      <a
                        className="links-generic"
                        href={"https://" + instance.server.domain}
                      >
                        {instance.server.domain}
                      </a>
                      .
                    </h5>
                    <MDBValidation
                      name="disable-form"
                      method="dialog"
                      id="disable-form"
                      onSubmit={this.confirm}
                    >
                      <MDBValidationItem
                        className="pb-4"
                        feedback={this.state.input.error}
                        invalid={true}
                      >
                        <MDBTextArea
                          name="explanation"
                          onChange={this.changeHandler}
                          id="explanation-text"
                          label="Enter an Explanation"
                          size="lg"
                          className={!this.state.input.invalid ? "mb-0" : 0}
                        />
                      </MDBValidationItem>
                    </MDBValidation>
                  </MDBModalBody>
                  <MDBModalFooter>
                    {this.state.working ? (
                      <MDBBtn disabled color="danger">
                        <Spinner className="me-2" size="sm" />
                        Disabling
                      </MDBBtn>
                    ) : (
                      <MDBBtn color="danger" onClick={this.confirm}>
                        <i className="fas fa-times-circle me-2" />
                        Confirm
                      </MDBBtn>
                    )}
                    <MDBBtn
                      className="bg-gray"
                      onClick={this.props.toggleShowModal}
                    >
                      Close
                    </MDBBtn>
                  </MDBModalFooter>
                </MDBModalContent>
              ) : (
                <></>
              )}
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { update_dashboard_data })(
  DisableModal
);
