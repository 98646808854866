import React from "react";
import { motion } from "framer-motion";
import t from "../utilities/transitions";
import {
  MDBContainer,
  MDBBtn,
  MDBCarousel,
  MDBCarouselItem,
  MDBCarouselCaption,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";

const Home = ({}) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <motion.div
      className="flex-grow-1 h-0 position-relative"
      transition={t.transition}
      exit={history?.location?.state?.exit || t.fade_out_scale_1}
      animate={t.normalize}
      initial={history?.location?.state?.enter || t.fade_out}
    >
      <MDBContainer id="home-container" fluid className="py-5 h-100 px-5">
        <div className="row h-75 align-items-center">
          <div className="col-12 col-lg-6 mt-4">
            <div>
              <h5 className="display-6">
                Your Social Media Empire Starts Here
              </h5>
              <h5 className="mt-4">
                Launch a fully functioning social media app without writing a
                single line of code.
              </h5>
              <p className="mt-2">
                Simply register a website, point it at our servers, and we'll
                handle the rest.
              </p>
            </div>
            <div className="mt-4 ms-auto">
              <Link
                to={{
                  pathname: "/products",
                  state: {
                    exit: t.fade_out_left,
                    enter: t.fade_out_right,
                  },
                }}
              >
                <MDBBtn size="lg" color="primary">
                  Get Started
                  <i className="fas fa-chevron-right ms-2" />
                </MDBBtn>
              </Link>
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-4">
            <MDBCarousel showIndicators>
              <MDBCarouselItem className="rounded-3" itemId={1}>
                <img
                  className="w-100 d-block"
                  src="/assets/images/step1.png"
                  alt="Step 1"
                />
                <MDBCarouselCaption className="mask bg-mask-regular">
                  <h5>Step 1</h5>
                  <p>Register an account and select a domain or use your own</p>
                </MDBCarouselCaption>
              </MDBCarouselItem>

              <MDBCarouselItem className="rounded-3" itemId={2}>
                <img
                  className="w-100 d-block"
                  src="/assets/images/step2.png"
                  alt="Step 2"
                />
                <MDBCarouselCaption className="mask bg-mask-regular">
                  <h5>Step 2</h5>
                  <p>
                    Upload your logos, change the verbiage, choose your colors,
                    or leave everything default.
                  </p>
                </MDBCarouselCaption>
              </MDBCarouselItem>

              <MDBCarouselItem
                className="rounded-3"
                itemId={3}
                src="/assets/images/step3.png"
              >
                <img
                  className="w-100 d-block"
                  src="/assets/images/step3.png"
                  alt="Step 3"
                />
                <MDBCarouselCaption className="mask bg-mask-regular">
                  <h5>Step 3</h5>
                  <p>Run your instance however you want.</p>
                </MDBCarouselCaption>
              </MDBCarouselItem>
            </MDBCarousel>
          </div>
        </div>
      </MDBContainer>
    </motion.div>
  );
};

export default Home;
