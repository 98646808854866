import React from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import t from "../../../../../utilities/transitions";
import h from "../../../../../utilities/helpers";
import {
  MDBContainer,
  MDBBtn,
  MDBListGroup,
  MDBListGroupItem,
  MDBRipple,
} from "mdb-react-ui-kit";
import { parse } from "node-html-parser";
import ViewNoteModal from "./ViewNoteModal";
import NewNoteModal from "./NewNoteModal";

class ReleaseNotes extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * noteSelected: false | ReleaseNotes document
       * viewNoteModalShown: Boolean - Whether the Note Details modal is shown
       * newNoteModalShown: Boolean - Whether the New Note modal is shown
       */
      noteSelected: false,
      viewNoteModalShown: false,
      newNoteModalShown: false,
    };
  }

  toggleViewNoteModal = () =>
    this.setState({
      ...this.state,
      viewNoteModalShown: !this.state.viewNoteModalShown,
    });

  setViewNoteModal = (option) =>
    this.setState({
      ...this.state,
      viewNoteModalShown: option,
    });

  toggleNewNoteModal = () =>
    this.setState({
      ...this.state,
      newNoteModalShown: !this.state.newNoteModalShown,
    });

  setNewNoteModal = (option) =>
    this.setState({
      ...this.state,
      newNoteModalShown: option,
    });

  selectNote = (note) =>
    this.setState(
      {
        ...this.state,
        noteSelected: note,
      },
      this.toggleViewNoteModal
    );

  render() {
    if (typeof document !== "undefined") {
      return (
        <motion.div
          className="fg-1 dashboard-containers"
          transition={t.transition}
          initial={this.props.enter}
          animate={t.normalize}
          exit={this.props.exit}
        >
          <ViewNoteModal
            setShowModal={this.setViewNoteModal}
            toggleShowModal={this.toggleViewNoteModal}
            note={this.state.noteSelected}
            modalShown={this.state.viewNoteModalShown}
          />
          <NewNoteModal
            setShowModal={this.setNewNoteModal}
            toggleShowModal={this.toggleNewNoteModal}
            modalShown={this.state.newNoteModalShown}
          />
          <MDBContainer fluid>
            <MDBBtn
              className="d-block ms-auto my-3"
              color="success"
              onClick={this.toggleNewNoteModal}
            >
              <i className="fas fa-plus me-2" />
              New Note
            </MDBBtn>
            <div className="row mx-0">
              <MDBListGroup>
                {this.props.releaseNotes
                  .filter((note) => note.for === "jizzer-beta")
                  .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                  .map((note) => (
                    <MDBRipple
                      className="cursor-pointer"
                      onClick={() => this.selectNote(note)}
                      rippleColor="primary"
                    >
                      <MDBListGroupItem action>
                        <p className="text-blusteel m-0">
                          {h.makeDateHR(new Date(note.timestamp))}
                        </p>
                        <p className="text-blusteel">
                          {h.getTimeHR(new Date(note.timestamp))}
                        </p>
                        <p className="m-0">
                          {h.shortString(parse(note.html).textContent)}
                        </p>
                      </MDBListGroupItem>
                    </MDBRipple>
                  ))}
              </MDBListGroup>
            </div>
          </MDBContainer>
        </motion.div>
      );
    } else return <></>;
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {})(ReleaseNotes);
