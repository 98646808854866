import React from "react";
import { connect } from "react-redux";
import { MDBContainer } from "mdb-react-ui-kit";
import { StaticRouter, Switch, Route } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import { AnimatePresence } from "framer-motion";
import InstanceView from "./instances_chadmin/InstanceView";
import { select_instance } from "../../../redux/actions";

class Instances_Chadmin extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * loaded: Boolean - Whether the initial data load has completed (placeholder/to remove)
       * view: String - The current view
       */
      loaded: false,
      view: "list",
    };
  }

  componentDidMount() {
    this.props.select_instance(false);
    this.setState({
      ...this.state,
      loaded: true,
    });
  }

  /**
   *
   * @param {String} view - A view or page
   *
   * Sets the view
   */
  setView = (view) =>
    this.setState({
      ...this.state,
      view: view,
    });

  render() {
    return (
      <div className="h-100 d-flex flex-column">
        {this.props.userList ? (
          <MDBContainer fluid className="fg-1 px-0 dasboard-containers">
            {this.props.userList.filter((u) => u.instances.length).length ? (
              <div className="row h-100 overflow-y-auto mx-0 overflow-x-hidden dashboard-containers">
                <StaticRouter location={this.state.view}>
                  <AnimatePresence exitBeforeEnter>
                    <Switch key={this.state.view}>
                      <Route exact path=":view">
                        <InstanceView
                          setView={this.setView}
                          view={this.state.view}
                          loaded={this.state.loaded}
                        />
                      </Route>
                    </Switch>
                  </AnimatePresence>
                </StaticRouter>
              </div>
            ) : (
              <h5 className="text-center display-6 mt-5">
                There are no users with any instances.
              </h5>
            )}
          </MDBContainer>
        ) : (
          <MDBContainer className="mt-5">
            <LinearProgress />
          </MDBContainer>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { select_instance })(Instances_Chadmin);
