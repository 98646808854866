import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import { set_user } from "../redux/actions";
import t from "../utilities/transitions";
import LinearProgress from "@mui/material/LinearProgress";
import { MDBContainer } from "mdb-react-ui-kit";
import axios from "axios";
import { useHistory, useLocation, useParams } from "react-router-dom";

const Validate = ({ set_user, userInfo }) => {
  const [working, setWorking] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (userInfo?._id) history.push("/dashboard");
  }, [userInfo?._id]);

  /**
   * Validates the request
   * If validation request is found and is successful, set user
   * If validation is found, but mod approval is still required, route to "Awaiting Approval" page
   */
  const load = () =>
    axios
      .get("/auth/validate/" + id)
      .then((res) => set_user(res.data))
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          setWorking(false);
          alert("Not found");
        } else setTimeout(load, 1000);
      });

  return (
    <motion.div
      transition={t.transition}
      exit={history?.location?.state?.exit || t.fade_out_scale_1}
      animate={t.normalize}
      initial={history?.location?.state?.enter || t.fade_out}
    >
      <MDBContainer>
        {working ? (
          <>
            <h5 className="text-center mt-4 display-6">Validating</h5>
            <LinearProgress />
          </>
        ) : (
          <motion.div
            transition={t.transition}
            exit={history?.location?.state?.exit || t.fade_out_scale_1}
            animate={t.normalize}
            initial={history?.location?.state?.enter || t.fade_out}
          >
            <h5 className="text-center mt-4 display-6">Invalid Link</h5>
          </motion.div>
        )}
      </MDBContainer>
    </motion.div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

export default connect(mapStateToProps, { set_user })(Validate);
