import React from "react";
import Logs from "./Logs";
import Preferences from "../Preferences";
import Status from "./Status";
import Files from "./Files";
import Messages from "./Messages";

class LiveView extends React.Component {
  constructor(props) {
    super();
    this.view = props.liveView;
  }

  render() {
    switch (this.view) {
      case "preferences":
        return <Preferences {...this.props} />;
      case "logs":
        return <Logs {...this.props} />;
      case "status":
        return <Status {...this.props} />;
      case "files":
        return <Files {...this.props} />;
      case "messages":
        return <Messages {...this.props} />;
      default:
        console.log("oob jizzer live view", this.view);
        return <></>;
    }
  }
}

export default LiveView;
