import React from "react";
import sanitize from "sanitize-html";
import { parse } from "node-html-parser";
import { MDBBadge, MDBTooltip } from "mdb-react-ui-kit";
import { parse as parseURL } from "url";
import crypto from "crypto-browserify";

const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const h = {};

/**
 *
 * @param {String} string
 * @returns The first up to 24 characters of that string
 */
h.veryShortString = (string) => {
  string = String(string);
  if (string.length > 25) return string.substring(0, 24) + "...";
  else return string;
};

/**
 *
 * @param {String} url - A url to be checked
 * Parse the url, create a URL using the constructor
 * Will break if it fails
 * @returns Boolean - whether or not the string is a valid url
 */
h.checkURL = (url) => {
  try {
    const parsed = parseURL(url);
    if (!parsed.protocol) url = "https://" + url;
    new URL(url);
    return url.split(".").length > 1;
  } catch (err) {
    return false;
  }
};

// Hides all tooltips on the page
h.hideToolTips = () => {
  Array.from(document.getElementsByClassName("tooltip")).forEach((e) => {
    // e.classList.remove("show")
    e.style.display = "none";
  });
};

/**
 *
 * @param {String} html - HTML string
 *
 * If an html string that is not properly parsed (class names wrong, etc), this function will fix it
 *
 * @returns HTML string that is properly parsed
 */
h.parseStrayTags = (html) => {
  if (html.includes("⚓")) {
    alert("Swastikas are not allowed");
    return "<p><br></p>";
  }
  html = html.replace(/[\u200B-\u200F\uFEFF]/g, "");
  let parsedHTML = parse(html);

  parsedHTML = Array.from(parsedHTML.getElementsByTagName("p"))
    .map((node) => {
      // Regular links
      Array.from(node.getElementsByTagName("a"))
        .filter((link) => ["#", "@"].indexOf(link.textContent[0]) === -1)
        .forEach(
          (link) =>
            (link.textContent = `⚓⚓⚓⚓${link.getAttribute("href")}⚓ ⚓⚓${
              link.textContent
            }⚓⚓ ⚓`)
        );
      let updatedHTML = node.textContent;
      updatedHTML = updatedHTML.split("⚓⚓⚓⚓");

      for (let u = 0; u < updatedHTML.length; u++) {
        const slice = updatedHTML[u];
        if (slice.includes("⚓ ⚓⚓")) {
          const href = slice.split("⚓ ⚓⚓")[0];
          const text = slice.split("⚓ ⚓⚓")[1].split("⚓⚓ ⚓")[0];
          updatedHTML[
            u
          ] = `<a class="text-blue text-decoration-none" href="${href}">${text}</a>${
            slice.split("⚓⚓ ⚓")[1]
          }`;
        }
      }
      updatedHTML = updatedHTML.join("");

      updatedHTML = updatedHTML.split("");
      // Hashtags/mentions
      updatedHTML.forEach((char, c) => {
        if (
          char === "#" &&
          updatedHTML[c + 1] &&
          ["@", "#", "\n", " "].indexOf(updatedHTML[c + 1]) === -1
        ) {
          updatedHTML[c] = "⚓⚓HASH⚓";
          let index = c + 1;
          let endFound = false;
          while (!endFound) {
            if (
              !updatedHTML[index + 1] ||
              ["@", "#", "\n", " "].indexOf(updatedHTML[index + 1]) > -1
            ) {
              updatedHTML[index] = updatedHTML[index] + "⚓HASH⚓⚓";
              endFound = true;
            }
            index++;
          }
        }
        if (
          char === "@" &&
          updatedHTML[c + 1] &&
          ["@", "#", "\n", " "].indexOf(updatedHTML[c + 1]) === -1
        ) {
          updatedHTML[c] = "⚓⚓MENTION⚓";
          let index = c + 1;
          let endFound = false;
          while (!endFound) {
            if (
              !updatedHTML[index + 1] ||
              ["@", "#", "\n", " "].indexOf(updatedHTML[index + 1]) > -1
            ) {
              updatedHTML[index] = updatedHTML[index] + "⚓MENTION⚓⚓";
              endFound = true;
            }
            index++;
          }
        }
      });

      updatedHTML = updatedHTML.join("");

      updatedHTML = updatedHTML.split("⚓⚓HASH⚓");
      for (let u = 0; u < updatedHTML.length; u++) {
        const slice = updatedHTML[u];
        if (slice.includes("⚓HASH⚓⚓")) {
          const text = slice.split("⚓HASH⚓⚓")[0];
          updatedHTML[
            u
          ] = `<a class="text-secondary" href="/tag/${text}">#${text}</a>${
            slice.split("⚓HASH⚓⚓")[1]
          }`;
        }
      }
      updatedHTML = updatedHTML.join("");

      updatedHTML = updatedHTML.split("⚓⚓MENTION⚓");
      for (let u = 0; u < updatedHTML.length; u++) {
        const slice = updatedHTML[u];
        if (slice.includes("⚓MENTION⚓⚓")) {
          const text = slice.split("⚓MENTION⚓⚓")[0];
          updatedHTML[
            u
          ] = `<a class="text-success" href="/${text}">@${text}</a>${
            slice.split("⚓MENTION⚓⚓")[1]
          }`;
        }
      }
      updatedHTML = updatedHTML.join("");
      return "<p>" + updatedHTML + "</p>";
    })
    .join("");

  return parsedHTML;
};

/**
 *
 * @param {Object} userInfo - Users document
 * @param {String} classes - CSS classes
 * @returns Tooltip with badge that depends on the user's role with provided CSS classes
 */
h.getBadge = (userInfo, classes) => {
  if (userInfo.role === "Chadmin")
    return (
      <MDBTooltip
        tag="span"
        wrapperProps={{ className: "name-chadmin cursor-default" }}
        title="Chadmin"
      >
        <MDBBadge className={`badge-chadmin ${classes}`}>
          <div className="d-flex justify-content-center align-items-center">
            <div
              className="fit-images"
              style={{ backgroundImage: `url("/assets/images/meltrans.png")` }}
            ></div>
          </div>
        </MDBBadge>
      </MDBTooltip>
    );
  else if (userInfo.role === "Janny")
    return (
      <MDBTooltip
        tag="span"
        wrapperProps={{ className: "name-janny cursor-default" }}
        title="Janny"
      >
        <MDBBadge className={`badge-janny ${classes}`}>
          <div className="d-flex justify-content-center align-items-center">
            <div
              className="fit-images"
              style={{
                backgroundImage: `url("/assets/images/thomastrans.png")`,
              }}
            ></div>
          </div>
        </MDBBadge>
      </MDBTooltip>
    );
  else if (userInfo.verified)
    return (
      <MDBTooltip
        tag="span"
        wrapperProps={{ className: "name-verified cursor-default" }}
        title="Verified"
      >
        <MDBBadge className={`badge-verified ${classes}`}>
          <div className="d-flex justify-content-center align-items-center">
            <div
              className="fit-images"
              style={{
                backgroundImage: `url("/assets/images/verifiedlogotrans.png")`,
              }}
            ></div>
          </div>
        </MDBBadge>
      </MDBTooltip>
    );
  else return <></>;
};

/**
 *
 * @param {JavaScript date} date
 * @returns a human readable date in the format "MM/DD/YYYY"
 */
h.makeDateHR = (date) => {
  /**
   * Accepts a Javascript date and returns a human readable date in the format "MM/DD/YYYY"
   */
  date = new Date(date);
  let months = date.getMonth() + 1;
  let days = date.getDate();
  let years = date.getFullYear();
  return months + "/" + days + "/" + years;
};

/**
 *
 * @param {JavaScript date} timestamp
 * @returns The time that the message was sent if sent less than a day ago, otherwise the date that the message was sent
 */
h.getMessageTime = (timestamp) => {
  const now = new Date();
  const messageTime = new Date(timestamp);
  const timeDifference = now.getTime() - messageTime.getTime();
  const day = 1000 * 60 * 60 * 24;
  if (timeDifference > day) return h.getNiceDate(timestamp);
  else return h.getTimeHR(timestamp);
};

/**
 *
 * @param {String} html - HTML string
 * @returns The length of the regular text in the string sans whitespace that is not a space or new line
 */
h.checkHTMLLength = (html) =>
  String(parse(html).textContent)
    .split("")
    .filter((c) => {
      const checkWhiteSpace = c.match(/[\s]/);
      if (!checkWhiteSpace) return true;
      else {
        return [" ", "\n"].indexOf(c) > -1;
      }
    }).length;

/**
 *
 * @param {Object} userInfo - Users document
 * @param {Array} rawData - List of emissions
 * @returns emissionIDs of emissions that were authored by the user
 */
h.getThreadEmissions = (userInfo, rawData) => {
  let emissions = [];
  rawData.forEach((emission) => {
    if (emission.userID === userInfo._id) emissions.push(emission.emissionID);
    if (emission.signalBoost && emission.signalBoost.userID === userInfo._id)
      emissions.push(emission.signalBoost.emissionID);
    if (emission.replyEmission) {
      if (emission.replyEmission.userID === userInfo._id)
        emissions.push(emission.replyEmission.emissionID);
      if (
        emission.replyEmission.signalBoost &&
        emission.replyEmission.signalBoost.userID === userInfo._id
      )
        emissions.push(emission.replyEmission.signalBoost.emissionID);

      if (emission.replyEmission.replyEmission) {
        if (emission.replyEmission.replyEmission.userID === userInfo._id)
          emissions.push(emission.replyEmission.replyEmission.emissionID);
        if (
          emission.replyEmission.replyEmission.signalBoost &&
          emission.replyEmission.replyEmission.signalBoost.userID ===
            userInfo._id
        )
          emissions.push(
            emission.replyEmission.replyEmission.signalBoost.emissionID
          );
      }
    }
  });
  emissions = [...new Set(emissions)];
  return emissions;
};

/**
 *
 * @param {Object} userInfo - Users document
 * @param {Object} profileInfo - Profile Info
 * @returns emissionIDs of emissions in profile emissions and profile likes that were authored by the user
 */
h.getUserProfileEmissions = (userInfo, profileInfo) => {
  let emissions = [];
  profileInfo.emissions.items.forEach((emission) => {
    if (emission.userID === userInfo._id) emissions.push(emission.emissionID);
    if (emission.signalBoost && emission.signalBoost.userID === userInfo._id)
      emissions.push(emission.signalBoost.emissionID);
    if (emission.replyEmission) {
      if (emission.replyEmission.userID === userInfo._id)
        emissions.push(emission.replyEmission.emissionID);
      if (
        emission.replyEmission.signalBoost &&
        emission.replyEmission.signalBoost.userID === userInfo._id
      )
        emissions.push(emission.replyEmission.signalBoost.emissionID);

      if (emission.replyEmission.replyEmission) {
        if (emission.replyEmission.replyEmission.userID === userInfo._id)
          emissions.push(emission.replyEmission.replyEmission.emissionID);
        if (
          emission.replyEmission.replyEmission.signalBoost &&
          emission.replyEmission.replyEmission.signalBoost.userID ===
            userInfo._id
        )
          emissions.push(
            emission.replyEmission.replyEmission.signalBoost.emissionID
          );
      }
    }
  });
  profileInfo.likes.items.forEach((emission) => {
    if (emission.userID === userInfo._id) emissions.push(emission.emissionID);
    if (emission.signalBoost && emission.signalBoost.userID === userInfo._id)
      emissions.push(emission.signalBoost.emissionID);
    if (emission.replyEmission) {
      if (emission.replyEmission.userID === userInfo._id)
        emissions.push(emission.replyEmission.emissionID);
      if (
        emission.replyEmission.signalBoost &&
        emission.replyEmission.signalBoost.userID === userInfo._id
      )
        emissions.push(emission.replyEmission.signalBoost.emissionID);

      if (emission.replyEmission.replyEmission) {
        if (emission.replyEmission.replyEmission.userID === userInfo._id)
          emissions.push(emission.replyEmission.replyEmission.emissionID);
        if (
          emission.replyEmission.replyEmission.signalBoost &&
          emission.replyEmission.replyEmission.signalBoost.userID ===
            userInfo._id
        )
          emissions.push(
            emission.replyEmission.replyEmission.signalBoost.emissionID
          );
      }
    }
  });
  emissions = [...new Set(emissions)];
  return emissions;
};

/**
 *
 * @param {Array} oldEmissions - Old list of emissions
 * @param {Array} newEmissions - New list of emissions
 *
 * Loops through old emissions
 * If any match any in the newEmissions array, replace with new data
 *
 * @returns Updated list of emissions
 */
h.replaceUserEmissions = (oldEmissions, newEmissions) =>
  oldEmissions.map((e) => {
    let replacedEmission;
    replacedEmission = newEmissions.find(
      (emission) => emission.emissionID === e.emissionID
    );
    if (replacedEmission) e = replacedEmission;
    else {
      if (e.signalBoost) {
        replacedEmission = newEmissions.find(
          (emission) => emission.emissionID === e.signalBoost.emissionID
        );
        if (replacedEmission) e.signalBoost = replacedEmission;
      }
      if (e.replyEmission) {
        replacedEmission = newEmissions.find(
          (emission) => emission.emissionID === e.replyEmission.emissionID
        );
        if (replacedEmission) e.replyEmission = replacedEmission;
        else {
          if (e.replyEmission.signalBoost) {
            replacedEmission = newEmissions.find(
              (emission) =>
                emission.emissionID === e.replyEmission.signalBoost.emissionID
            );
            if (replacedEmission)
              e.replyEmission.signalBoost = replacedEmission;
          }
          if (e.replyEmission.replyEmission) {
            replacedEmission = newEmissions.find(
              (emission) =>
                emission.emissionID === e.replyEmission.replyEmission.emissionID
            );
            if (replacedEmission)
              e.replyEmission.replyEmission = replacedEmission;
            else {
              if (e.replyEmission.replyEmission.signalBoost) {
                replacedEmission = newEmissions.find(
                  (emission) =>
                    emission.emissionID ===
                    e.replyEmission.replyEmission.signalBoost.emissionID
                );
                if (replacedEmission)
                  e.replyEmission.replyEmission.signalBoost = replacedEmission;
              }
            }
          }
        }
      }
    }

    return e;
  });

/**
 *
 * @param {JavaScript date} date
 * @returns a human readable time in the format "0:00AM"
 */
h.getTimeHR = (date) => {
  date = new Date(date);
  let meridian = "AM";
  let hours = date.getHours();
  let minutes = date.getMinutes();
  if (!hours) hours = 12;
  if (hours > 12) {
    hours -= 12;
    meridian = "PM";
  }
  if (String(minutes).length === 1) minutes = `0${minutes}`;
  return hours + ":" + minutes + meridian;
};

/**
 * @param {String | Number} num - A number (i.e. 1000000)
 * @returns String - Number with commas appended (i.e. 1,000,000)
 */
h.numberWithCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 *
 * @param {String} string
 * @returns The first up to 99 characters of that string
 */
h.shortString = (string) => {
  string = String(string);
  if (string.length > 100) return string.substring(0, 99) + "...";
  else return string;
};

/**
 *
 * @param {String} string
 * @returns Boolean - Whether the string is a number.
 */
h.isNumeric = (string) => {
  if (typeof string != "string") return false;
  return !isNaN(string) && !isNaN(parseFloat(string));
};

/**
 * Fixes MDB bug in which labels on inputs with text input are not properly floated
 * Floats the labels
 */
h.floatLabels = () =>
  setTimeout(
    () =>
      [].slice
        .call(document.getElementsByClassName("form-control"))
        .forEach((e) => {
          if (e.value && !e.classList.contains("active")) {
            e.classList.add("active");
            const oldValue = e.value;
            e.value += "4";
            e.value = oldValue;
          }
        }),
    250
  );

/**
 *
 * @param {JavaScript date} date
 * @returns Date in the format of "Jan 1, 1970"
 */
h.getNiceDate = (date) => {
  date = new Date(date);
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
};

/**
 *
 * @param {File} file
 * @returns md5 hash of the file
 */
h.getMD5 = (file) => {
  try {
    return crypto
      .createHash("md5")
      .update(URL.createObjectURL(file))
      .digest("hex");
  } catch (err) {
    console.log("getMD5 error", err, file);
    return "";
  }
};

/**
 *
 * @param {Number} time - Milliseconds to sleep
 *
 * Freezes for the amount of milliseconds specified
 */
h.sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));

/**
 * Executes a captcha challenge and generates a key a key
 * Will hang until connected to captcha servers
 */
h.getRecaptcha = (reCaptchaProps) =>
  new Promise(async (resolve, reject) => {
    if (String(process.env.REACT_APP_DEV) === "true")
      return resolve(process.env.REACT_APP_DEV_CAPTCHA_KEY);
    if (reCaptchaProps.executeRecaptcha)
      reCaptchaProps
        .executeRecaptcha()
        .then((res) => resolve(res))
        .catch((err) => {
          console.log("captcha error", err);
          alert("Recaptcha error. Refresh the page and try again.");
          return reject(false);
        });
    else {
      return reject(true);
    }
  });

/**
 *
 * @param {Object} userInfo - Users document
 * @returns Boolean - Whether the user has Janny privileges
 */
h.checkJanny = (userInfo) => {
  if (
    userInfo &&
    userInfo.role &&
    ["Janny", "Chadmin"].indexOf(userInfo.role) !== -1
  )
    return true;
  else return false;
};

/**
 *
 * @param {Object} userInfo - Users document
 * @returns Boolean - Whether the user has Chadmin privileges
 */
h.checkChadmin = (userInfo) => {
  if (userInfo && userInfo.role && userInfo.role === "Chadmin") return true;
  else return false;
};

/**
 *
 * @param {String} code - Removal code
 * @returns Removal label
 */
h.getRemovedReason = (code) => {
  switch (code) {
    case "fed":
      return "Terrorism/Fedposting";
    case "porn":
      return "Porn";
    case "spam":
      return "Spam";
    default:
      console.log(code);
      return "Other";
  }
};

/**
 *
 * @param {String} string
 * @returns The first up to 100 characters of that string
 */
h.abbreviatedText = (text) => {
  text = String(text);
  if (text.length > 100) return text.substring(0, 100) + "...";
  else return text;
};

/**
 *
 * @param {String} string
 * @returns The first up to 1000 characters of that string
 */
h.longString = (text) => {
  text = String(text);
  if (text.length > 1000) return text.substring(0, 1000) + "...";
  else return text;
};

/**
 *
 * @param {HTML Element} e
 * @returns Inner dimensions of the element
 */
h.innerDimensions = (e) => {
  const computedStyle = getComputedStyle(e);

  let width = e.clientWidth;
  let height = e.clientHeight;

  height -=
    parseFloat(computedStyle.paddingTop) +
    parseFloat(computedStyle.paddingBottom);
  width -=
    parseFloat(computedStyle.paddingLeft) +
    parseFloat(computedStyle.paddingRight);

  return {
    height: height,
    width: width,
  };
};

/**
 *
 * @param {String} html - HTML string
 * @returns HTML with only approved tags, classes, and attributes
 */
h.sanitizeHTML = (html) => {
  while (html.split("<p><br></p><p><br></p>").length > 1)
    html = html.split("<p><br></p><p><br></p>").join("<p><br></p>");
  html = h.parseStrayTags(html);
  while (html.split("  ").length > 1) html = html.split("  ").join(" ");
  while (html.split("\n\n").length > 1) html = html.split("\n\n").join("\n");
  while (html.split(" \n \n").length > 1)
    html = html.split(" \n \n").join("\n");
  const clean = sanitize(html, {
    allowedTags: ["a", "br", "p", "div", "span"],
    allowedAttributes: {
      a: ["href", "class"],
      br: [],
      p: [],
      div: [],
      span: [],
    },
    allowedClasses: {
      a: [
        "text-success",
        "text-secondary",
        "text-blue",
        "text-decoration-none",
        "ql-mention",
        "ql-hashtag",
      ],
      br: [],
      p: [],
      div: [],
      span: [],
    },
  });
  return clean;
};

/**
 *
 * @param {String} action - Action ID
 * @param {String} app - App ID
 * @returns Action Label based on action and app
 */
h.getActionName = (action, app) => {
  switch (app) {
    case "jizzer-beta":
      switch (action) {
        case "domain-submitted":
          return "Domain Submitted";
        case "preferences-submitted":
          return "Preferences Submitted";
        case "launched":
          return "App Launched";
        case "accept":
          return "Request Accepted";
        case "request":
          return "Request to join Filepimps Beta";
        case "updated-preferences":
          return "Preferences Updated";
        case "version-update":
          return "Upgraded to the Latest Version";
        case "disable":
          return "Disabled Instance";
        case "restore":
          return "Restored Instance";
        default:
          console.log("oob action jizzer beta", action);
          return <></>;
      }
    default:
      console.log("oob app");
  }
};

/**
 *
 * @param {Number} value - Any number
 *
 * Shortens numbers by compiling them
 * i.e. 1000 bytes -> 1KB
 * 10000 bytes -> 10KB
 *
 *
 * @returns The compiled number
 */
h.compiledNumber = (value) => {
  value = Number(value);
  if (value > 1000000000) return String((value / 1000000000).toFixed(1)) + "B";
  else if (value > 1000000) return String((value / 1000000).toFixed(1)) + "M";
  else if (value > 1000) return String((value / 1000).toFixed(1)) + "K";
  return value;
};

export default h;
