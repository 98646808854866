export default [
  {
    field: "icon",
    label: "Logo",
    tab: "logos",
  },
  {
    field: "newJizzIcon",
    label: "New Post Icon",
    tab: "logos",
    classes: "global-input-button p-2",
  },
  {
    field: "loadingSpinner",
    label: "Loading Icon",
    tab: "logos",
  },
  {
    field: "defaultAvatar",
    label: "Default Avatar",
    tab: "logos",
  },
  {
    field: "defaultBackground",
    label: "Default Background",
    tab: "logos",
  },
  {
    field: "chadminBadge",
    label: "Admin Badge",
    tab: "logos",
  },
  {
    field: "jannyBadge",
    label: "Mod Badge",
    tab: "logos",
  },
  {
    field: "verifiedBadge",
    label: "Verified Badge",
    tab: "logos",
  },
  {
    field: "gigachadAvatar",
    label: "Superadmin Avatar",
    tab: "settings",
  },
  {
    field: "gigachadBackground",
    label: "Superadmin Background",
    tab: "settings",
  },
];
