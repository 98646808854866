import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import t from "../../../../../utilities/transitions";
import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBListGroup,
  MDBListGroupItem,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBRipple,
  MDBBtn,
} from "mdb-react-ui-kit";
import settings from "./formData/settings";
import { Switch } from "@mui/material";
import RuleModal from "./RuleModal";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { change_user_details } from "../../../../../redux/actions";
import BioField from "../../settings/BioField";
import Spinner from "../../../../../components/Spinner";

const Settings = ({
  animations,
  changeHandler,
  change_user_details,
  clearErrorPage,
  errorPage,
  formValues,
  screenDimensions,
  selectFile,
  formsSubmitted,
  userInfo,
  view,
}) => {
  const instance = userInfo.instances.find((i) => i.service === "jizzer");
  const gigachad = formValues.find((v) => v.field === "gigachad");
  const rules = formValues.find((v) => v.field === "rules");
  const [scrolled, setScrolled] = useState(false);
  const [newRuleModalShown, setNewRuleModalShown] = useState(false);
  const [rulesLength, setRulesLength] = useState(rules.value.length || 0);
  const [ruleSelected, setRuleSelected] = useState(false);
  const [toggling, setToggling] = useState([]);

  useEffect(() => {
    const errors = formValues.filter((v) => v.tab === "settings" && v.error);
    errors.forEach((error) => {
      if (error.field === "gigachad") {
        Object.keys(error.error).forEach((key) => {
          if (error.error[key]) {
            const element = document.getElementById("gigachad." + key);
            if (element) element.setCustomValidity(error.error[key]);
          }
        });
      } else {
        const element = document.getElementById(error.field);
        if (element) element.setCustomValidity(error.error);
      }
    });
  }, []);

  const scrollToError = () => {
    if (!scrolled && errorPage && view === "settings") {
      const errors = formValues.filter((v) => v.tab === "settings" && v.error);
      for (let e = 0; e < errors.length; e++) {
        const error = errors[e];
        if (error.field === "gigachad") {
          let hit = false;
          Object.keys(error.error).forEach((key) => {
            try {
              if (error.error[key]) {
                if (!hit) {
                  const element = document.getElementById("gigachad." + key);
                  if (element) element.scrollIntoView();
                  clearErrorPage();
                  setScrolled(true);
                  hit = true;
                }
              }
            } catch (err) {
              console.log("error", err);
            }
          });
          if (hit) break;
        } else {
          const element = document.getElementById(error.field);
          if (element) element.scrollIntoView();
          break;
        }
      }
    }
  };

  useEffect(() => {
    setRulesLength(rules.value.length || 0);
    if (rules.value.length && rules.value.length > rulesLength) {
      const newRule = document.getElementById("rule-" + rules.value[0].id);
      if (newRule) newRule.scrollIntoView();
    }
  }, [rules.value.length]);

  useEffect(() => {
    if (ruleSelected) toggleNewRuleModal();
  }, [ruleSelected]);

  useEffect(() => {
    if (!newRuleModalShown && ruleSelected) setRuleSelected(false);
  }, [newRuleModalShown]);

  const toggleNewRuleModal = () => setNewRuleModalShown(!newRuleModalShown);

  const saveRule = (rule) => {
    changeHandler({
      target: {
        name: "rules",
        value: ruleSelected
          ? formValues
              .find((v) => v.field === "rules")
              .value.map((r) => {
                if (r.id === ruleSelected)
                  r = {
                    ...r,
                    ...rule,
                  };

                return r;
              })
          : [
              {
                ...rule,
                id: uuid(),
              },
              ...formValues.find((v) => v.field === "rules").value,
            ],
      },
    });
    if (ruleSelected) {
      const newRule = document.getElementById("rule-" + ruleSelected);
      if (newRule) newRule.scrollIntoView();
      setNewRuleModalShown(false);
      setRuleSelected(false);
    }
  };

  const removeRule = () => {
    changeHandler({
      target: {
        name: "rules",
        value: formValues
          .find((v) => v.field === "rules")
          .value.filter((v) => v.id !== ruleSelected),
      },
    });
  };

  const setBioText = (html) => console.log("setBioText", html);

  const toggleAccessibility = (criteria) => {
    setToggling((curr) => [...new Set([...curr, criteria])]);
    axios
      .post("/jizzer/toggle-criteria", {
        criteria,
      })
      .then(() =>
        change_user_details({
          ...userInfo,
          instances: userInfo.instances.map((instance) => {
            if (instance.service === "jizzer")
              instance[criteria] = !instance[criteria];
            return instance;
          }),
        })
      )
      .catch((err) => {
        console.log("toggleAccessibility error", err, criteria);
        alert("An error occurred. Please try again later.");
      })
      .finally(() => setToggling((curr) => curr.filter((c) => c !== criteria)));
  };

  return (
    <motion.div
      transition={t.transition}
      initial={animations.enter}
      animate={t.normalize}
      exit={animations.exit}
      className="pb-4"
      onAnimationComplete={scrollToError}
    >
      <RuleModal
        modalShown={newRuleModalShown}
        toggleShowModal={toggleNewRuleModal}
        rules={rules}
        saveRule={saveRule}
        removeRule={removeRule}
        ruleSelected={ruleSelected}
      />
      <hr />
      <MDBValidation
        className={formsSubmitted.includes("settings") ? "was-validated" : ""}
      >
        {instance.status === "in-progress" ? (
          <>
            <h5 className="text-center my-3">Superuser</h5>
            <MDBContainer>
              <div className="row">
                {Object.keys(gigachad.value)
                  .filter((key) => key !== "bio")
                  .map((key) => {
                    const value = gigachad.value[key];
                    const error = gigachad.error[key];
                    const label = gigachad.labels[key];
                    return (
                      <MDBValidationItem
                        className={`pb-4 col-12 col-lg-6 col-xl-4`}
                        feedback={error}
                        invalid={true}
                      >
                        <MDBInput
                          name={"gigachad." + key}
                          onChange={changeHandler}
                          id={"gigachad." + key}
                          label={label}
                          size="lg"
                          className={!error ? "mb-0" : 0}
                          value={value}
                          key={key}
                          type={key.includes("password") ? "password" : "text"}
                        />
                      </MDBValidationItem>
                    );
                  })}
                <div className="col-12 col-lg-6 col-xl-4">
                  <BioField
                    setText={setBioText}
                    setForceParse={() => {}}
                    maxChars={1000}
                    userInfo={{ bio: gigachad.value.bio }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-6 mt-4">
                  <h6 className="text-center fs-5 display-6">Avatar</h6>
                  <div className="d-flex justify-content-center align-items-center square-12 mx-auto">
                    <MDBRipple
                      onClick={() => selectFile("gigachadAvatar")}
                      tag="div"
                      rippleColor="primary"
                      className="fit-images fit-round"
                      style={{
                        backgroundImage: `url("${
                          formValues.find(
                            (value) => value.field === "gigachadAvatar"
                          ).path
                        }")`,
                        cursor: "pointer",
                        borderRadius: "50%",
                      }}
                    ></MDBRipple>
                  </div>
                </div>
                <div className="col-12 col-lg-6 mt-4">
                  <h6 className="text-center fs-5 display-6">Background</h6>
                  <div
                    style={{ height: "200px" }}
                    className="d-flex justify-content-center align-items-center w-100 mx-auto"
                  >
                    <MDBRipple
                      onClick={() => selectFile("gigachadBackground")}
                      tag="div"
                      rippleColor="light"
                      className="fit-background cursor-pointer"
                      style={{
                        backgroundImage: `url("${
                          formValues.find(
                            (value) => value.field === "gigachadBackground"
                          ).path
                        }")`,
                      }}
                    ></MDBRipple>
                  </div>
                </div>
              </div>
            </MDBContainer>
          </>
        ) : (
          <MDBContainer fluid className="px-0">
            <MDBRow>
              <MDBCol className="my-4" size="md">
                <h5 className="text-center display-6 fs-4">
                  {instance.readOnly
                    ? "Your instance is read-only"
                    : "Your instance is accessible"}
                </h5>
                <MDBBtn
                  size="lg"
                  color="link"
                  rippleColor="#3f51b5"
                  className="mx-auto mt-3 d-block text-indigo"
                  disabled={toggling.includes("readOnly")}
                  onClick={() => toggleAccessibility("readOnly")}
                >
                  {toggling.includes("readOnly") ? (
                    <>
                      <Spinner className="me-2" size="sm" />
                      Toggling
                    </>
                  ) : (
                    <>
                      {instance.readOnly ? (
                        <>
                          <i className="fas fa-globe-americas me-2"></i>
                          Make accessible
                        </>
                      ) : (
                        <>
                          <i className="fas fa-glasses me-2"></i>
                          Make read-only
                        </>
                      )}
                    </>
                  )}
                </MDBBtn>
              </MDBCol>
              <MDBCol className="my-4" size="md">
                <h5 className="text-center display-6 fs-4">
                  {instance.disabled
                    ? "Your instance is disabled"
                    : "Your instance is enabled"}
                </h5>
                <MDBBtn
                  size="lg"
                  color="link"
                  rippleColor="#009688"
                  className="mx-auto mt-3 d-block text-teal"
                  disabled={toggling.includes("disabled")}
                  onClick={() => toggleAccessibility("disabled")}
                >
                  {toggling.includes("disabled") ? (
                    <>
                      <Spinner className="me-2" size="sm" />
                      Toggling
                    </>
                  ) : (
                    <>
                      {instance.disabled ? (
                        <>
                          <i className="fas fa-check-circle me-2" />
                          Enable Instance
                        </>
                      ) : (
                        <>
                          <i className="fas fa-exclamation-triangle me-2"></i>
                          Disable Instance
                        </>
                      )}
                    </>
                  )}
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        )}
        <hr />
        <MDBListGroup light>
          {settings
            .filter((setting) => setting.type !== "other")
            .map((item) => {
              const data = formValues.find((v) => v.field === item.id);
              let input = <></>;
              switch (item.type) {
                case "text":
                  input = (
                    <MDBValidationItem
                      feedback={data.error}
                      invalid={true}
                      className="min-w-25 max-w-50"
                    >
                      <MDBInput
                        id={item.id}
                        name={item.id}
                        onChange={changeHandler}
                        className={!data.error ? "mb-0" : 0}
                        value={data.value}
                      />
                    </MDBValidationItem>
                  );
                  break;
                case "number":
                  input = (
                    <MDBValidationItem
                      feedback={data.error}
                      invalid={true}
                      className="min-w-25 max-w-50"
                    >
                      <MDBInput
                        id={item.id}
                        name={item.id}
                        onChange={changeHandler}
                        className={!data.error ? "mb-0" : 0}
                        value={data.value}
                        type="number"
                        min={0}
                        max={10000}
                      />
                    </MDBValidationItem>
                  );
                  break;
                case "boolean":
                  input = (
                    <Switch
                      checked={data.value}
                      onChange={(e) => {
                        changeHandler({
                          target: {
                            name: e.target.name,
                            value: e.target.checked,
                          },
                        });
                      }}
                      id={item.id}
                      name={item.id}
                      disabled={item.id === "force_free_tier"}
                    />
                  );
                  break;
                default:
                  console.log("oob type", item.type);
              }
              return (
                <MDBListGroupItem
                  key={item.id}
                  className="d-flex justify-content-between align-items-center"
                >
                  <h6 className="m-0 fs-5 display-6">{item.label}</h6>
                  {input}
                </MDBListGroupItem>
              );
            })}

          <MDBListGroupItem>
            <MDBContainer fluid>
              <div className="row align-items-center">
                <div
                  className={`col-4 offset-0 offset-lg-4 text-${
                    screenDimensions.width > 991 ? "center" : "start"
                  }`}
                >
                  <h5 className="m-0">Rules</h5>
                </div>
                <div className="col-8 col-lg-4 d-flex justify-content-end">
                  <MDBBtn
                    type="button"
                    color="link"
                    rippleColor="primary"
                    onClick={toggleNewRuleModal}
                  >
                    <i className="fas fa-plus me-2" />
                    New Rule
                  </MDBBtn>
                </div>
              </div>
            </MDBContainer>
            <p className="text-center mx-auto w-max-content">
              <small className="text-center mx-auto w-max-content">
                These are rules are in addition to the universal rules for all
                instances
              </small>
            </p>
            <MDBListGroup light>
              {rules.value.map((rule) => (
                <MDBRipple
                  key={rule.id}
                  rippleColor="primary"
                  className="cursor-pointer"
                  id={"rule-" + rule.id}
                  onClick={() => setRuleSelected(rule.id)}
                >
                  <MDBListGroupItem
                    style={{ borderRadius: "0px" }}
                    action
                    className="px-2"
                  >
                    <h5>{rule.title}</h5>
                    <div
                      className="text-wrap text-break"
                      dangerouslySetInnerHTML={{
                        __html: rule.html,
                      }}
                    ></div>
                  </MDBListGroupItem>
                </MDBRipple>
              ))}
            </MDBListGroup>
          </MDBListGroupItem>
        </MDBListGroup>
      </MDBValidation>
    </motion.div>
  );
};

const mapStateToProps = (state) => ({
  screenDimensions: state.screenDimensions,
  userInfo: state.userInfo,
});

export default connect(mapStateToProps, { change_user_details })(Settings);
