import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
const translations = {
  duration: "Hours Streamed",
};

const addLabels = (series) => {
  return series.map((item) => ({
    ...item,
    label: translations[item.dataKey],
    valueFormatter: (v) => {
      console.log("val", v);
      return v.toFixed(3);
    },
  }));
};

const LiveStreaming = ({ stats }) => {
  const chartData = stats
    .filter((stat, s) => s >= stats.length - 12)
    .map((stat, s) => ({
      month: months[stat.month],
      duration:
        stat.users.reduce((prev, curr) => prev + curr.duration, 0) /
        (1000 * 60 * 60),
    }));
  return (
    <>
      <h5 className="display-6 text-center">Live Streaming</h5>
      <BarChart
        dataset={chartData}
        series={addLabels([
          { dataKey: "duration", stack: "all", color: "#14a44d" },
        ])}
        xAxis={[{ scaleType: "band", dataKey: "month" }]}
        slotProps={{ legend: { hidden: true } }}
        height={350}
      />
    </>
  );
};

export default LiveStreaming;
