import React, { useEffect } from "react";
import { motion } from "framer-motion";
import t from "../utilities/transitions";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBBtn,
} from "mdb-react-ui-kit";
import { useHistory, useLocation } from "react-router-dom";

const Info = ({}) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (window.location.hash) {
      const element = document.querySelector(window.location.hash);
      if (element) element.scrollIntoView();
    }
  }, []);

  return (
    <motion.div
      className="py-4"
      transition={t.transition}
      initial={history?.location?.state?.enter || t.fade_out}
      animate={t.normalize}
      exit={history?.location?.state?.exit || t.fade_out_scale_1}
    >
      <MDBContainer>
        <img
          style={{ height: "10rem" }}
          className="d-block mx-auto max-w-100"
          src="/assets/images/logo-wide.svg"
        />
        <hr />
        <MDBCard>
          <MDBCardHeader>
            <h5 className="m-0">What We Do</h5>
          </MDBCardHeader>
          <MDBCardBody>
            Carbon Valley enables individuals to create their own social media
            empires without writing a single line of code! Simply sign up for
            one of our products, register a domain with any domain registrar,
            point your domain at our servers, and we take care of the rest. Our
            products are highly customizable, allowing you to change the name,
            logos, colors, CSS, verbiage, and much more. All Carbon Valley apps
            are PWA-ready, allowing users to download them to any device without
            using any app store.
          </MDBCardBody>
        </MDBCard>
        <MDBCard id="jizzer" className="mt-4">
          <MDBCardBody>
            <div className="d-flex justify-content-center align-items-center">
              <div className="square-6 me-2">
                <div
                  className="fit-images"
                  style={{
                    backgroundImage: 'url("/assets/images/jizzer-logo.png")',
                  }}
                ></div>
              </div>
              <h5 className="display-6 ms-2 mb-0">Filepimps</h5>
            </div>
            <h6 className="text-center">
              Filepimps is a fully featured file sharing application with a
              modern interface that can be fully launched in seconds.
            </h6>
            <hr></hr>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <h5>File Sharing</h5>
                <p className="m-0">
                  Users can upload pictures, videos, and any other type of file
                  to your instance. This feature, along with allowed file types
                  and size restrictions, can be adjusted by the owner of the
                  instance.
                </p>
              </li>
              <li className="list-group-item">
                <h5>Encrypted Messaging</h5>
                <p className="m-0">
                  All private messages on all Filepimps instances are sent
                  through multiple stages of encryption, and cannot be seen by
                  any third parties, including Carbon Valley/hackers/law
                  enforcement.
                </p>
              </li>
              <li className="list-group-item">
                <h5>Fully-featured search engine</h5>
                <p className="m-0">
                  All Filepimps instances come packaged with a functioning
                  search engine using Mongo Atlas Search.
                </p>
              </li>
              <li className="list-group-item">
                <h5>Livestreaming</h5>
                <p className="m-0">
                  Livestreaming can be enabled on your Filepimps instance, which
                  will allow users with accounts on your instance to Go Live.
                </p>
              </li>
              <li className="list-group-item">
                <h5>AI Chat Companions</h5>
                <p className="m-0">
                  All Filepimps instances come packaged with 2 AI chat
                  companions, Virgil and Chad, who will give you and your users
                  solid advice from their own unique perspectives with anything
                  you ask them.
                </p>
              </li>
              <li className="list-group-item">
                <h5>Progressive Web App Enabled</h5>
                <p className="m-0">
                  All Filepimps instances are PWA-compatible, which means that
                  any mobile (and even desktop) user can visit the site once,
                  and will be prompted to download the mobile app, circumventing
                  the app stores.
                </p>
              </li>
              {/* <li className="list-group-item">
                <h5>Basic DDoS Protection</h5>
                <p className="m-0">
                  As with all Carbon Valley products, SSL certificates and basic
                  DDoS protections are installed for your instance. Advanced
                  DDoS mitigation products such as Cloudflare are fully
                  compatible as well.
                </p>
              </li> */}
            </ul>
            <MDBBtn
              color="link"
              className="text-darkblu d-block mx-auto mt-4"
              onClick={() => history.push("/products")}
              rippleColor="#1976d2"
              size="lg"
            >
              Interested? Visit our Products Page
            </MDBBtn>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </motion.div>
  );
};

export default Info;
