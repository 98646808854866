import React from "react";
import { connect } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import h from "../../../../utilities/helpers";
import t from "../../../../utilities/transitions";
import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import { notify, set_server_status, set_user } from "../../../../redux/actions";
import JizzerView from "./JizzerView";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { StaticRouter, Switch, Route } from "react-router-dom";
import Spinner from "../../../../components/Spinner";

class Jizzer extends React.Component {
  constructor(props) {
    super();
    const instance = props.userInfo.instances.find(
      (i) => i.service === "jizzer"
    );
    this.state = {
      view: instance.status === "in-progress" ? "prelaunch" : "live",
      liveView: "preferences",
      status: [],
      reset: false,
      statistics: false,
      files: [],
      messages: [],
      messagesLoaded: false,
      loadingFiles: false,
      loadStarted: false,
      fileTableState: {
        sort: {
          column: "date",
          order: "desc",
        },
        filesSelected: [],
        reset: false,
      },
    };
  }

  componentDidMount() {
    if (this.props.socket) this.initializeSocket();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.socket && this.props.socket) this.initializeSocket();
    const instance = this.props.userInfo.instances.find(
      (i) => i.service === "jizzer"
    );
    if (!this.state.loadStarted && instance?.status !== "in-progress") {
      this.loadStatistics();
      this.loadFiles();
      this.loadMessages();
    }
  }

  componentWillUnmount() {
    this.props.socket.off("jizzer-launch-status");
    this.props.socket.off("jizzer-launch-file-inc");
  }

  fileRemoveSuccess = () =>
    this.setState((curr) => ({
      ...curr,
      files: curr.files.filter(
        (file) =>
          !curr.fileTableState.filesSelected.find((_id) => _id === file._id)
      ),
      fileTableState: {
        ...curr.fileTableState,
        filesSelected: [],
        reset: !curr.fileTableState.reset,
      },
    }));

  loadStatistics = () =>
    this.setState(
      (curr) => ({
        ...curr,
        loadStarted: true,
        statistics: {
          instanceSize: [],
          tokens: [],
          streamLogs: [],
          loading: true,
        },
      }),
      () =>
        axios
          .get("/jizzer/statistics")
          .then((res) => {
            this.setState((curr) => ({
              ...curr,
              statistics: res.data,
            }));
          })
          .catch((err) => {
            console.log("loadStatistics error", err);
            this.setState(
              (curr) => ({
                ...curr,
                statistics: false,
              }),
              () => setTimeout(() => this.loadStatistics(), 1500)
            );
          })
    );

  loadFiles = () =>
    this.setState(
      (curr) => ({
        ...curr,
        loadingFiles: true,
      }),
      () =>
        axios
          .get("/jizzer/files")
          .then((res) => {
            this.setState((curr) => ({
              ...curr,
              files: res.data.files,
              loadingFiles: false,
            }));
          })
          .catch((err) => {
            console.log("loadFiles error", err);
            setTimeout(() => this.loadFiles(), 1500);
          })
    );

  loadMessages = () =>
    axios
      .get("/jizzer/messages")
      .then((res) =>
        this.setState((curr) => ({
          ...curr,
          messagesLoaded: true,
          messages: res.data.messages,
        }))
      )
      .catch((err) => {
        console.log("loadMessages error", err);
        setTimeout(() => this.loadMessages(), 1500);
      });

  initializeSocket = () => {
    if (!this.props.socket) return;
    this.props.socket.on("jizzer-launch-status", (status) => {
      status.id = uuid();
      this.setState((curr) => ({
        ...curr,
        status,
      }));
    });
    this.props.socket.on("jizzer-launch-file-inc", (total) => {
      this.setState((curr) => ({
        ...curr,
        status: curr.status.map((status) => {
          if (status.label.includes("Processing Files")) {
            status.total = total;
            if (!status.current) status.current = 0;
            status.current++;
            status.label = `Processing Files ${status.current}/${status.total}`;
          }
          return status;
        }),
      }));
    });
  };

  launch = () => {
    let bio = document.getElementById("input-bio");
    if (bio) {
      bio = h.sanitizeHTML(bio.innerHTML);
      if (h.checkHTMLLength(bio) > 1000) {
        this.setState((curr) => ({
          ...curr,
          errorPage: "settings",
        }));
        return;
      }
      if (typeof bio !== "string") bio = "";
    }

    const errors = this.state.formValues.filter((v) => v.error);
    console.log("errors", errors);
    for (let e = 0; e < errors.length; e++) {
      const error = errors[e];
      if (error.field === "gigachad") {
        let errorFound = false;
        Object.keys(error.error).forEach((key) => {
          if (error.error[key]) {
            errorFound = true;
            this.setState((curr) => ({
              ...curr,
              errorPage: "settings",
            }));
          }
        });
        if (errorFound) return;
      } else {
        this.setState((curr) => ({
          ...curr,
          errorPage: error.tab,
        }));
        return;
      }
    }
    this.setState(
      (curr) => ({
        ...curr,
        view: this.state.view === "prelaunch" ? "launching" : this.state.view,
        status: [{ label: "Updating Preferences", status: "inProgress" }],
      }),
      () => {
        const fd = new FormData();
        this.state.formValues
          .filter((value) => !value.path)
          .forEach((value) => {
            if (value.field === "gigachad" && bio) {
              value.value.bio = bio;
            }
            fd.append(
              value.field,
              ["gigachad", "rules"].includes(value.field)
                ? JSON.stringify(value.value)
                : value.value
            );
          });
        this.state.formValues
          .filter((value) => value.path && value.file)
          .forEach((value) => fd.append(value.field, value.file, value.name));
        console.log("launching", this.state.formValues);
        axios
          .post("/jizzer/jizzer-preferences", fd)
          .then((res) => {
            this.props.set_user(res.data.userInfo);
            const icon =
              this.state.view === "launching" ? (
                <i className="fas fa-rocket me-2 text-success" />
              ) : (
                <i className="fas fa-check-circle me-2 text-success" />
              );
            const text =
              this.state.view === "launching"
                ? `${
                    this.state.formValues.find((v) => v.field === "app_name")
                      .value
                  } Launched!`
                : "Preferences Updated";
            this.setState(
              (curr) => ({
                ...curr,
                view: "live",
                status: [],
              }),
              () => {
                this.props.notify(icon, text);
              }
            );
          })
          .catch((err) =>
            this.setState(
              (curr) => ({
                ...curr,
                view:
                  this.state.view === "launching"
                    ? "prelaunch"
                    : this.state.view,
                status: [],
              }),
              () => {
                console.log("error", err.response?.data?.error);
                alert(
                  err.response?.data?.error ||
                    "An error occurred. Please try again later."
                );
              }
            )
          );
      }
    );
  };

  setFormValues = (formValues) =>
    this.setState((curr) => ({
      ...curr,
      formValues,
    }));

  clearErrorPage = () =>
    this.setState((curr) => ({
      ...curr,
      errorPage: false,
    }));

  setLiveView = (view) =>
    this.setState((curr) => ({
      ...curr,
      liveView: view,
    }));

  setFileTableState = (state) =>
    this.setState((curr) => ({
      ...curr,
      fileTableState: state,
    }));

  render() {
    return (
      <motion.div
        className="pt-4 h-100 d-flex flex-column"
        transition={t.transition}
        initial={t.fade_out_right}
        animate={t.normalize}
        exit={t.fade_out_right}
      >
        <MDBContainer className="px-0" fluid>
          <div className="row align-items-center">
            <div className="col-4">
              <MDBBtn
                color="link"
                className="text-primary"
                onClick={() => this.props.setView("list")}
                size={
                  this.props.screenDimensions.width < 478 ? "sm" : undefined
                }
                rippleColor="primary"
              >
                <i
                  className={`fas fa-chevron-left ${
                    this.props.screenDimensions.width >= 405 ? "me-2" : "mb-2"
                  }`}
                ></i>
                Products
              </MDBBtn>
            </div>
            <div className="col-4">
              <h5 className="text-center display-6 fs-3 mb-0">Filepimps</h5>
            </div>
            {this.state.view === "prelaunch" ||
            this.state.liveView === "preferences" ||
            (this.state.view !== "prelaunch" && this.state.status.length) ? (
              <motion.div
                transition={t.transition}
                initial={t.fade_out}
                animate={t.normalize}
                exit={t.fade_out_scale_1}
                className="col-4 d-flex align-items-center justify-content-end"
                id="jizzer-header-buttons"
              >
                <MDBBtn
                  color="link"
                  rippleColor="primary"
                  className="me-2"
                  size={
                    this.props.screenDimensions.width < 478 ? "sm" : undefined
                  }
                  disabled={this.state.status.length}
                  onClick={() =>
                    this.setState((curr) => ({
                      ...curr,
                      reset: !this.state.reset,
                    }))
                  }
                >
                  <i className="fas fa-undo me-2" />
                  Reset
                </MDBBtn>
                <MDBBtn
                  color="success"
                  size={
                    this.props.screenDimensions.width < 478 ? "sm" : undefined
                  }
                  onClick={this.launch}
                  disabled={this.state.status.length}
                >
                  {this.state.view === "prelaunch" ? (
                    <>
                      <i
                        className={`fas fa-rocket ${
                          this.props.screenDimensions.width >= 372
                            ? "me-2"
                            : "mb-2"
                        }`}
                      />
                      Launch
                    </>
                  ) : (
                    <>
                      {this.state.status.length ? (
                        <>
                          <Spinner className="me-2" size="sm" />
                          Saving
                        </>
                      ) : (
                        <>
                          <i
                            className={`fas fa-save me-2 ${
                              this.props.screenDimensions.width >= 318
                                ? ""
                                : "fa-sm"
                            }`}
                          />
                          Save
                        </>
                      )}
                    </>
                  )}
                </MDBBtn>
              </motion.div>
            ) : (
              <></>
            )}
          </div>
          <hr />
          <StaticRouter location={this.state.view}>
            <AnimatePresence exitBeforeEnter>
              <Switch key={this.state.view}>
                <Route exact path=":view">
                  <JizzerView
                    setFormValuesParent={this.setFormValues}
                    errorPage={this.state.errorPage}
                    clearErrorPage={this.clearErrorPage}
                    status={this.state.status}
                    view={this.state.view}
                    formValuesParent={this.state.formValues}
                    liveView={this.state.liveView}
                    setLiveView={this.setLiveView}
                    reset={this.state.reset}
                    statistics={this.state.statistics}
                    files={this.state.files}
                    loadingFiles={this.state.loadingFiles}
                    fileTableState={this.state.fileTableState}
                    setFileTableState={this.setFileTableState}
                    fileRemoveSuccess={this.fileRemoveSuccess}
                    messages={this.state.messages}
                    messagesLoaded={this.state.messagesLoaded}
                  />
                </Route>
              </Switch>
            </AnimatePresence>
          </StaticRouter>
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {
  notify,
  set_server_status,
  set_user,
})(Jizzer);
