import React from "react";
import { MDBRipple } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

/**
 *
 * @param {Function} nav - The navigation function
 * @returns The desktop logo
 */
const DesktopLogo = ({ collapseNav }) => {
  return (
    <MDBRipple
      className="me-3 p-1 rounded-4"
      rippleColor="#1976d2"
      rippleTag="span"
    >
      <Link to="/" onClick={collapseNav} id="logo-desktop">
        <img
          id="svg-logo"
          src="/assets/images/logo-wide.svg"
          alt="Navbar Logo"
        />
      </Link>
    </MDBRipple>
  );
};

export default DesktopLogo;
