import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import h from "../../../../../../utilities/helpers";
import t from "../../../../../../utilities/transitions";
import { MDBBtn, MDBDatatable } from "mdb-react-ui-kit";
import { Collapse } from "@mui/material";
import ConfirmDeleteModal from "./ConfirmDeleteModal";

const columns = [
  {
    label: "File",
    field: "file",
    sort: true,
  },
  {
    label: "Original Name",
    field: "name",
    sort: true,
  },
  {
    label: "Type",
    field: "mimetype",
    sort: true,
  },
  {
    label: "Date",
    field: "date",
    sort: true,
  },
  {
    label: "Size (MB)",
    field: "size",
    sort: true,
  },
];

const defaultFileTableState = {
  sort: {
    column: "date",
    order: "desc",
  },
  filesSelected: [],
  reset: false,
};

const Files = ({
  files,
  loadingFiles,
  fileRemoveSuccess,
  fileTableState,
  setFileTableState,
}) => {
  const [confirmDeleteModalShown, setConfirmDeleteModalShown] = useState(false);
  const fileTableStateRef = useRef(fileTableState);

  files = files
    .map((file) => ({ ...file, id: file._id }))
    .sort((a, b) => {
      const asc = fileTableState.sort.order === "asc";
      const column = fileTableState.sort.column;
      switch (column) {
        case "file":
          const aFile = a.Key.split("/")[a.Key.split("/").length - 1];
          const bFile = b.Key.split("/")[b.Key.split("/").length - 1];
          return (asc ? aFile : bFile).localeCompare(asc ? bFile : aFile);
        case "name":
          return (asc ? a.name : b.name).localeCompare(asc ? b.name : a.name);
        case "mimetype":
          return (asc ? a.mimetype : b.mimetype).localeCompare(
            asc ? b.mimetype : a.mimetype
          );
        case "date":
          return (
            new Date(asc ? a.timestamp : b.timestamp) -
            new Date(asc ? b.timestamp : a.timestamp)
          );
        case "size":
          return (asc ? a.size : b.size) - (asc ? b.size : a.size);
        default:
          console.log("oob sort", column);
      }
    });

  useEffect(() => {
    Array.from(document.querySelectorAll(".datatable-header tr th")).forEach(
      (header, index) => {
        if (index)
          header.addEventListener("click", () =>
            clickSort(columns[index - 1].field)
          );
      }
    );

    return () =>
      Array.from(document.querySelectorAll(".datatable-header tr th")).forEach(
        (header, index) => {
          if (index)
            header.removeEventListener("click", () =>
              clickSort(columns[index - 1].field)
            );
        }
      );
  }, []);

  useEffect(() => {
    if (confirmDeleteModalShown) setConfirmDeleteModalShown(false);
  }, [fileTableState.filesSelected.length]);

  useEffect(() => {
    fileTableStateRef.current = fileTableState;

    setTimeout(() => {
      const fileTableState = fileTableStateRef.current || defaultFileTableState;
      const icon = Array.from(
        document.querySelectorAll(".datatable-sort-icon")
      )[
        columns.indexOf(
          columns.find((c) => c.field === fileTableState.sort.column)
        )
      ];
      if (icon) {
        icon.classList.remove("rotate-0");
        icon.classList.remove("rotate-180");
        icon.classList.add(
          `rotate-${fileTableState.sort.order === "asc" ? "0" : "180"}`
        );
      }
    }, 50);
  }, [JSON.stringify(fileTableState.sort)]);

  const clickSort = (field) => {
    const fileTableState = fileTableStateRef.current || defaultFileTableState;
    setFileTableState({
      ...fileTableState,
      sort: {
        column: field,
        order:
          fileTableState.sort.column === field
            ? fileTableState.sort.order === "asc"
              ? "desc"
              : "asc"
            : fileTableState.sort.order,
      },
    });
  };

  if (!files.length && !loadingFiles)
    return (
      <motion.h5
        transition={t.transition}
        initial={t.fade_out}
        animate={t.normalize}
        exit={t.fade_out_scale_1}
        className="display-6 text-center"
      >
        No files found for this instance
      </motion.h5>
    );
  return (
    <motion.div
      transition={t.transition}
      initial={t.fade_out}
      animate={t.normalize}
      exit={t.fade_out_scale_1}
    >
      <ConfirmDeleteModal
        modalShown={confirmDeleteModalShown}
        toggleShowModal={() =>
          setConfirmDeleteModalShown(!confirmDeleteModalShown)
        }
        files={files.filter((file) =>
          fileTableState.filesSelected.find((_id) => file._id === _id)
        )}
        fileRemoveSuccess={fileRemoveSuccess}
      />
      <Collapse in={fileTableState.filesSelected.length}>
        <div className="mb-2 d-flex align-items-center">
          <MDBBtn
            color="link"
            className="text-danger"
            rippleColor="danger"
            onClick={() => setConfirmDeleteModalShown(true)}
          >
            <i className="fas fa-fire me-2" />
            Delete Permanently ({fileTableState.filesSelected.length} Item
            {fileTableState.filesSelected.length === 1 ? " " : "s "}
            Selected)
          </MDBBtn>
        </div>
      </Collapse>
      <MDBDatatable
        id="file-datatable"
        key={fileTableState.reset}
        data={{
          columns,
          rows: files.map((file) => ({
            ...file,
            file: (
              <div
                title={file.Key.split("/")[file.Key.split("/").length - 1]}
                className="d-flex align-items-center w-100 cursor-pointer"
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_JIZZER_BUCKET_HOST + "/" + file.Key
                  )
                }
                onKeyDown={() =>
                  window.open(
                    process.env.REACT_APP_JIZZER_BUCKET_HOST + "/" + file.Key
                  )
                }
              >
                <div
                  style={{
                    height: "40px",
                    minHeight: "40px",
                    width: "40px",
                    minWidth: "40px",
                  }}
                  className="d-flex align-items-center"
                >
                  <img
                    style={{ maxHeight: "40px", maxWidth: "40px" }}
                    src={
                      file.mimetype.includes("audio")
                        ? "/assets/images/speaker.svg"
                        : process.env.REACT_APP_JIZZER_BUCKET_HOST +
                          "/" +
                          file.thumbnail
                    }
                  />
                </div>
                <div className="ms-1">
                  {file.Key.split("/")[file.Key.split("/").length - 1]}
                </div>
              </div>
            ),
            name: (
              <span
                style={{
                  height: "40px",
                  minHeight: "40px",
                  width: "40px",
                  minWidth: "40px",
                }}
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_JIZZER_BUCKET_HOST + "/" + file.Key
                  )
                }
                onKeyDown={() =>
                  window.open(
                    process.env.REACT_APP_JIZZER_BUCKET_HOST + "/" + file.Key
                  )
                }
                title={file.name}
                className="cursor-pointer d-flex align-items-center"
              >
                {file.name}
              </span>
            ),
            mimetype: (
              <div
                style={{
                  height: "40px",
                  minHeight: "40px",
                  width: "40px",
                  minWidth: "40px",
                }}
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_JIZZER_BUCKET_HOST + "/" + file.Key
                  )
                }
                onKeyDown={() =>
                  window.open(
                    process.env.REACT_APP_JIZZER_BUCKET_HOST + "/" + file.Key
                  )
                }
                title={file.mimetype}
                className="text-capitalize cursor-pointer d-flex align-items-center"
              >
                {file.mimetype.split("/")[0]}
              </div>
            ),
            date: (
              <span
                style={{
                  height: "40px",
                  minHeight: "40px",
                  width: "40px",
                  minWidth: "40px",
                }}
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_JIZZER_BUCKET_HOST + "/" + file.Key
                  )
                }
                onKeyDown={() =>
                  window.open(
                    process.env.REACT_APP_JIZZER_BUCKET_HOST + "/" + file.Key
                  )
                }
                title={new Date(file.timestamp).toISOString()}
                className="cursor-pointer d-flex align-items-center"
              >
                {h.makeDateHR(new Date(file.timestamp))}
              </span>
            ),
            size: (
              <span
                style={{
                  height: "40px",
                  minHeight: "40px",
                  width: "40px",
                  minWidth: "40px",
                }}
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_JIZZER_BUCKET_HOST + "/" + file.Key
                  )
                }
                onKeyDown={() =>
                  window.open(
                    process.env.REACT_APP_JIZZER_BUCKET_HOST + "/" + file.Key
                  )
                }
                title={`${file.size} bytes`}
                className="cursor-pointer d-flex align-items-center"
              >
                {(Number(file.size) / (1024 * 1024)).toFixed(2)}
              </span>
            ),
          })),
        }}
        entries={25}
        fixedHeader={true}
        multi
        selectable
        onSelectRow={(rows) =>
          setFileTableState({
            ...fileTableState,
            filesSelected: rows.map((row) => row._id),
          })
        }
        isLoading={loadingFiles}
        search
        sm
        fullPagination
        hover
        loadingMessage="Loading Files"
      />
    </motion.div>
  );
};

export default Files;
