import React from "react";
import { connect } from "react-redux";
import { AnimatePresence } from "framer-motion";
import { Switch, Route, StaticRouter } from "react-router-dom";
import LiveNav from "./live/LiveNav";
import LiveView from "./live/LiveView";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import Monitor from "./live/Monitor";
import Banned from "./live/Banned";

const Live = ({
  setFormValuesParent,
  errorPage,
  clearErrorPage,
  status,
  formValuesParent,
  userInfo,
  liveView,
  reset,
  setLiveView,
  statistics,
  files,
  loadingFiles,
  fileTableState,
  setFileTableState,
  fileTableRefresh,
  fileRemoveSuccess,
  messages,
  messagesLoaded,
}) => {
  const props = {
    setFormValuesParent,
    errorPage,
    clearErrorPage,
    status,
    formValuesParent,
    userInfo,
    liveView,
    reset,
    setLiveView,
    statistics,
    files,
    loadingFiles,
    fileTableState,
    setFileTableState,
    fileTableRefresh,
    fileRemoveSuccess,
    messages,
    messagesLoaded,
  };
  const instance = userInfo.instances.find((i) => i.service === "jizzer");

  return (
    <MDBContainer fluid className="h-100">
      <MDBRow id="row-live-content" className="h-100">
        <MDBCol xl={9}>
          {instance.banned ? (
            <Banned />
          ) : (
            <StaticRouter location={liveView}>
              <AnimatePresence exitBeforeEnter>
                <Switch key={liveView}>
                  <Route exact path=":view">
                    <LiveView {...props} />
                  </Route>
                </Switch>
              </AnimatePresence>
            </StaticRouter>
          )}
        </MDBCol>
        <MDBCol xl={3}>
          <MDBContainer className="px-0" fluid>
            <MDBRow id="row-live-indicators">
              <MDBCol className="mb-3">
                <LiveNav view={liveView} setView={setLiveView} />
              </MDBCol>
              <MDBCol className="mb-3">
                <Monitor {...props} />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

export default connect(mapStateToProps, {})(Live);
