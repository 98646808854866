import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { connect } from "react-redux";
import t from "../utilities/transitions";
import { MDBContainer, MDBSelect } from "mdb-react-ui-kit";
import {
  StaticRouter,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import FeedbackForm from "./feedback/FeedbackForm";
import {
  general_feedback_schema,
  bug_schema,
  request_schema,
  report_abuse_schema,
} from "../utilities/validations";
import axios from "axios";

const options = [
  {
    label: "General Feedback",
    id: "general",
    fields: [
      {
        text: "Category",
        id: "app",
        options: [
          {
            label: "Pigger - Beta",
            id: "jizzer-beta",
          },
          {
            label: "Carbon Valley",
            id: "carbon-valley",
          },
        ],
        skipValidity: true,
      },
      {
        text: "Your Name",
        id: "name",
      },
      {
        text: "Email",
        id: "email",
      },
      {
        text: "Subject",
        id: "subject",
      },
      {
        text: "Feedback",
        id: "feedback",
      },
    ],
  },
  {
    label: "Bug Report",
    id: "bug",
    fields: [
      {
        text: "App",
        id: "app",
        options: [
          {
            label: "Pigger - Beta",
            id: "jizzer-beta",
          },
          {
            label: "Carbon Valley",
            id: "carbon-valley",
          },
        ],
        skipValidity: true,
      },
      {
        text: "Your Name",
        id: "name",
      },
      {
        text: "Email",
        id: "email",
      },
      {
        text: "Subject",
        id: "subject",
      },
      {
        text: "Describe Bug in Detail",
        id: "feedback",
      },
    ],
  },
  {
    label: "Request",
    id: "request",
    fields: [
      {
        text: "App",
        id: "app",
        options: [
          {
            label: "Pigger - Beta",
            id: "jizzer-beta",
          },
          {
            label: "Carbon Valley",
            id: "carbon-valley",
          },
        ],
        skipValidity: true,
      },
      {
        text: "Your Name",
        id: "name",
      },
      {
        text: "Email",
        id: "email",
      },
      {
        text: "Subject",
        id: "subject",
      },
      {
        text: "Request",
        id: "feedback",
      },
    ],
  },
  {
    label: "Report Abuse",
    id: "report",
    fields: [
      {
        text: "Your Email",
        id: "email",
      },
      {
        text: "Link to Abuse",
        id: "link",
      },
      {
        text: "Nature of Abuse",
        id: "nature",
        options: [
          {
            label: "Unmoderated Instance",
            id: "unmoderated",
          },
          {
            label: "Illegal Activity",
            id: "illegal",
          },
          {
            label: "Rampant Copyright Infringement",
            id: "copyright",
          },
          {
            label: "Other",
            id: "other",
          },
        ],
        skipValidity: true,
      },
      {
        text: "Please Explain",
        id: "feedback",
      },
    ],
  },
];

const Feedback = ({ captchaReady }) => {
  const [working, setWorking] = useState(false);
  const [form, setForm] = useState("general");
  const [inputs, setInputs] = useState(
    options
      .find((o) => o.id === "general")
      .fields.map((field) => ({
        id: field.id,
        error: "",
        invalid: true,
        value: field.options ? field.options[0].id : "",
        skipValidity: field.skipValidity,
      }))
  );
  const history = useHistory();
  const location = useLocation();
  /**
   *
   * @param {Select Event} e
   *
   * Triggered when the user selects a different form from the select dropdown
   * Changes the form and resets the inputs
   */
  const changeForm = (e) => {
    setForm(e.value);
    setInputs(
      options
        .find((o) => o.id === e.value)
        .fields.map((field) => ({
          id: field.id,
          error: "",
          invalid: true,
          value: field.options ? field.options[0].id : "",
          skipValidity: field.skipValidity,
        }))
    );
  };

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  const changeHandler = (e) => {
    setInputs((curr) => {
      curr = curr.map((input) => {
        if (input.id === e.target.name)
          return {
            ...input,
            value: e.target.value,
          };
        else return input;
      });

      const data = Object.fromEntries(
        curr.map((input) => [input.id, input.value.trim()])
      );
      try {
        switch (form) {
          case "general":
            general_feedback_schema.validateSync(data, {
              abortEarly: false,
            });
            break;
          case "bug":
            bug_schema.validateSync(data, {
              abortEarly: false,
            });
            break;
          case "request":
            request_schema.validateSync(data, {
              abortEarly: false,
            });
            break;
          case "report":
            report_abuse_schema.validateSync(data, {
              abortEarly: false,
            });
          default:
            console.log("oob form", form);
        }

        curr = curr.map((input) => {
          if (!input.skipValidity)
            document.getElementById(input.id).setCustomValidity("");
          return {
            ...input,
            invalid: false,
            error: "",
          };
        });
      } catch (err) {
        let errorsAdded = [];
        curr = curr.map((input) => {
          if (
            err.inner.find((error) => error.path === input.id) &&
            errorsAdded.indexOf(input.id) === -1
          ) {
            errorsAdded.push(input.id);
            return {
              ...input,
              invalid: true,
              error: err.inner.find((error) => error.path === input.id).message,
            };
          } else
            return {
              ...input,
              invalid: false,
              error: "",
            };
        });
      }

      curr.forEach((input) => {
        if (!input.skipValidity) {
          if (input.invalid)
            document
              .getElementById(input.id)
              .setCustomValidity(input.error || "Invalid");
          else document.getElementById(input.id).setCustomValidity("");
        }
      });

      return curr;
    });
  };

  /**
   * Executes a captcha challenge and generates a key a key
   * Will hang until connected to captcha servers
   */
  const getRecaptcha = () =>
    new Promise(async (resolve, reject) => {
      if (String(process.env.REACT_APP_DEV) === "true")
        return resolve(process.env.REACT_APP_DEV_CAPTCHA_KEY);
      if (captchaReady)
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
          .then(resolve)
          .catch((err) => {
            console.log(err);
            alert("Human verification failed. Refresh the page and try again.");
            reject();
          });
      else
        setTimeout(async () => {
          const captchaKey = await getRecaptcha();
          resolve(captchaKey);
        }, 500);
    });

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Get Captcha key
   * Make request to server
   * Route to the "Feedback Received" page
   */
  const submit = async () => {
    try {
      document.getElementById("feedback-form").classList.add("was-validated");
      let invalidInputs = inputs.filter(
        (input) => input.invalid && !input.skipValidity
      );
      invalidInputs.forEach((input) =>
        document
          .getElementById(input.id)
          .setCustomValidity(input.error || "Invalid")
      );
      if (!working && !invalidInputs.length) {
        setWorking(true);
        const data = Object.fromEntries(
          inputs.map((input) => [input.id, input.value.trim()])
        );
        try {
          switch (form) {
            case "general":
              general_feedback_schema.validateSync(data, {
                abortEarly: false,
              });
              break;
            case "bug":
              bug_schema.validateSync(data, {
                abortEarly: false,
              });
              break;
            case "request":
              request_schema.validateSync(data, {
                abortEarly: false,
              });
              break;
            case "report":
              report_abuse_schema.validateSync(data, {
                abortEarly: false,
              });
            default:
              console.log("oob form", form);
          }
          const captchaKey = await getRecaptcha();
          const fd = new FormData();
          for (const key in data) {
            fd.append(key, data[key]);
          }
          fd.append("captchaKey", captchaKey);
          fd.append("category", form);
          axios
            .post("/contact/feedback", fd)
            .then(() => history.push("/feedback-received"))
            .catch((err) => {
              setWorking(false);
              console.log(err);
              alert("An error occurred. Please try again later.");
            });
        } catch (err) {
          setWorking(false);
          console.log(err);
          alert("An error occurred. Please try again later");
        }
      }
    } catch (err) {
      console.log("submit error", err);
      setWorking(false);
      alert("An error occurred. Please try again later.");
    }
  };

  return (
    <motion.div
      transition={t.transition}
      initial={history?.location?.state?.enter || t.fade_out}
      animate={t.normalize}
      exit={history?.location?.state?.exit || t.fade_out_scale_1}
      className="pt-5"
    >
      <MDBContainer>
        <MDBSelect
          data={options.map((option) => ({
            text: option.label,
            value: option.id,
            defaultSelected: option.id === form,
          }))}
          onValueChange={changeForm}
          label="Type"
          style={{
            width: "400px",
            maxWidth: "95%",
          }}
        />
        <StaticRouter location={form}>
          <AnimatePresence exitBeforeEnter>
            <Switch key={form}>
              <Route exact path=":form">
                <FeedbackForm
                  form={form}
                  working={working}
                  options={options}
                  fields={options.find((o) => o.id === form).fields}
                  inputs={inputs}
                  changeHandler={changeHandler}
                  submit={submit}
                />
              </Route>
            </Switch>
          </AnimatePresence>
        </StaticRouter>
        <small className="mt-2 d-block mx-auto text-center">
          This site is protected by reCAPTCHA and the Google{" "}
          <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
          <a href="https://policies.google.com/terms"> Terms of Service</a>{" "}
          apply.
        </small>
      </MDBContainer>
    </motion.div>
  );
};

const mapStateToProps = (state) => ({
  captchaReady: state.captchaReady,
});

export default connect(mapStateToProps, {})(Feedback);
