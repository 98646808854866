import React from "react";
import t from "../../../utilities/transitions";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import { select_instance } from "../../../redux/actions";

class JizzerTerms extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.props.resetAnimations();
    if (this.props.userInfo?.instances?.find((i) => i.service === "jizzer"))
      this.props.select_instance("jizzer");
  }

  render() {
    if (this.props.userInfo?.instances?.find((i) => i.service === "jizzer"))
      return <></>;
    return (
      <motion.div
        className="pt-4"
        transition={t.transition}
        initial={this.props.enter}
        animate={t.normalize}
        exit={this.props.exit}
      >
        <MDBContainer>
          <MDBBtn
            color="link"
            style={{
              color: "#1976d2",
            }}
            rippleColor="#1976d2"
            size="lg"
            onClick={() => this.props.changePage("list")}
          >
            <i className="fas fa-chevron-left me-2" />
            Products
          </MDBBtn>
          <h5 className="text-center display-6 m-0">
            Please Accept Our Terms of Use
          </h5>
          <hr />
          <MDBBtn
            size="lg"
            color="success"
            onClick={() => this.props.changePage("jizzer-payment")}
            className="d-block ms-auto"
          >
            Accept and Continue
            <i className="fas fa-chevron-right ms-2" />
          </MDBBtn>
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { select_instance })(JizzerTerms);
