import React from "react";
import List from "./List";
import Jizzer from "./Jizzer";

class InstanceView extends React.Component {
  constructor(props) {
    super();
    this.view = props.view;
  }

  render() {
    switch (this.view) {
      case "list":
        return <List setView={this.props.setView} loaded={this.props.loaded} />;
      case "jizzer":
        return <Jizzer setView={this.props.setView} />;
      default:
        console.log("oob view", this.view);
    }
  }
}

export default InstanceView;
