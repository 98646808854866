import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import { set_user } from "../redux/actions";
import { connect } from "react-redux";
import { change_password_uuid_schema } from "../utilities/validations";
import t from "../utilities/transitions";
import h from "../utilities/helpers";
import {
  MDBContainer,
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBBtn,
} from "mdb-react-ui-kit";
import Spinner from "../components/Spinner";
import LinearProgress from "@mui/material/LinearProgress";

/**
 * This is the page that the user hits when they click the password reset link that was emailed to them
 */

const fields = [
  {
    id: "password1",
    text: "New Password",
  },
  {
    id: "password2",
    text: "Re-enter password",
  },
];

const Resets = ({ set_user }) => {
  const [working, setWorking] = useState(false);
  const [inputs, setInputs] = useState(
    fields.map((field) => ({
      id: field.id,
      error: "",
      invalid: true,
      value: "",
    }))
  );
  console.log(inputs);
  const [loaded, setLoaded] = useState(false);
  const [expired, setExpired] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();

  useEffect(() => {
    load();
  }, []);

  /**
   * Load password reset data
   * Run blank change handler
   */
  const load = () =>
    axios
      .get("/auth/reset-request/" + id)
      .then((res) => {
        setLoaded(true);
        if (res.data.notFound) setNotFound(true);
        if (res.data.expired) setExpired(true);
        if (!expired && !notFound)
          changeHandler({
            target: {
              name: "",
            },
          });
      })
      .catch((err) => {
        console.log("load error", err);
        setTimeout(load, 1000);
      });

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Make request to server
   * Set user in application state
   * Route to user's profile page
   */
  const submit = () => {
    document.getElementById("form").classList.add("was-validated");
    let invalidInputs = inputs.filter((input) => input.invalid);
    invalidInputs.forEach((input) => {
      const element = document.getElementById(input.id);
      if (element) element.setCustomValidity(input.error || "Invalid");
    });
    if (!working && !invalidInputs.length) {
      setWorking(true);
      const data = Object.fromEntries(
        inputs.map((input) => [input.id, input.value])
      );
      try {
        change_password_uuid_schema.validateSync(data, {
          abortEarly: false,
        });
        const fd = new FormData();
        for (const key in data) {
          fd.append(key, data[key]);
        }
        fd.append("uuid", id);

        axios
          .post("/auth/change-password", fd)
          .then((res) => {
            if (res.data.error) {
              setWorking(false);
              alert(res.data.error);
            } else {
              set_user(res.data);
              history.push("/dashboard");
            }
          })
          .catch((err) => {
            setWorking(false);
            console.log(err.response);
            alert("An error occurred. Please try again later");
          });
      } catch (err) {
        setWorking(false);
        console.log(err);
        alert("An error occurred. Please try again later");
      }
    }
  };

  const pressEnter = (e) => {
    /**
     * Submit the form if the user presses the enter key while in one of the inputs
     */
    if (e.key === "Enter") document.getElementById("form").requestSubmit();
  };

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  const changeHandler = (e) => {
    setInputs((curr) => {
      curr = curr.map((input) => {
        if (input.id === e.target.name)
          return {
            ...input,
            value: e.target.value,
          };
        else return input;
      });

      const data = Object.fromEntries(
        curr.map((input) => [input.id, input.value])
      );
      console.log("data", data);
      try {
        change_password_uuid_schema.validateSync(data, {
          abortEarly: false,
        });
        curr = curr.map((input) => {
          const element = document.getElementById(input.id);
          if (element) element.setCustomValidity("");
          return {
            ...input,
            invalid: false,
            error: "",
          };
        });
      } catch (err) {
        let errorsAdded = [];
        curr = curr.map((input) => {
          if (
            err.inner.find((error) => error.path === input.id) &&
            errorsAdded.indexOf(input.id) === -1
          ) {
            errorsAdded.push(input.id);
            return {
              ...input,
              invalid: true,
              error: err.inner.find((error) => error.path === input.id).message,
            };
          } else
            return {
              ...input,
              invalid: false,
              error: "",
            };
        });
      }

      curr.forEach((input) => {
        const element = document.getElementById(input.id);
        if (element) {
          if (input.invalid) element.setCustomValidity(input.error);
          else element.setCustomValidity("");
        }
      });

      return curr;
    });
  };

  h.floatLabels();

  return (
    <motion.div
      transition={t.transition}
      exit={history?.location?.state?.exit || t.fade_out_scale_1}
      animate={t.normalize}
      initial={history?.location?.state?.enter || t.fade_out}
    >
      <MDBContainer className="mt-5">
        {!loaded ? (
          <>
            <h5 className="mb-4 text-center display-6">Loading</h5>
            <LinearProgress />
          </>
        ) : (
          <>
            {expired ? (
              <motion.h5
                className="display-6 text-center"
                transition={t.transition}
                exit={history?.location?.state?.exit || t.fade_out_scale_1}
                animate={t.normalize}
                initial={history?.location?.state?.enter || t.fade_out}
              >
                Reset link has expired
              </motion.h5>
            ) : (
              <>
                {notFound ? (
                  <motion.h5
                    className="display-6 text-center"
                    transition={t.transition}
                    exit={history?.location?.state?.exit || t.fade_out_scale_1}
                    animate={t.normalize}
                    initial={history?.location?.state?.enter || t.fade_out}
                  >
                    Reset link not found
                  </motion.h5>
                ) : (
                  <motion.div
                    transition={t.transition}
                    exit={history?.location?.state?.exit || t.fade_out_scale_1}
                    animate={t.normalize}
                    initial={history?.location?.state?.enter || t.fade_out}
                  >
                    <h1 className="display-6 text-center">Set Password</h1>
                    <hr></hr>
                    <div className="mx-auto mt-2 form-containers">
                      <MDBValidation
                        method="dialog"
                        id="form"
                        onSubmit={submit}
                      >
                        {fields.map((i) => (
                          <MDBValidationItem
                            key={i.id}
                            className="pb-4"
                            feedback={
                              inputs.find((input) => input.id === i.id).error
                            }
                            invalid={true}
                          >
                            <MDBInput
                              name={i.id}
                              onChange={changeHandler}
                              id={i.id}
                              label={i.text}
                              size="lg"
                              className={
                                !inputs.find((input) => input.id === i.id)
                                  .invalid
                                  ? "mb-0"
                                  : 0
                              }
                              type="password"
                              onKeyPress={pressEnter}
                            />
                          </MDBValidationItem>
                        ))}
                      </MDBValidation>
                      <div className="d-grid gap-2 mb-4">
                        {working ? (
                          <MDBBtn disabled color="primary" size="lg">
                            <Spinner size="sm" className="me-2" />
                            Saving
                          </MDBBtn>
                        ) : (
                          <MDBBtn onClick={submit} color="primary" size="lg">
                            <i className="fas fa-save me-2"></i>Save Changes
                          </MDBBtn>
                        )}
                      </div>
                    </div>
                  </motion.div>
                )}
              </>
            )}
          </>
        )}
      </MDBContainer>
    </motion.div>
  );
};

export default connect(() => ({}), { set_user })(Resets);
