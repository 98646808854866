import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useHistory, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import t from "../utilities/transitions";
import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import LinearProgress from "@mui/material/LinearProgress";

/**
 * This is the page the user hits when they cancel a password reset request
 */

const Cancel = ({}) => {
  const [cancelled, setCancelled] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    axios
      .get("/auth/cancel", {
        uuid: params.id,
      })
      .then(() => {
        setCancelled(true);
      })
      .catch((err) => {
        console.log(err);
        alert("An error occurred. Please try again later");
      });
  }, []);

  return (
    <motion.div
      transition={t.transition}
      exit={history?.location?.state?.exit || t.fade_out_scale_1}
      animate={t.normalize}
      initial={history?.location?.state?.enter || t.fade_out}
    >
      <MDBContainer>
        {cancelled ? (
          <>
            <h1 className="display-6 mt-5 text-center">
              Password change request has been canceled.
            </h1>
            <div className="d-flex justify-content-center"></div>
            <MDBBtn
              onClick={() => history.push("/")}
              color="primary"
              size="lg"
              className="mt-4 mx-auto d-block"
            >
              <i className="fas fa-home me-2"></i>Return
            </MDBBtn>
          </>
        ) : (
          <>
            <h1 className="text-center my-4 display-6">Cancelling</h1>
            <LinearProgress />
          </>
        )}
      </MDBContainer>
    </motion.div>
  );
};

export default Cancel;
