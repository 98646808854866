import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBContainer,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import { set_user } from "../redux/actions";
import Spinner from "./Spinner";
import Form from "./loginModal/Form";
import { StaticRouter, Switch, Route } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import t from "../utilities/transitions";

class LoginModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether the user is in the process of logging in/registering
       * formSelected: String - "login" | "create" - Login or create account
       */
      working: false,
      formSelected: "login",
    };
  }

  /**
   * When the user resizes the page, reset the modal body height
   */
  componentDidMount() {
    window.addEventListener("resize", this.setModalBodyHeight);
  }

  /**
   * When modal is displayed, set body height
   * Add mousedown event listener for closeModal method
   */
  componentDidUpdate(prevProps) {
    if (prevProps.modalShown !== this.props.modalShown) {
      this.setModalBodyHeight();
      if (this.props.modalShown)
        document.addEventListener("mousedown", this.closeModal);
    }
  }

  /**
   * remove event listeners
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.setModalBodyHeight);
    document.removeEventListener("mousedown", this.closeModal);
  }

  /**
   * Triggered by the mousedown event
   *
   * Close modal only if the user clicks one of the close buttons or the actual backdrop
   * Prevents bug that closes the modal when clicking anywhere while the modal is growing or shrinking
   */
  closeModal = (e) => {
    if (
      e.target.classList.contains("modal") &&
      !this.state.working &&
      this.props.modalShown
    ) {
      this.props.toggleShowModal();
      document.removeEventListener("mousedown", this.closeModal);
    }
  };

  /**
   * Triggered when the user changes the form
   * Changes the form, then sets the modal body height
   */
  changeForm = () => {
    if (!this.state.working)
      this.setState(
        {
          ...this.state,
          formSelected:
            this.state.formSelected === "login" ? "create" : "login",
        },
        () => setTimeout(this.setModalBodyHeight, 333)
      );
  };

  setWorking = (option) =>
    this.setState({
      ...this.state,
      working: option,
    });

  /**
   * Set the body height to that of its immediate child
   * Causes the body to smoothly grow or shrink instead of pop
   */
  setModalBodyHeight = () =>
    (document.getElementById("login-modal-body").style.height = `${
      document.getElementById("login-modal-body-child").clientHeight
    }px`);

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal open={this.props.modalShown} staticBackdrop tabIndex="-1">
            <MDBModalDialog
              size={
                this.props.screenDimensions.width >
                this.props.screenDimensions.modalBreak
                  ? "xl"
                  : "fullscreen"
              }
            >
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>
                    {this.state.formSelected === "login" ? (
                      <motion.div
                        className="m-0"
                        transition={t.transition}
                        exit={t.fade_out_minimize}
                        animate={t.normalize}
                        initial={t.fade_out_minimize}
                      >
                        Login to {this.props.tempAction.label}
                      </motion.div>
                    ) : (
                      <motion.p
                        className="m-0"
                        transition={t.transition}
                        exit={t.fade_out_minimize}
                        animate={t.normalize}
                        initial={t.fade_out_minimize}
                      >
                        Create Account
                      </motion.p>
                    )}
                  </MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={this.props.toggleShowModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <div
                  id="login-modal-body"
                  className="transition-25 overflow-y-hidden"
                >
                  <MDBModalBody id="login-modal-body-child">
                    <StaticRouter location={this.state.formSelected}>
                      <AnimatePresence exitBeforeEnter>
                        <Switch key={this.state.formSelected}>
                          <Route exact path=":form">
                            <Form
                              form={this.state.formSelected}
                              setSubmit={(f) => (this.submit = f)}
                              modalShown={this.props.modalShown}
                              setShowModal={this.props.setShowModal}
                              toggleShowModal={this.props.toggleShowModal}
                              setWorking={this.setWorking}
                              key={this.state.formSelected}
                            />
                          </Route>
                        </Switch>
                      </AnimatePresence>
                    </StaticRouter>
                    <MDBContainer>
                      <small className="mt-2 d-block mx-auto text-center">
                        This site is protected by reCAPTCHA and the Google{" "}
                        <a href="https://policies.google.com/privacy">
                          {" "}
                          Privacy Policy
                        </a>{" "}
                        and
                        <a href="https://policies.google.com/terms">
                          {" "}
                          Terms of Service
                        </a>{" "}
                        apply.
                      </small>
                    </MDBContainer>
                  </MDBModalBody>
                </div>
                <MDBModalFooter className="d-flex justify-content-between">
                  <MDBBtn onClick={this.changeForm} color="primary">
                    {this.state.formSelected === "login" ? (
                      <>
                        <i className="fas fa-user-plus me-2"></i>
                        Create Account
                      </>
                    ) : (
                      <>
                        <i className="fas fa-sign-in-alt me-2"></i>
                        Login
                      </>
                    )}
                  </MDBBtn>
                  <div className="d-flex">
                    {this.state.working ? (
                      <MDBBtn
                        rippleColor="light"
                        color="success"
                        disabled
                        className="me-2"
                      >
                        <Spinner size="sm" className="me-2" />
                        Working
                      </MDBBtn>
                    ) : (
                      <MDBBtn
                        onClick={this.submit}
                        rippleColor="light"
                        color="success"
                        className="me-2"
                      >
                        <i className="fas fa-paper-plane me-2"></i>Submit
                      </MDBBtn>
                    )}
                    <MDBBtn
                      className="bg-gray"
                      onClick={this.props.toggleShowModal}
                    >
                      Close
                    </MDBBtn>
                  </div>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { set_user })(LoginModal);
