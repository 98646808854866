import React from "react";
import { connect } from "react-redux";
import { MDBContainer } from "mdb-react-ui-kit";
import { StaticRouter, Switch, Route, Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import InstanceView from "./instances_child/InstanceView";
import LinearProgress from "@mui/material/LinearProgress";
import { motion } from "framer-motion";
import t from "../../../utilities/transitions";
import { select_instance } from "../../../redux/actions";

class Instances_Child extends React.Component {
  constructor(props) {
    super();
    this.state = {
      /**
       * view: String - The current view
       * notificationIcon: JSX - The notification icon
       * notificationText: String - The notification text
       */
      view: props.instanceSelected ? props.instanceSelected : "list",
      notificationIcon: <></>,
      notificationText: "",
    };
  }

  componentDidMount() {
    this.props.select_instance(false);
  }

  /**
   *
   * @param {String} view - A view or page
   *
   * Sets the view
   */
  setView = (view) =>
    this.setState({
      ...this.state,
      view: view,
    });

  render() {
    return (
      <div
        id="toast-trigger-instances-child-container"
        className="h-100 d-flex flex-column"
      >
        {this.props.statistics ? (
          <MDBContainer fluid className="fg-1 px-0 dashboard-containers">
            {this.props.userInfo.instances.length ? (
              <motion.div
                transition={t.transition}
                initial={t.fade_out}
                animate={t.normalize}
                exit={t.fade_out_scale_1}
                className="row h-100 overflow-y-auto overflow-x-hidden mx-0 dashboard-containers"
              >
                <StaticRouter location={this.state.view}>
                  <AnimatePresence exitBeforeEnter>
                    <Switch key={this.state.view}>
                      <Route exact path=":view">
                        <InstanceView
                          view={this.state.view}
                          setView={this.setView}
                          loaded={this.props.statistics}
                        />
                      </Route>
                    </Switch>
                  </AnimatePresence>
                </StaticRouter>
              </motion.div>
            ) : (
              <motion.h5
                transition={t.transition}
                initial={t.fade_out}
                animate={t.normalize}
                exit={t.fade_out_scale_1}
                className="text-center display-6 mt-5"
              >
                You have no instances.{" "}
                <Link to="/products" className="links-generic">
                  Visit the Products page to get started.
                </Link>
              </motion.h5>
            )}
          </MDBContainer>
        ) : (
          <MDBContainer className="mt-5">
            <LinearProgress />
          </MDBContainer>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { select_instance })(Instances_Child);
