import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import h from "../../../../../utilities/helpers";

class ViewNoteModal extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const note = this.props.note;
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            open={this.props.modalShown}
            staticBackdrop
            onClosePrevented={this.props.toggleShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog
              size={
                this.props.screenDimensions.width >
                this.props.screenDimensions.modalBreak
                  ? "xl"
                  : "fullscreen"
              }
            >
              {note ? (
                <MDBModalContent>
                  <MDBModalHeader>
                    <MDBModalTitle>Release Notes</MDBModalTitle>
                    <MDBBtn
                      className="btn-close"
                      color="none"
                      onClick={this.props.toggleShowModal}
                    ></MDBBtn>
                  </MDBModalHeader>
                  <MDBModalBody>
                    <p className="text-blusteel m-0">
                      {h.makeDateHR(new Date(note.timestamp))}
                    </p>
                    <p className="text-blusteel">
                      {h.getTimeHR(new Date(note.timestamp))}
                    </p>
                    <div dangerouslySetInnerHTML={{ __html: note.html }}></div>
                  </MDBModalBody>
                  <MDBModalFooter>
                    <MDBBtn
                      className="bg-gray"
                      onClick={this.props.toggleShowModal}
                    >
                      Close
                    </MDBBtn>
                  </MDBModalFooter>
                </MDBModalContent>
              ) : (
                <></>
              )}
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {})(ViewNoteModal);
