import React from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import t from "../../../../../../utilities/transitions";
import {
  MDBCard,
  MDBCardBody,
  MDBListGroup,
  MDBListGroupItem,
} from "mdb-react-ui-kit";
import Spinner from "../../../../../../components/Spinner";

const Monitor = ({ status, userInfo }) => {
  const getStatus = () => {
    const instance = userInfo.instances.find((i) => i.service === "jizzer");
    if (instance.banned) return <span className="text-danger">Banned</span>;
    if (instance.disabled) return <span className="text-danger">Disabled</span>;
    if (instance.readOnly)
      return <span className="text-primary">Read-Only</span>;
    return <span className="text-success">Live</span>;
  };

  const getBottomText = () => {
    const instance = userInfo.instances.find((i) => i.service === "jizzer");
    if (!instance.banned && !instance.disabled && !instance.readOnly)
      return (
        <p className="mt-4 fw-bold mb-0 w-max-content">
          <a
            target="_blank"
            href={`https://${instance.domain.replaceAll("⚓", ".")}`}
          >
            Visit Site
            <i className="ms-2 fas fa-external-link-alt" />
          </a>
        </p>
      );
  };

  return (
    <motion.div
      transition={t.transition}
      initial={t.fade_out}
      animate={t.normalize}
      exit={t.fade_out_scale_1}
      className="h-100"
    >
      <MDBCard className="h-100">
        <MDBCardBody className="h-100">
          {status.length ? (
            <MDBListGroup light>
              {status.map((s) => {
                let statusIcon;
                switch (s.status) {
                  case "inProgress":
                    statusIcon = <Spinner color="primary" size="sm" />;
                    break;
                  case "complete":
                    statusIcon = (
                      <i className="fas fa-check-circle text-success" />
                    );
                    break;
                  default:
                    statusIcon = (
                      <i className="fas fa-times-circle text-danger" />
                    );
                }
                return (
                  <MDBListGroupItem key={s.id}>
                    <motion.div
                      className="d-flex justify-content-between align-items-center"
                      transition={t.transition}
                      initial={t.fade_out}
                      animate={t.normalize}
                      exit={t.fade_out_scale_1}
                    >
                      {s.label}
                      {statusIcon}
                    </motion.div>
                  </MDBListGroupItem>
                );
              })}
            </MDBListGroup>
          ) : (
            <>
              <h5 className="m-0">Status: {getStatus()}</h5>
              {getBottomText()}
            </>
          )}
        </MDBCardBody>
      </MDBCard>
    </motion.div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

export default connect(mapStateToProps, {})(Monitor);
