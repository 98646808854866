import {
  SET_HISTORY,
  SET_USER,
  PURGE_USER,
  SELECT_DASHBOARD_ITEM,
  DASHBOARD_NAV,
  CHANGE_USER_DETAILS,
  RECONNECT_SOCKET,
  SET_TEMP_ACTION,
  CLEAR_TEMP_ACTION,
  TOGGLE_DARK_MODE,
  SET_UNREAD_MESSAGES,
  SET_NOTIFICATION_COUNT,
  SET_REPORT_COUNT,
  SET_OPEN_REPORTS,
  SET_UNREAD_MOD_LOGS,
  SEARCH_CHANGE,
  SET_CAPTCHA_READY,
  SET_VERIFICATION_DETAILS,
  SELECT_INSTANCE,
  SET_DASHBOARD_DATA,
  UPDATE_USER_LIST,
  SET_SERVER_STATUS,
  TERMINATE_DASHBOARD,
  SET_PREFERENCE_STATUS,
  SET_MESSAGE_READ,
  UPDATE_DASHBOARD_DATA,
  LOAD_MORE_MESSAGES,
  SET_JIZZER_PRODUCT_INFO,
  NOTIFY_GLOBAL,
  PAYMENT_SUCCESS,
  NOTIFY,
  REMOVE_NOTIFICATION,
  SET_SCREEN_DIMENSIONS,
} from "./actions";
import { io } from "socket.io-client";
import { json2html } from "html2json";
import React from "react";
import { v4 as uuid } from "uuid";

// Blank user (Not logged in)
const blankUser = {
  userSettings: {
    theme:
      typeof document !== "undefined"
        ? [].slice
            .call(document.head.getElementsByTagName("link"))
            .find((link) => link.href.split("/bootstrap/").length > 1)
            .href.split("/bootstrap/")[1]
            .split(".css")[0] || "default"
        : "default",
  },
  avatar: "blank.webp",
  ban: {
    banned: false,
  },
};

let initialUser = JSON.parse(JSON.stringify(blankUser));

/**
 * If there is metadata present in the document, set the initial user to the object contained in the metadata
 */
if (typeof document !== "undefined") {
  const p = document.getElementById("user-info-server");
  const metadata = JSON.parse(p?.textContent || "{}");
  if (metadata.userInfo) {
    const userInfo = JSON.parse(metadata.textContent);
    initialUser = {
      ...userInfo,
      bio: json2html(userInfo.bio),
      instances: userInfo.instances.map((instance) => {
        // switch (instance.id) {
        //   case "jizzer-beta":
        //     if (instance.preferences && instance.preferences.gigachad)
        return {
          ...instance,
          preferences: instance.preferences
            ? {
                ...instance.preferences,
                gigachad: instance.preferences.gigachad
                  ? {
                      ...instance.preferences.gigachad,
                      bio: json2html(instance.preferences.gigachad.bio),
                    }
                  : instance.preferences.gigachad,
                rules: instance.preferences.rules?.length
                  ? instance.preferences.rules.map((rule) => ({
                      ...rule,
                      html: json2html(rule.html),
                    }))
                  : instance.preferences.rules,
              }
            : instance.preferences,
        };
        //     return instance;
        //   default:
        //     return instance;
        // }
      }),
    };
    metadata.remove();
  }
}

const initialState = {
  /**
   * userInfo: Object - Users document minus sensitive info
   * historyStack: Array - List of routes that the user has been to
   * dashboardItemSelected: String - Dashboard item selected
   * socket: false | Socket.io object
   * socketReconnect: Boolean - When flipped, components that depend on the socket will reset their socket event functions
   * tempAction: false | Object - Temp action data - To be executed when the user logs in
   * captchaReady: Boolean - Whether the Google reCaptcha system has been initialized
   * verificationDetails: false | Object - Details of the most recent email verification email
   * instanceSelected: false | Object - Data of the instance that the user has selected
   * userList: false | Array - List of users requesting access to a resource
   * serverStatus: false | Object - Server data of a selected instance
   * preferenceStatus: Likely no longer used
   * contactFormMessages: Array - List of contact form messages
   * statistics: false | Object - Statistics about a selected instance
   * releaseNotes: Array - List of release notes of updates to products
   * servers: false | Array - List of servers
   * messageCounts: false | Object - The different categories of messages and how many unread remain
   */
  userInfo: initialUser,
  historyStack: ["/null"],
  dashboardItemSelected: "settings",
  socket: "",
  socketReconnect: false,
  tempAction: false,
  captchaReady: false,
  verificationDetails: false,
  instanceSelected: false,
  userList: false,
  serverStatus: false,
  preferenceStatus: false,
  contactFormMessages: [],
  statistics: false,
  releaseNotes: [],
  servers: false,
  messageCounts: false,
  jizzerProductInfo: false,
  globalNotificationIcon: <></>,
  globalNotificationText: "",
  globalNotificationToggle: false,
  notifications: [],
  screenDimensions: {
    width: window?.innerWidth || 0,
    height: window.innerHeight || 0,
    modalBreak: Number(process.env.REACT_APP_MODAL_BREAK) || 800,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HISTORY:
      return {
        ...state,
        history: action.data.history,
        location: action.data.location,
        socket: io("/"),
      };
    case SET_SCREEN_DIMENSIONS:
      return {
        ...state,
        screenDimensions: {
          ...state.screenDimensions,
          ...action.data,
        },
      };
    case NOTIFY_GLOBAL:
      return {
        ...state,
        globalNotificationIcon: action.data.icon,
        globalNotificationText: action.data.text,
        globalNotificationToggle: !state.globalNotificationToggle,
      };
    case NOTIFY:
      const { icon, text, id } = action.data;
      return {
        ...state,
        notifications: [
          {
            icon,
            text,
            id,
          },
          ...state.notifications,
        ],
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.id !== action.data
        ),
      };
    case PAYMENT_SUCCESS:
      return {
        ...state,
        notifications: [
          {
            icon: action.data.icon,
            text: action.data.text,
            id: uuid(),
          },
          ...state.notifications,
        ],
        dashboardItemSelected: "instances",
        instanceSelected: action.data.instance,
      };
    case SET_JIZZER_PRODUCT_INFO:
      return {
        ...state,
        jizzerProductInfo: action.data,
      };
    case LOAD_MORE_MESSAGES:
      return {
        ...state,
        messageCounts: action.data.messageCounts,
        contactFormMessages: [
          ...state.contactFormMessages,
          ...action.data.contactFormMessages,
        ],
      };
    case SET_MESSAGE_READ:
      return {
        ...state,
        contactFormMessages: state.contactFormMessages.map((message) => {
          if (message._id === action.data)
            return {
              ...message,
              read: true,
            };
          return message;
        }),
      };
    case SET_PREFERENCE_STATUS:
      return {
        ...state,
        preferenceStatus: action.data,
      };
    case TERMINATE_DASHBOARD:
      return {
        ...state,
        userList: false,
        serverStatus: false,
        instanceSelected: false,
        dashboardItemSelected: "settings",
      };
    case SET_DASHBOARD_DATA:
      return {
        ...state,
        userList: action.data.userList,
        contactFormMessages: action.data.contactFormMessages,
        statistics: action.data.statistics,
        releaseNotes: action.data.releaseNotes,
        servers: action.data.servers,
        messageCounts: action.data.messageCounts,
      };
    case UPDATE_DASHBOARD_DATA:
      return {
        ...state,
        ...action.data,
      };
    case SET_SERVER_STATUS:
      return {
        ...state,
        serverStatus: action.data,
      };
    case UPDATE_USER_LIST:
      return {
        ...state,
        userList: [
          ...state.userList.filter((u) => u._id !== action.data._id),
          action.data,
        ],
        userInfo:
          state.userInfo._id === action.data._id
            ? {
                ...state.userInfo,
                ...action.data,
              }
            : state.userInfo,
        socket: state.userInfo._id === action.data._id ? io("/") : state.socket,
      };
    case SELECT_INSTANCE:
      return {
        ...state,
        dashboardItemSelected: "instances",
        instanceSelected: action.data,
      };
    case SET_CAPTCHA_READY:
      return {
        ...state,
        captchaReady: true,
      };
    case SET_VERIFICATION_DETAILS:
      return {
        ...state,
        verificationDetails: action.data,
      };
    case SEARCH_CHANGE:
      return {
        ...state,
        searchText: action.data.text,
        sideNavSearchKey:
          action.data.source === "sideNav"
            ? state.sideNavSearchKey
            : !state.sideNavSearchKey,
        searchPageSearchKey:
          action.data.source === "searchPage"
            ? state.searchPageSearchKey
            : !state.searchPageSearchKey,
      };
    case SET_UNREAD_MOD_LOGS:
      return {
        ...state,
        unreadModLogs: action.data,
      };
    case SET_OPEN_REPORTS:
      return {
        ...state,
        openReports: action.data,
      };
    case SET_NOTIFICATION_COUNT:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          notifications: action.data >= 0 ? action.data : 0,
        },
      };
    case SET_REPORT_COUNT:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          unreadReports: action.data >= 0 ? action.data : 0,
        },
      };
    case TOGGLE_DARK_MODE:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          userSettings: {
            ...state.userInfo.userSettings,
            theme:
              state.userInfo.userSettings.theme === "default"
                ? "dark"
                : "default",
          },
        },
      };
    case SET_UNREAD_MESSAGES:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          unreadMessages: action.data,
        },
      };
    case CLEAR_TEMP_ACTION:
      return {
        ...state,
        tempAction: false,
      };
    case SET_TEMP_ACTION:
      console.log("set", action.data);
      return {
        ...state,
        tempAction: action.data,
      };
    case RECONNECT_SOCKET:
      return {
        ...state,
        socketReconnect: !state.socketReconnect,
      };
    case SET_USER:
      console.log("set_user", action.data.userInfo);
      return {
        ...state,
        userInfo: action.data.userInfo,
        socket: io("/"),
      };
    case CHANGE_USER_DETAILS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...action.data,
        },
      };
    case DASHBOARD_NAV:
      return {
        ...state,
        dashboardNav: !state.dashboardNav,
        dashboardItemSelected: action.data,
      };
    case PURGE_USER:
      return {
        ...state,
        userInfo: {
          ...blankUser,
          userSettings: {
            ...blankUser.userSettings,
            theme: state.userInfo.userSettings.theme,
          },
        },
        dashboardItemSelected: "settings",
        socket: io("/"),
        userList: false,
        serverStatus: false,
        preferenceStatus: false,
        contactFormMessages: [],
        statistics: false,
        releaseNotes: [],
        servers: false,
        messageCounts: false,
      };
    case SELECT_DASHBOARD_ITEM:
      return {
        ...state,
        dashboardItemSelected: action.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
