import React, { useEffect, useState } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { StaticRouter, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import t from "../../../../../utilities/transitions";
import { AnimatePresence, motion } from "framer-motion";
import PreferenceView from "./PreferenceView";
import Nav from "./Nav";
import colors from "./formData/colors";
import logos from "./formData/logos";
import settings from "./formData/settings";
import verbiage from "./formData/verbiage";
import h from "../../../../../utilities/helpers";
import {
  preference_schema,
  gigachad_schema,
  gigachad_solo_schema,
} from "../../../../../utilities/validations";

const pages = [colors, logos, settings, verbiage];

const allowedExtensions = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/bmp",
  "image/webp",
  "image/svg+xml",
];

const Preferences = ({
  clearErrorPage,
  errorPage,
  formValuesParent,
  reset,
  setFormValuesParent,
  userInfo,
}) => {
  const instance = userInfo.instances.find((i) => i.service === "jizzer");
  const getDefaultFormValues = (totalRefresh) => {
    if (!totalRefresh && formValuesParent) return formValuesParent;

    if (!instance) return [];
    const allFields = [...colors, ...logos, ...settings, ...verbiage];
    const domainSplit = instance.domain.includes(".")
      ? instance.domain.split(".")
      : instance.domain.split("⚓");
    let defaultValues = {
      domain: instance.domain.split("⚓").join("."),
      use_own_domain: !instance.domain
        ? false
        : !(
            domainSplit[domainSplit.length - 2]?.toLowerCase() ===
              "filepimps" &&
            domainSplit[domainSplit.length - 1]?.toLowerCase() === "com"
          ),
    };

    if (!defaultValues.use_own_domain)
      defaultValues.domain = defaultValues.domain.split(".filepimps.com")[0];
    let values = [
      ...allFields
        .map((field) => field.id)
        .filter((key) => key !== "gigachad")
        .map((key) => {
          return {
            field: key,
            value:
              instance.preferences[key] ||
              defaultValues[key] ||
              allFields.find((field) => field.id === key).defaultValue,
            error: "",
            changeHandler: true,
            tab: pages
              .find((p) => p.find((i) => i.id === key))
              .find((i) => i.id === key).tab,
          };
        }),
      {
        path: `${process.env.REACT_APP_JIZZER_BUCKET_HOST}/${instance.uuid}/icons/favicon-96x96.png`,
        name: "Click to Change",
        file: "",
        field: "icon",
      },
      {
        path: `${process.env.REACT_APP_JIZZER_BUCKET_HOST}/${instance.uuid}/images/blank-avatar.png`,
        name: "Click to Change",
        file: "",
        field: "defaultAvatar",
      },
      {
        path: `${process.env.REACT_APP_JIZZER_BUCKET_HOST}/${instance.uuid}/images/default-background.webp`,
        name: "Click to Change",
        file: "",
        field: "defaultBackground",
      },
      {
        path: `${process.env.REACT_APP_JIZZER_BUCKET_HOST}/${instance.uuid}/assets/images/meltrans.png`,
        name: "Click to Change",
        file: "",
        field: "chadminBadge",
      },
      {
        path: `${process.env.REACT_APP_JIZZER_BUCKET_HOST}/${instance.uuid}/assets/images/thomastrans.png`,
        name: "Click to Change",
        file: "",
        field: "jannyBadge",
      },
      {
        path: `${process.env.REACT_APP_JIZZER_BUCKET_HOST}/${instance.uuid}/assets/images/verifiedlogotrans.png`,
        name: "Click to Change",
        file: "",
        field: "verifiedBadge",
      },
      {
        path: `${process.env.REACT_APP_JIZZER_BUCKET_HOST}/${instance.uuid}/icons/newjizz.png`,
        name: "Click to Change",
        file: "",
        field: "newJizzIcon",
      },
      {
        path: `${process.env.REACT_APP_JIZZER_BUCKET_HOST}/${instance.uuid}/icons/loading-spinner.png`,
        name: "Click to Change",
        file: "",
        field: "loadingSpinner",
      },
      {
        path: instance.preferences?.gigachad?.avatar?.main
          ? `${process.env.REACT_APP_JIZZER_BUCKET_HOST}/${instance.uuid}/images/${instance.preferences.gigachad.avatar.main}`
          : `${process.env.REACT_APP_BUCKET_HOST}/images/gigachad_default.png`,
        name: "Click to Change",
        file: "",
        field: "gigachadAvatar",
      },
      {
        path: instance.preferences?.gigachad?.background?.main
          ? `${process.env.REACT_APP_JIZZER_BUCKET_HOST}/${instance.uuid}/images/${instance.preferences.gigachad.background.main}`
          : `${process.env.REACT_APP_BUCKET_HOST}/images/default-background.webp`,
        name: "Click to Change",
        file: "",
        field: "gigachadBackground",
      },
    ];
    if (
      !values.find((v) => v.field === "gigachad")?.value &&
      instance.status === "in-progress"
    ) {
      values = values.filter((v) => v.field !== "gigachad");
      values.push({
        field: "gigachad",
        value: instance.preferences.gigachad
          ? instance.preferences.gigachad
          : {
              username: "",
              email: "",
              password1: "",
              password2: "",
              displayName: "",
              location: "",
              website: "",
              bio: "",
            },
        error: {
          username: "Please enter a username",
          email: "Please enter an email",
          password1: "Please enter a password",
          password2: "Please enter a password",
          displayName: "Please enter a display name",
          location: "",
          website: "",
        },
        labels: {
          username: "Username",
          email: "Email",
          password1: "Password",
          password2: "Re-enter Password",
          displayName: "Display Name",
          location: "Location",
          website: "Website",
        },
        changeHandler: true,
        tab: "settings",
      });
    }
    return values;
  };

  const [view, setView] = useState("logos");
  const [animations, setAnimations] = useStateWithCallbackLazy({
    enter: t.normalize,
    exit: t.normalize,
  });
  const [formValues, setFormValues] = useState(getDefaultFormValues());
  const [formsSubmitted, setFormsSubmitted] = useState([]);

  useEffect(() => {
    setFormValues(getDefaultFormValues(true));
  }, [reset]);

  useEffect(() => {
    setFormValuesParent(formValues);
    const use_own_domain = formValues.find(
      (value) => value.field === "use_own_domain"
    ).value;
    const domain = formValues.find((value) => value.field === "domain").value;
    if ((use_own_domain && !domain.includes(".")) || !domain.split(".")) {
      setFormValues((curr) =>
        curr.map((fv) => {
          if (fv.field === "domain") fv.error = "Invalid domain";

          return fv;
        })
      );
      const element = document.getElementById("domain");
      if (element?.setCustomValidity)
        element.setCustomValidity("Invalid domain");
    }
  }, [JSON.stringify(formValues)]);

  /**
   * Fired when the user clicks the Insert Files button
   *
   * Creates a virtual file input
   * Adds a change event that sets the selected file into state
   * Appends to document body (necessary for iDevices and possibly others)
   * Clicks the input
   * Removes the input after the files are selected
   */
  const selectFile = (field) => {
    h.hideToolTips();
    let input = document.createElement("input");
    input.type = "file";
    input.style.visibility = "hidden";
    input.style.position = "fixed";
    document.body.appendChild(input);
    input.onchange = (e) => {
      const file = e.target.files[0];
      if (!allowedExtensions.includes(file.type.toLowerCase())) {
        alert(
          `Invalid file format. Allowed: ${allowedExtensions
            .map((e) => "." + e.split("/")[1].split("+")[0])
            .join(", ")}`
        );
      } else if (
        file.size > Number(process.env.REACT_APP_MAX_INDIVIDUAL_FILE_SIZE)
      )
        alert(
          `Max individual file size exceeded. (Max: ${Math.round(
            Number(process.env.REACT_APP_MAX_INDIVIDUAL_FILE_SIZE) /
              (1024 * 1024)
          )}MB)`
        );
      else {
        const data = {
          name: e.target.files[0].name,
          file: e.target.files[0],
          path: URL.createObjectURL(e.target.files[0]),
        };

        setFormValues(
          formValues.map((value) => {
            if (value.field === field) {
              return {
                ...value,
                ...data,
              };
            }
            return value;
          })
        );
      }
    };
    input.click();
  };

  const getChildEnter = (child) => {
    switch (view) {
      case "logos":
        return t.fade_out_right;
      case "verbiage":
        if (child === "logos") return t.fade_out_left;
        return t.fade_out_right;
      case "colors":
        if (child === "settings") return t.fade_out_right;
        return t.fade_out_left;
      case "settings":
        return t.fade_out_left;
      default:
        console.log("oob child enter", view);
    }
  };

  const getChildExit = (child) => {
    switch (view) {
      case "logos":
        return t.fade_out_left;
      case "verbiage":
        if (child === "logos") return t.fade_out_right;
        return t.fade_out_left;
      case "colors":
        if (child === "settings") return t.fade_out_left;
        return t.fade_out_right;
      case "settings":
        return t.fade_out_right;
      default:
        console.log("oob child enter", view);
    }
  };

  const nav = (destination, addDestination) => {
    if (view === "settings") {
      const bio = document.getElementById("input-bio");
      if (bio)
        changeHandler({
          target: {
            name: "gigachad.bio",
            value: h.sanitizeHTML(bio.innerHTML),
          },
        });
    }

    const newFormsSubmitted = [...formsSubmitted, view];
    if (addDestination) newFormsSubmitted.push(destination);
    setFormsSubmitted([...new Set(newFormsSubmitted)]);
    setAnimations(
      {
        enter: getChildEnter(destination),
        exit: getChildExit(destination),
      },
      () => setView(destination)
    );
  };

  useEffect(() => {
    if (errorPage) {
      if (errorPage !== view) {
        nav(errorPage, true);
      } else {
        const newFormsSubmitted = [...formsSubmitted, view];
        setFormsSubmitted([...new Set(newFormsSubmitted)]);
        const errors = formValues.filter((v) => v.tab === view && v.error);
        for (let e = 0; e < errors.length; e++) {
          const error = errors[e];
          if (error.field === "gigachad") {
            let hit = false;
            Object.keys(error.error).forEach((key) => {
              try {
                if (error.error[key]) {
                  if (!hit) {
                    const element = document.getElementById("gigachad." + key);
                    if (element) element.scrollIntoView();
                    clearErrorPage();
                    hit = true;
                  }
                }
              } catch (err) {
                console.log("error", err);
              }
            });
            if (hit) break;
          } else {
            const element = document.getElementById(error.field);
            if (element) element.scrollIntoView();
            break;
          }
        }
      }
    }
  }, [errorPage]);

  const changeHandler = (e) => {
    setFormValues((curr) => {
      curr = curr.map((item) => {
        if (item.field === e.target.name) {
          item.value = e.target.value;
        } else if (
          item.field.includes("gigachad") &&
          e.target.name.includes("gigachad")
        ) {
          item.value = {
            ...item.value,
            [e.target.name.split("gigachad.")[1]]: e.target.value,
          };
        }

        return item;
      });

      const data = Object.fromEntries(
        curr
          .filter((c) => c.changeHandler)
          .map((input) => {
            if (typeof input.value === "string")
              return [input.field, input.value.trim()];
            return [input.field, input.value];
          })
      );
      try {
        let toValidate = preference_schema;
        if (instance.status === "in-progress")
          toValidate = toValidate.concat(gigachad_solo_schema);
        toValidate.validateSync(data, {
          abortEarly: false,
        });
        curr = curr.map((input) => {
          if (input.field === "gigachad") {
            Object.keys(input.value).forEach((key) => {
              input.error[key] = "";
              const element = document.getElementById("gigachad." + key);
              if (element) element.setCustomValidity("");
            });
          }
          const element = document.getElementById(input.field);
          if (element) element.setCustomValidity("");
          return {
            ...input,
            error: input.field === "gigachad" ? input.error : "",
          };
        });
      } catch (err) {
        let errorsAdded = [];
        curr = curr.map((input) => {
          if (input.field === "gigachad") {
            try {
              gigachad_schema.validateSync(input.value, {
                abortEarly: false,
              });
              Object.keys(input.value).forEach((key) => {
                input.error[key] = "";
                const element = document.getElementById("gigachad." + key);
                if (element) element.setCustomValidity("");
              });
            } catch (err) {
              const gigachadErrorsAdded = [];
              Object.keys(input.value).forEach((key) => {
                input.error[key] = "";
                if (
                  err.inner?.find((error) => error.path === key) &&
                  !gigachadErrorsAdded.includes(key)
                ) {
                  gigachadErrorsAdded.push(key);
                  input.error[key] = err.inner.find(
                    (error) => error.path === key
                  ).message;
                } else input.error[key] = "";
                const element = document.getElementById("gigachad." + key);
                if (element) element.setCustomValidity(input.error[key]);
              });
            }
            return input;
          }
          if (
            err.inner?.find((error) => error.path === input.field) &&
            errorsAdded.indexOf(input.field) === -1
          ) {
            errorsAdded.push(input.field);

            return {
              ...input,
              error: err.inner.find((error) => error.path === input.field)
                .message,
            };
          } else {
            return {
              ...input,
              error: "",
            };
          }
        });
      }

      curr.forEach((input) => {
        const element = document.getElementById(input.field);
        if (element) {
          if (input.error) element.setCustomValidity(input.error || "Invalid");
          else element.setCustomValidity("");
        }
      });
      return curr;
    });
  };

  return (
    <motion.div
      transition={t.transition}
      initial={t.fade_out}
      animate={t.normalize}
      exit={t.fade_out_scale_1}
    >
      <Nav view={view} nav={nav} />
      <StaticRouter location={view}>
        <AnimatePresence exitBeforeEnter>
          <Switch key={view}>
            <Route exact path=":id">
              <PreferenceView
                animations={animations}
                view={view}
                formValues={formValues}
                changeHandler={changeHandler}
                selectFile={selectFile}
                formsSubmitted={formsSubmitted}
                errorPage={errorPage}
                clearErrorPage={clearErrorPage}
              />
            </Route>
          </Switch>
        </AnimatePresence>
      </StaticRouter>
    </motion.div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

export default connect(mapStateToProps, {})(Preferences);
