import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import t from "../utilities/transitions";
import { connect } from "react-redux";
import { MDBContainer, MDBBtn } from "mdb-react-ui-kit";
import { set_verification_details } from "../redux/actions";
import Spinner from "../components/Spinner";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";

const ValidateEmail = ({ set_verification_details, verificationDetails }) => {
  const [working, setWorking] = useState(false);
  const [validationSent, setValidationSent] = useState(false);
  const [reset, setReset] = useState(false);
  const [resetInterval, setResetInterval] = useState(false);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setResetInterval(setInterval(reset, 1000));

    return () => {
      clearInterval(resetInterval);
      set_verification_details(false);
    };
  }, []);

  const toggleReset = () => setReset(!reset);

  const resend = () => {
    if (!working) {
      setWorking(true);
      axios
        .get("/auth/resend-verification", {
          email: verificationDetails.email,
        })
        .then((res) => {
          setValidationSent(true);
          setWorking(false);
          set_verification_details(res.data);
        })
        .catch((err) => {
          console.log(err);
          setWorking(false);
          alert("An error occurred. Please try again later.");
        });
    }
  };

  return (
    <motion.div
      className="my-4"
      transition={t.transition}
      exit={history?.location?.state?.exit || t.fade_out_scale_1}
      animate={t.normalize}
      initial={history?.location?.state?.enter || t.fade_out}
    >
      <MDBContainer>
        <h5 className="text-center display-6">
          An email has been sent from {process.env.REACT_APP_VERIFICATION_EMAIL}{" "}
          to verify your email address. Please click the link in this email to
          verify your account.
        </h5>
        {verificationDetails ? (
          <>
            {new Date(new Date().setMinutes(new Date().getMinutes() - 1)) >
            new Date(verificationDetails.timestamp) ? (
              <motion.div
                transition={t.transition}
                exit={history?.location?.state?.exit || t.fade_out_scale_1}
                animate={t.normalize}
                initial={history?.location?.state?.enter || t.fade_out}
                className="mt-4"
              >
                <h5 className="text-center">Didn't get it?</h5>
                {working ? (
                  <MDBBtn disabled className="d-block mx-auto" color="primary">
                    <Spinner size="sm" className="me-2" />
                    Resending
                  </MDBBtn>
                ) : (
                  <MDBBtn
                    onClick={resend}
                    className="d-block mx-auto"
                    color="primary"
                  >
                    <i className="fas fa-paper-plane me-2"></i>
                    Resend Email
                  </MDBBtn>
                )}
              </motion.div>
            ) : (
              <>
                {validationSent ? (
                  <motion.h5
                    transition={t.transition}
                    exit={history?.location?.state?.exit || t.fade_out_scale_1}
                    animate={t.normalize}
                    initial={history?.location?.state?.enter || t.fade_out}
                    className="text-center mt-4"
                  >
                    Validation resent
                  </motion.h5>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </MDBContainer>
    </motion.div>
  );
};

const mapStateToProps = (state) => ({
  verificationDetails: state.verificationDetails,
});

export default connect(mapStateToProps, { set_verification_details })(
  ValidateEmail
);
