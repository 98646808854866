import React from "react";
import t from "../../../../utilities/transitions";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import { MDBContainer } from "mdb-react-ui-kit";
import JizzerBetaCard from "./list/JizzerBetaCard";

class List extends React.Component {
  constructor(props) {
    super();
    this.state = {
      /**
       * exit: Object - framer-motion exit animation
       */
      exit: props.loaded ? t.fade_out_left : t.fade_out,
    };
  }

  // Set the appropriate exit animation after mounting
  componentDidMount() {
    this.setState({
      ...this.state,
      exit: t.fade_out_left,
    });
  }

  render() {
    return (
      <motion.div
        className="pt-4"
        transition={t.transition}
        initial={this.state.exit}
        animate={t.normalize}
        exit={this.state.exit}
      >
        <MDBContainer fluid>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4 mt-4">
              <JizzerBetaCard setView={this.props.setView} />
            </div>
          </div>
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {})(List);
