import React from "react";
import { Tab, Tabs } from "@mui/material";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";

const LiveNav = ({ view, setView }) => {
  return (
    <MDBCard className="h-100">
      <MDBCardBody className="h-100">
        <Tabs
          orientation="vertical"
          value={view}
          onChange={(e, view) => setView(view)}
          id="nav-live"
        >
          <Tab
            style={{ minHeight: "48px" }}
            icon={<i className="fas fa-edit text-success"></i>}
            iconPosition="start"
            label="Preferences"
            value="preferences"
            id="tab-preferences"
            className="side-nav-live-buttons justify-content-start"
          />
          <Tab
            style={{ minHeight: "48px" }}
            icon={<i className="fas fa-chart-bar text-danger"></i>}
            iconPosition="start"
            label="Usage & Status"
            value="status"
            id="tab-status"
            className="side-nav-live-buttons justify-content-start"
          />
          <Tab
            style={{ minHeight: "48px" }}
            icon={<i className="fas fa-photo-video text-purple"></i>}
            iconPosition="start"
            label="Files"
            value="files"
            id="tab-files"
            className="side-nav-live-buttons justify-content-start"
          />
          <Tab
            style={{ minHeight: "48px" }}
            icon={<i className="fas fa-comments text-warning"></i>}
            iconPosition="start"
            label="Messages"
            value="messages"
            id="tab-messages"
            className="side-nav-live-buttons justify-content-start"
          />
        </Tabs>
      </MDBCardBody>
    </MDBCard>
  );
};

/**
 * Read-only
 * Disable Instance
 */

export default LiveNav;
