import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import t from "../utilities/transitions";
import {
  StaticRouter,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import { connect } from "react-redux";
import ProductView from "./products/ProductView";
import LoginModal from "../components/LoginModal";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { clear_temp_action, select_instance } from "../redux/actions";

const Products = ({
  dashboardItemSelected,
  clear_temp_action,
  instanceSelected,
  select_instance,
  tempAction,
  userInfo,
}) => {
  const getInitialPage = () => {
    console.log("get");
    if (!tempAction?.action) return "list";

    switch (tempAction.action) {
      case "continue":
        switch (tempAction.product) {
          case "jizzer":
            if (userInfo?._id) clear_temp_action();
            return "jizzer-terms";
          default:
            console.log("oob temp action product", tempAction);
            return "list";
        }
        break;
      default:
        console.log("oob temp action action", tempAction);
    }
  };

  const [page, setPage] = useState(getInitialPage());
  const [productHistoryStack, setProductHistoryStack] = useState([]);
  const [loginModalShown, setLoginModalShown] = useState(false);
  const [childAnimations, setChildAnimations] = useStateWithCallbackLazy({
    enter: t.normalize,
    exit: t.normalize,
  });

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (userInfo?._id) {
      if (tempAction?.action === "continue") triggerTempAction();
      else if (page !== "list") setPage("list");
    }
  }, [userInfo?._id]);

  useEffect(() => {
    if (dashboardItemSelected === "instances" && instanceSelected)
      history.push("/dashboard", {
        exit: t.fade_out_left,
        enter: t.fade_out_right,
      });
  }, [dashboardItemSelected, instanceSelected]);

  const resetAnimations = () =>
    setChildAnimations({
      enter: t.fade_out,
      exit: t.fade_out,
    });

  const getChildEnter = (child) => {
    switch (page) {
      case "list":
        return t.fade_out_right;
      case "jizzer-terms":
        if (child === "jizzer-payment") return t.fade_out_right;
        return t.fade_out_left;
      case "jizzer-payment":
        return t.fade_out_left;
      default:
        console.log("oob child enter", page);
    }
  };

  const getChildExit = (child) => {
    switch (page) {
      case "list":
        return t.fade_out_left;
      case "jizzer-terms":
        if (child === "jizzer-payment") return t.fade_out_left;
        return t.fade_out_right;
      case "jizzer-payment":
        return t.fade_out_right;
      default:
        console.log("oob child enter", page);
    }
  };

  const triggerTempAction = () => {
    switch (tempAction.action) {
      case "continue":
        switch (tempAction.product) {
          case "jizzer":
            if (userInfo?.instances.find((i) => i.service === "jizzer"))
              select_instance("jizzer");
            else if (page !== "jizzer-terms") {
              setPage("jizzer-terms");
            }

            break;
          default:
            console.log("oob temp action product", tempAction.product);
        }
        break;
      default:
        console.log("oob temp action action", tempAction.label);
    }
    clear_temp_action();
  };

  /**
   *
   * @param {String} page - Page to be navigated to
   *
   * Navigates to a new page
   */
  const changePage = (page) => {
    setChildAnimations(
      {
        enter: getChildEnter(page),
        exit: getChildExit(page),
      },
      () => {
        setPage(page);
        setProductHistoryStack([...productHistoryStack, page]);
      }
    );
  };

  const toggleLoginModal = () => setLoginModalShown(!loginModalShown);
  return (
    <motion.div
      className="h-100"
      transition={t.transition}
      initial={history?.location?.state?.enter || t.fade_out}
      animate={t.normalize}
      exit={history?.location?.state?.exit || t.fade_out_scale_1}
    >
      <LoginModal
        modalShown={loginModalShown}
        setShowModal={setLoginModalShown}
        toggleShowModal={toggleLoginModal}
      />
      <StaticRouter location={page}>
        <AnimatePresence exitBeforeEnter>
          <Switch key={page}>
            <Route exact path=":page">
              <ProductView
                page={page}
                changePage={changePage}
                productHistoryStack={productHistoryStack}
                toggleLoginModal={toggleLoginModal}
                enter={childAnimations.enter}
                exit={childAnimations.exit}
                resetAnimations={resetAnimations}
              />
            </Route>
          </Switch>
        </AnimatePresence>
      </StaticRouter>
    </motion.div>
  );
};

const mapStateToProps = (state) => ({
  dashboardItemSelected: state.dashboardItemSelected,
  instanceSelected: state.instanceSelected,
  tempAction: state.tempAction,
  userInfo: state.userInfo,
});

export default connect(mapStateToProps, { clear_temp_action, select_instance })(
  Products
);
