import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import Spinner from "../../../../../components/Spinner";
import axios from "axios";
import { update_dashboard_data } from "../../../../../redux/actions";

class RestoreModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether a request to restore a disabled instance is currently being processed
       */
      working: false,
    };
  }

  /**
   * Submit only if there isn't already a submission being sent
   * Make request to server
   * Update dashboard data
   * Hide modal
   */
  confirm = () => {
    if (!this.state.working)
      this.setState(
        {
          ...this.state,
          working: true,
        },
        () =>
          axios
            .get("/jizzer/restore-chadmin", {
              ...this.props.instance,
            })
            .then((res) => {
              this.props.update_dashboard_data(res.data);
              this.setState(
                {
                  ...this.state,
                  working: false,
                },
                this.props.toggleShowModal
              );
            })
            .catch((err) =>
              this.setState(
                {
                  ...this.state,
                  working: false,
                },
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later.");
                }
              )
            )
      );
  };

  render() {
    const instance = this.props.instance;
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            open={this.props.modalShown}
            staticBackdrop
            onClosePrevented={this.props.toggleShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog
              size={
                this.props.screenDimensions.width >
                this.props.screenDimensions.modalBreak
                  ? "xl"
                  : "fullscreen"
              }
            >
              {instance ? (
                <MDBModalContent>
                  <MDBModalHeader>
                    <MDBModalTitle className="d-flex align-items-center">
                      <div className="square-2 me-2">
                        <div
                          className="fit-images"
                          style={{
                            backgroundImage: `url("${process.env.REACT_APP_JIZZER_BUCKET_HOST}/${instance.uuid}/icons/favicon-96x96.png")`,
                          }}
                        ></div>
                      </div>
                      Are you sure?
                    </MDBModalTitle>
                    <MDBBtn
                      className="btn-close"
                      color="none"
                      onClick={this.props.toggleShowModal}
                    ></MDBBtn>
                  </MDBModalHeader>
                  <MDBModalBody>
                    <h5 className="text-center">
                      You are about to restore {instance.preferences.app_name}{" "}
                      located at{" "}
                      <a
                        className="links-generic"
                        href={"https://" + instance.server.domain}
                      >
                        {instance.server.domain}
                      </a>
                      .
                    </h5>
                  </MDBModalBody>
                  <MDBModalFooter>
                    {this.state.working ? (
                      <MDBBtn disabled color="success">
                        <Spinner className="me-2" size="sm" />
                        Restoring
                      </MDBBtn>
                    ) : (
                      <MDBBtn color="success" onClick={this.confirm}>
                        <i className="fas fa-check-circle me-2" />
                        Confirm
                      </MDBBtn>
                    )}
                    <MDBBtn
                      className="bg-gray"
                      onClick={this.props.toggleShowModal}
                    >
                      Close
                    </MDBBtn>
                  </MDBModalFooter>
                </MDBModalContent>
              ) : (
                <></>
              )}
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { update_dashboard_data })(
  RestoreModal
);
