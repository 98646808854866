import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
const translations = {
  totalRequests: "Total Requests",
  tokens: "Tokens Used",
  image: "Images",
};

const addLabels = (series) => {
  return series.map((item) => ({
    ...item,
    label: translations[item.dataKey],
  }));
};

const Tokens = ({ stats }) => {
  const chartData = stats
    .filter((stat, s) => s >= stats.length - 12)
    .map((stat, s) => ({
      month: months[stat.month],
      tokens: stat.tokens,
      totalRequests: stat.totalRequests,
    }));

  return (
    <>
      <h5 className="display-6 text-center">Virgil/Chad Tokens</h5>
      <BarChart
        dataset={chartData}
        series={addLabels([
          { dataKey: "tokens", stack: "all", color: "#e91e63" },
          { dataKey: "totalRequests", stack: "all", color: "#fff" },
        ])}
        xAxis={[{ scaleType: "band", dataKey: "month" }]}
        slotProps={{ legend: { hidden: true } }}
        height={350}
      />
    </>
  );
};

export default Tokens;
